import {
  getAuth,
  updatePassword as firebaseUpdatePassword,
} from "firebase/auth";

import { setBanner } from "@/features/banner/state/banner";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";

export async function updatePassword(password: string, onSuccess?: () => void) {
  await tryFirebase(
    async () => {
      const auth = getAuth();
      if (!auth.currentUser) {
        throw new Error("No user");
      }
      await firebaseUpdatePassword(auth.currentUser, password);
      setBanner({
        intent: "success",
        message: "Your password has been updated",
      });
      await onSuccess?.();
    },
    async (error) => {
      await handleError(error);
      await showError(error);
    }
  );
}
