import { createSignal } from "solid-js";
import resolveConfig from "tailwindcss/resolveConfig";

import tailwindConfig from "@/../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

const getIsTablet = () =>
  fullConfig?.theme?.screens &&
  "tablet" in fullConfig.theme.screens &&
  typeof fullConfig.theme.screens.tablet === "string" &&
  window.innerWidth <=
    Number(fullConfig.theme?.screens?.tablet.replace("px", ""));

const [isTablet, setIsTablet] = createSignal(getIsTablet());

window.addEventListener("resize", () => {
  setIsTablet(getIsTablet());
});

export { isTablet };
