import Solid, { For } from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { ToolColor } from "@/features/canvas/state/colorSwatch";

import { setThemeColor, themeColor } from "../../state/themeColor";

interface Props extends Solid.ComponentProps<typeof Dropdown> {}

export const ThemeColorPicker: Solid.Component<Props> = ({
  isDisabled,
  ...spreadProps
}) => (
  <Dropdown isDisabled={isDisabled || isSigningOut()} {...spreadProps}>
    <DropdownButton class="w-full" intent="secondary" isSelected>
      <span class="flex flex-1 gap-2">
        {/* SVG Circle for the primary color */}
        <svg
          class="h-6 w-6"
          fill={`var(--color-${themeColor()}-500)`}
          stroke="none"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" />
        </svg>
        {themeColor().charAt(0).toUpperCase() + themeColor().slice(1)}
      </span>
    </DropdownButton>
    <DropdownMenu>
      <For
        each={
          [
            "red",
            "orange",
            "amber",
            "yellow",
            "lime",
            "green",
            "emerald",
            "teal",
            "cyan",
            "sky",
            "blue",
            "indigo",
            "violet",
            "purple",
            "fuchsia",
            "pink",
            "rose",
          ] as ToolColor[]
        }
      >
        {(color) => (
          <MenuItem
            isSelected={themeColor() === color}
            onSelect={() =>
              (async () => {
                await setThemeColor(color);
              })()
            }
          >
            <>
              <svg
                class="h-6 w-6"
                fill={`var(--color-${color}-500)`}
                stroke="none"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" />
              </svg>
              {color.charAt(0).toUpperCase() + color.slice(1)}
            </>
          </MenuItem>
        )}
      </For>
    </DropdownMenu>
  </Dropdown>
);
