import { createSprite } from "../helpers/engine/createSprite";

export const sprGrass = createSprite({
  columns: 2,
  rows: 1,
  src: "mon/images/grass.png",
});

export const sprGrassForeground = createSprite({
  columns: 4,
  rows: 1,
  src: "mon/images/grassForeground.png",
});
