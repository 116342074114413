import { useNavigate } from "@solidjs/router";
import { getAuth, signOut } from "firebase/auth";

import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";

import { setIsSigningOut } from "../state/isSigningOut";

export async function signUserOut() {
  setIsSigningOut(true);

  const navigate = useNavigate();

  // Wait for view transition
  await new Promise((resolve) => setTimeout(resolve, 300));

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await signOut(auth);
      localStorage.removeItem("backStack");
      navigate("/");
    },
    async (error) => {
      await handleError(error);
      await showError(error);
    }
  );
  setIsSigningOut(false);
}
