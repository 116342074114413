import * as Tone from "tone";

import { ActionObject } from "./handleAction";

const synth = new Tone.Synth().toDestination();

function triggerRandomPitch(
  duration: string,
  startOffset: number,
  voice: string[],
  randomSeed: number
) {
  console.log(startOffset);
  const now = Tone.now() + startOffset;
  const pitch = voice;
  const randomPitch =
    pitch[
      Math.floor(
        (((startOffset * randomSeed * 4321) % 100) / 100) * pitch.length
      )
    ];
  synth.triggerAttackRelease(randomPitch, duration, now);
}

function simulateTalking(length: number, voice: string[]) {
  let startTime = 0;
  for (let i = 0; i < length; i++) {
    // Number of "characters"
    triggerRandomPitch("16n", startTime, voice, length);
    startTime += 0.1; // Adjust timing to control speed of "talking"
  }
}

export function readMessage(
  readerObject: {
    currentMessage?: string;
    currentMessageTime?: Date;
    nextAction?: (objectActioning: ActionObject) => void;
  },
  message: () => string,
  voice?: string[]
) {
  const messages = message().split("\n");

  if (!message() || !messages.length) {
    readerObject.currentMessage = undefined;
    readerObject.nextAction = undefined;
    return;
  }

  if (voice) {
    simulateTalking(messages.slice(0, 2).join("").length / 5, voice);
  }

  readerObject.currentMessage = messages.slice(0, 2).join("\n");
  readerObject.currentMessageTime = new Date();

  readerObject.nextAction = () => {
    readMessage(readerObject, () => messages.slice(2).join("\n"), voice);
  };
}
