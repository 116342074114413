import { createEffect } from "solid-js";

import doesElementContainElement from "../helpers/doesElementContainElement";

export default function useOnMouseDownOutside(
  elementMouseIsOutsideOf:
    | HTMLElement
    | undefined
    | (HTMLElement | undefined)[],
  handler: () => void
) {
  createEffect(() => {
    function onMousedownOutside(event: MouseEvent) {
      const refArray = [
        ...(Array.isArray(elementMouseIsOutsideOf)
          ? elementMouseIsOutsideOf
          : [elementMouseIsOutsideOf]),
      ];

      const isInside = refArray.find((element) =>
        doesElementContainElement(element, event.target as HTMLElement)
      );

      if (!isInside) {
        handler();
      }
    }
    document.body.addEventListener("mousedown", onMousedownOutside);
    return () => {
      document.body.removeEventListener("mousedown", onMousedownOutside);
    };
  });
}
