import "./Banner.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid, { For } from "solid-js";

import { Button } from "@/components/Button/Button";
import { CloseIcon } from "@/icons/CloseIcon";

const bannerClasses = cva("banner", {
  defaultVariants: {},
  variants: {
    intent: {
      error: "banner--error",
      success: "banner--success",
    },
  },
});

interface Props
  extends Solid.JSX.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof bannerClasses> {
  dismiss: () => void;
  message: string;
}

export const Banner: Solid.Component<Props> = ({
  class: className,
  dismiss,
  intent,
  message,
  ...attributes
}) => (
  <div class="absolute top-0 z-20 flex w-full justify-center">
    <div class="mx-5 w-full py-5 tablet:mx-auto tablet:w-[728px]">
      <div
        class={cn(
          bannerClasses({ intent }),
          className,
          "flex flex-col items-baseline gap-5 p-5 signInActions:flex-row"
        )}
        {...attributes}
      >
        <div>
          <For each={message.split("\n")}>
            {(message, i) => {
              // TODO: Re-enable this linting rule
              // eslint-disable-next-line solid/reactivity
              if (i() === message.split("\n").length - 1) {
                return message;
              }
              return [message, <br />];
            }}
          </For>
        </div>
        <div class={cn("hidden", "signInActions:block signInActions:flex-1")} />
        <Button
          class="w-full shrink-0 signInActions:w-auto"
          intent={intent}
          onClick={dismiss}
        >
          Dismiss
          <div class="block flex-1 signInActions:hidden" />
          <CloseIcon class="h-6 w-6" />
        </Button>
      </div>
    </div>
  </div>
);
