// TODO (2 - refactor)

import "./CanvasElement.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid from "solid-js";
import colors from "tailwindcss/colors";

import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import renderCanvas from "@/features/canvas/actions/renderCanvas";
import useElementSize from "@/features/canvas/hooks/useElementSize";
import useEraserTool from "@/features/canvas/hooks/useEraserTool";
import useHandTool, {
  previousHandToolPosition,
} from "@/features/canvas/hooks/useHandTool";
import usePenTool from "@/features/canvas/hooks/usePenTool";
import useShapeTool from "@/features/canvas/hooks/useShapeTool";
import { toolColor } from "@/features/canvas/selectors/toolColor";
import { canvasView, setCanvasView } from "@/features/canvas/state/canvasView";
import { Tool, tool } from "@/features/canvas/state/tool";
import { calculatedTouchAction } from "@/features/userSettings/state/touchAction";

const canvasClasses = cva("canvas", {
  defaultVariants: {},
  variants: {
    tool: {
      // eraser: "canvas--tool-eraser",
      // hand: "canvas--tool-hand",
      // pencil: "canvas--tool-pencil",
      // select: "canvas--tool-select",
      // shape: "canvas--tool-shape",
    },
  },
});

interface Props
  extends Solid.JSX.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof canvasClasses> {}

export const CanvasElement: Solid.Component<Props> = () => {
  let canvasRef: HTMLCanvasElement | undefined;

  const { elementSize: canvasSize } = useElementSize(() => canvasRef);

  renderCanvas(canvasSize, () => canvasRef);

  const { ...handToolEvents } = useHandTool();
  const { ...shapeToolEvents } = useShapeTool();
  const { ...penToolEvents } = usePenTool();
  const { ...eraserToolEvents } = useEraserTool();

  const toolEvents = (event: Event | PointerEvent) => {
    if (isSigningOut()) {
      return undefined;
    }

    const pointerTool = () => {
      if (
        event instanceof PointerEvent &&
        event.pointerType === "touch" &&
        calculatedTouchAction() !== "tool"
      ) {
        return calculatedTouchAction();
      }

      return tool();
    };

    switch (pointerTool()) {
      case "hand":
        return { ...handToolEvents };
      case "shape":
        return { ...shapeToolEvents };
      case "pencil":
        return { ...penToolEvents };
      case "eraser":
        return { ...eraserToolEvents };
    }

    return undefined;
  };

  const cursorMap: () => {
    [key in Tool]: string;
  } = () => ({
    eraser: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${(
      colors["black"] + "00060"
    ).replace(
      "#",
      "%23"
    )}" height="14" viewBox="0 0 14 14" width="14"><circle fill="${(
      colors["white"] + "FFF60"
    ).replace(
      "#",
      "%23"
    )}" cx="7" cy="7" r="7" /><circle cx="7" cy="7" r="5" /></svg>') 7 7, auto`,
    hand: previousHandToolPosition() ? "grabbing" : "grab",
    pencil: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${colors[
      toolColor()
    ][500]?.replace(
      "#",
      "%23"
    )}" height="3" viewBox="0 0 3 3" width="3"><circle cx="1.5" cy="1.5" r="1.5" /></svg>') 1.5 1.5, auto`,
    select: "default",
    shape: "crosshair",
  });

  return (
    <div
      class={cn(canvasClasses({}), "overflow-visible")}
      onPointerCancel={(event) => {
        if (event.button !== 0) {
          return;
        }

        toolEvents(event)?.onPointerCancel?.();
      }}
      onPointerDown={(event) => {
        if (event.button !== 0) {
          return;
        }

        toolEvents(event)?.onPointerDown?.(event);
      }}
      onPointerLeave={(event) => {
        if (event.buttons % 2 !== 1) {
          return;
        }

        toolEvents(event)?.onPointerLeave();
      }}
      onPointerMove={(event) => {
        if (event.buttons % 2 !== 1) {
          return;
        }

        toolEvents(event)?.onPointerMove(event);
      }}
      onPointerUp={(event) => {
        if (event.button !== 0) {
          return;
        }

        toolEvents(event)?.onPointerUp?.();
      }}
      onTouchEnd={(event) => {
        event.preventDefault();
      }}
      onWheel={(event) => {
        const newZoom = canvasView().zoom * (event.deltaY > 0 ? 0.5 : 2);

        setCanvasView({
          x:
            canvasView().x -
            ((event.clientX -
              (event.currentTarget as HTMLElement).getBoundingClientRect()
                .left) /
              canvasView().zoom -
              (event.clientX -
                (event.currentTarget as HTMLElement).getBoundingClientRect()
                  .left) /
                newZoom),
          y:
            canvasView().y -
            ((event.clientY -
              (event.currentTarget as HTMLElement).getBoundingClientRect()
                .top) /
              canvasView().zoom -
              (event.clientY -
                (event.currentTarget as HTMLElement).getBoundingClientRect()
                  .top) /
                newZoom),
          zoom: newZoom,
        });
      }}
      style={{
        cursor: cursorMap()[tool()],
      }}
    >
      {/* Absolute positioning to allow the height to shrink with the viewport */}
      <canvas class="canvas__canvas absolute" ref={canvasRef} />
      {/* <svg
              class="canvas__svg w-full pointer-events-none overflow-visible"
              style={{
                position: "absolute",
                transform: `translate(${canvasOffset().x}px,${canvasOffset().y}px)`,
                // left: `${canvasOffset().x}px`,
                // top: `${canvasOffset().y}px`,
              }}
            >
              <For each={[...allStrokes(), currentStroke()]}>
                {(stroke) => {
                  if (!stroke) {
                    return;
                  }
                  const path = strokeToSVGPath(Array.from(stroke));
                  return (
                    <path
                      class="canvas__stroke"
                      d={path}
                      style={{
                        stroke: stroke[0]?.color
                          ? colors[stroke[0].color][500] ||
                            String(colors[stroke[0].color])
                          : "black",
                        "stroke-width": "3px",
                      }}
                    />
                  );
                }}
              </For>
            </svg> */}
    </div>
  );
};
