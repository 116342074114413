import { toolColor } from "../selectors/toolColor";
import { canvasView } from "../state/canvasView";
import {
  allStrokes,
  currentStroke,
  setAllStrokes,
  setCurrentStroke,
} from "../state/strokes";

export default function usePenTool() {
  function penTool(x: number, y: number) {
    // Check the angle between the last 2 points and the current point, and if the line is straight enough skip the point
    // const lastPoint = currentStroke()[currentStroke().length - 1];
    // const currentPoint = event.touches[0];
    // // Using math figure out the distance and if it's too close, skip it
    // const distance = Math.sqrt(
    //   Math.pow(currentPoint.clientX - lastPoint.clientX, 2) +
    //     Math.pow(currentPoint.clientY - lastPoint.clientY, 2)
    // );
    // if (distance < 2) {
    //   return;
    // }

    setCurrentStroke([
      ...currentStroke(),
      {
        clientX: x,
        clientY: y,
        color: toolColor(),
      },
    ]);
  }

  function penToolCommit() {
    setAllStrokes([...allStrokes(), currentStroke()]);
    setCurrentStroke([]);
  }

  function penToolCancel() {
    setCurrentStroke([]);
  }

  function penToolHandler({ clientX, clientY, currentTarget }: PointerEvent) {
    const x =
      (clientX - (currentTarget as HTMLElement).getBoundingClientRect().left) /
      canvasView().zoom;
    const y =
      (clientY - (currentTarget as HTMLElement).getBoundingClientRect().top) /
      canvasView().zoom;

    penTool(x - canvasView().x, y - canvasView().y);
  }

  return {
    onPointerCancel: penToolCancel,
    onPointerDown: penToolHandler,
    onPointerLeave: penToolCommit,
    onPointerMove: penToolHandler,
    onPointerUp: penToolCommit,
  };
}
