import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const backIconClasses = cva("backIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof backIconClasses> {}

export const BackIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
