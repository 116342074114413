import "./DeskSettings.scss";

import cn from "clsx";
import Solid, { createEffect, createSignal, onCleanup } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { Alignment, Side } from "@/components/Dropdown/hooks/usePositioning";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { PageComponent } from "@/components/PageComponent/PageComponent";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {}

export const DeskSettings: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => {
  const [side, setSide] = createSignal<Side>("bottom");
  const [alignment, setAlignment] = createSignal<Alignment>("start");

  let t: number | undefined;

  const getNextSide = (s: Side) => {
    if (s === "top") {
      return "end" as Side;
    } else if (s === "end") {
      return "bottom" as Side;
    } else if (s === "bottom") {
      return "start" as Side;
    } else if (s === "start") {
      return "top" as Side;
    } else if (s === "inside") {
      return "top" as Side;
    }
    return s;
  };
  const getNextAlignment = (a: Alignment) => {
    if (a === "start") {
      return "center" as Alignment;
    } else if (a === "center") {
      return "end" as Alignment;
    } else if (a === "end") {
      return "start" as Alignment;
    }
    return a;
  };

  createEffect(() => {
    const nextSide = getNextSide(side());
    const nextAlignment = getNextAlignment(alignment());

    setTimeout(() => {
      if (alignment() === "end") {
        setAlignment("start");
        setSide(nextSide);
      } else {
        setAlignment(nextAlignment);
      }
    }, 500);

    return () => {
      clearTimeout(t);
    };
  });

  onCleanup(() => {
    clearInterval(t);
  });

  document.title = "Scribble Desk - Desk settings";

  return (
    <PageComponent>
      <div class={cn("pageDeskSettings", className)} {...attributes}>
        <div
          style={{
            position: "absolute",
            right: "50%",
            top: "200px",
          }}
        >
          <Dropdown
            alignment={alignment}
            isDisabled={isSigningOut()}
            side={side}
          >
            <span>
              <DropdownButton hasCaret={false}>🕺</DropdownButton>
            </span>
            <DropdownMenu>
              <MenuItem>💃💃</MenuItem>
              <MenuItem>💃💃</MenuItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="mt-60 flex flex-col gap-5">
          <div>
            <Button>Button</Button>
          </div>
          <div>
            <Button isDisabled>Disabled button</Button>
          </div>
          <div>
            <Button intent="primary">Primary button</Button>
          </div>
          <div>
            <Button intent="primary" isDisabled>
              Disabled primary button
            </Button>
          </div>
          <div>
            <Button intent="secondary">Secondary button</Button>
          </div>
          <div>
            <Button intent="secondary" isDisabled>
              Disabled secondary button
            </Button>
          </div>
        </div>
      </div>
    </PageComponent>
  );
};
