import { drawSprite } from "../../helpers/engine/drawSprite";
import { currentRoom } from "../../Mon";
import { sprTree } from "../../sprites/sprTree";
import { objWall } from "../objWall/objWall";

export class objTree extends objWall {
  public height: number;
  public width: number;
  public x: number;
  public y: number;
  public depth: number;

  constructor({ x, y }: { x: number; y: number }) {
    const height = 16;
    const width = 16;

    super(x, y, width, height);

    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
    this.depth = this.y;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  public render = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = currentRoom.current.backgroundColor;
    ctx.fillRect(this.x, this.y, this.width, this.height);

    drawSprite({
      ctx,
      sprite: sprTree,
      x: this.x,
      y: this.y,
    });
  };
}
