import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const upgradeIconClasses = cva("upgradeIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof upgradeIconClasses> {}

export const UpgradeIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
