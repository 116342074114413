export function roundClientRect(clientRect?: DOMRect) {
  if (!clientRect) {
    return clientRect;
  }

  return {
    bottom: Math.round(clientRect.bottom),
    height: Math.round(clientRect.height),
    left: Math.round(clientRect.left),
    right: Math.round(clientRect.right),
    top: Math.round(clientRect.top),
    width: Math.round(clientRect.width),
  };
}
