import "./Input.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import { nanoid } from "nanoid";
import Solid, { createEffect, createSignal } from "solid-js";

const inputClasses = cva("input", {
  defaultVariants: {},
  variants: {
    hasContent: {
      true: "input-hasContent",
    },
    intent: {
      field: "input-field",
    },
    isActive: {
      true: "input-active",
    },
    isDisabled: {
      true: "ripples-noClick input-disabled",
    },
  },
});

interface Props
  extends Solid.JSX.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputClasses> {
  isDisabled?: boolean;
  label?: string;
}
// TODO (4 - fun) - Add ellipses
export const Input: Solid.Component<Props> = ({
  id,
  isActive,
  class: className,
  intent,
  isDisabled = false,
  label,
  onInput,
  value,
  ...attributes
}) => {
  const [inputValue, setInputValue] = createSignal(value ?? "");
  const generatedId = nanoid();
  const inputId = () => id ?? generatedId;

  createEffect(() => {
    setInputValue(value ?? "");
  });

  return (
    <div
      class={cn(
        inputClasses({
          hasContent: Boolean(inputValue()),
          intent,
          isActive,
          isDisabled,
        }),
        "mb-2 mt-1",
        className
      )}
    >
      <label class={cn("input__label")} for={inputId()}>
        {label}
      </label>
      <input
        class="input__input"
        disabled={isDisabled}
        id={inputId()}
        onInput={(event) => {
          setInputValue((event.target as HTMLInputElement).value);
          if (typeof onInput === "function") {
            onInput(event);
          }
        }}
        value={inputValue()}
        {...attributes}
      />
    </div>
  );
};
