import "./MenuItem.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import { nanoid } from "nanoid";
import Solid, { onCleanup, useContext } from "solid-js";

import { MenuContext } from "../Menu/Menu";
import { Ripples } from "../Ripples/Ripples";

const menuItemClasses = cva("menuItem", {
  defaultVariants: {},
  variants: {
    isActive: {
      true: "menuItem-active",
    },
    isDisabled: {
      true: "menuItem-disabled",
    },
    isSelected: {
      true: "menuItem-selected",
    },
  },
});

interface Props
  extends Solid.JSX.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof menuItemClasses> {
  onSelect?: () => void;
  selectionIndicator?: string;
}

export const MenuItem: Solid.Component<Props> = ({
  children,
  class: className,
  isDisabled,
  isSelected,
  onSelect: onSelectProp,
  selectionIndicator = "line",
  ...attributes
}) => {
  const menuItemId = nanoid();

  const {
    activeItem,
    addMenuItem,
    menuItems,
    removeMenuItem,
    setActiveItemIndex,
  } = useContext(MenuContext);

  addMenuItem?.(menuItemId);

  onCleanup(() => {
    removeMenuItem?.(menuItemId);
  });

  function onSelect() {
    const activeItemIndex = menuItems?.().findIndex(
      (menuItem) => menuItem === menuItemId
    );

    if (typeof activeItemIndex === "number") {
      setActiveItemIndex?.(activeItemIndex);
    }

    onSelectProp?.();
  }

  const onClickHandler: Props["onClick"] = (...args) => {
    if (typeof attributes.onClick === "function") {
      attributes.onClick(...args);
    }

    onSelect();
  };

  const onKeyDownHandler: Props["onKeyDown"] = (event, ...args) => {
    if (typeof attributes.onKeyDown === "function") {
      attributes.onKeyDown(event, ...args);
    }

    if (event.key === "Enter" || event.key === " ") {
      onSelect();
    }
  };

  return (
    // TODO (4 - fun): Add disabled state (styling exists for disabled attribute)
    <div
      {...attributes}
      aria-selected={isSelected ?? undefined}
      class={cn(
        menuItemClasses({
          isActive: activeItem?.() === menuItemId,
          isDisabled,
          isSelected,
        }),
        className
      )}
      data-isselected={isSelected ?? undefined}
      id={menuItemId}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
    >
      <Ripples color="gray" colorDark="white" />
      {isSelected && (
        <div
          class={cn(
            "menuItem__selectionIndicator",
            selectionIndicator === "circle" &&
              "menuItem__selectionIndicator--circle",
            selectionIndicator === "line" &&
              "menuItem__selectionIndicator--line"
          )}
        />
      )}
      {children}
    </div>
  );
};
