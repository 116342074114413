import "./Mon.scss";

import cn from "clsx";
import Solid, { createSignal } from "solid-js";

import { isLoadingImages } from "./helpers/engine/loadImage";
import { room } from "./helpers/engine/room";
import { objPlayer } from "./objects/objPlayer/objPlayer";
import { rmInside } from "./rooms/rmInside";
import { rmOverworld } from "./rooms/rmOverworld";

const [isFontLoading, setIsFontLoading] = createSignal(true);

const font = new FontFace("pixel", 'url("/mon/fonts/OpenSansPX.ttf")');

font
  .load()
  .then(() => {
    setIsFontLoading(false);
  })
  .catch(() => {
    console.log("Font error");
  });

export const player: {
  current?: InstanceType<typeof objPlayer>;
} = {};

export const roomIdMap: Record<string, room> = {
  rmInside: new room(rmInside),
  rmOverworld: new room(rmOverworld),
};

export const currentRoom: {
  current: room;
} = {
  current: roomIdMap.rmOverworld,
};

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {}

export const Mon: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => {
  const [isPlayingAudio, setIsPlayingAudio] = createSignal(false);

  let canvasRef: HTMLCanvasElement | undefined;

  const renderCanvas = () => {
    if (!canvasRef) {
      return;
    }

    const canvas = canvasRef.getContext("2d");
    if (!canvas) {
      return;
    }

    const isLoading = () => isLoadingImages().length > 0 || isFontLoading();

    if (!isLoading()) {
      currentRoom.current.render(canvas);
    }
  };

  const renderLoop = () => {
    renderCanvas();
    requestAnimationFrame(renderLoop);
  };

  renderLoop();

  document.title = "Mon";

  return (
    <div class={cn("page-mon", className)} {...attributes}>
      <canvas class="page-mon__canvas" ref={canvasRef} />
      <button
        class="page-mon__mute-button"
        onClick={() => {
          if (currentRoom.current.audio.paused) {
            currentRoom.current.audio.volume = 0.3;
            currentRoom.current.audio.play();
            setIsPlayingAudio(true);
          } else {
            currentRoom.current.audio.pause();
            currentRoom.current.audio.currentTime = 0;
            setIsPlayingAudio(false);
          }
        }}
      >
        {isPlayingAudio() ? "Mute" : "Unmute"} background music
      </button>
    </div>
  );
};
