import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingColorSwatch } from "@/features/saving/state/isSaving";

export type ToolColor =
  | "black"
  | "white"
  | "red"
  | "orange"
  | "amber"
  | "yellow"
  | "lime"
  | "green"
  | "emerald"
  | "teal"
  | "cyan"
  | "sky"
  | "blue"
  | "indigo"
  | "violet"
  | "purple"
  | "fuchsia"
  | "pink"
  | "rose"
  | "white"
  | "black";

export const defaultColorSwatch: ToolColor[] = ["black"];

export const {
  isConnected: isColorSwatchSynced,
  getSignal: colorSwatch,
  setSignal: setColorSwatchSignal,
  setSignalConnected: setColorSwatch,
} = createConnectedSignal<ToolColor[]>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/colorSwatch`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultColorSwatch,
  onEnd: () => setIsSavingColorSwatch(false),
  onStart: () => setIsSavingColorSwatch(true),
});
