import "./Canvas.scss";

import cn from "clsx";
import Solid, { For } from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { Side } from "@/components/Dropdown/hooks/usePositioning";
import { Menu } from "@/components/Menu/Menu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { CanvasElement } from "@/features/canvas/components/ui/CanvasElement/CanvasElement";
import { ColorPicker } from "@/features/canvas/components/ui/ColorPicker/ColorPicker";
import { ShapePicker } from "@/features/canvas/components/ui/ShapePicker/ShapePicker";
import {
  colorSwatch,
  setColorSwatch,
} from "@/features/canvas/state/colorSwatch";
import {
  currentColorPickerIndex,
  setCurrentColorPickerIndex,
} from "@/features/canvas/state/currentColorPickerIndex";
import {
  currentShapePickerIndex,
  setCurrentShapePickerIndex,
} from "@/features/canvas/state/currentShapePickerIndex";
import {
  hideToolNames,
  setHideToolNames,
} from "@/features/canvas/state/hideToolNames";
import {
  setShapeSwatch,
  shapeSwatch,
} from "@/features/canvas/state/shapeSwatch";
import { setTool, tool } from "@/features/canvas/state/tool";
import { isBetaUser } from "@/features/featureFlags/state/betaUser";
import { EraserIcon } from "@/icons/EraserIcon";
import { HandIcon } from "@/icons/HandIcon";
import { HideToolNames } from "@/icons/HideToolNames";
import { LassoIcon } from "@/icons/LassoIcon";
import { PenIcon } from "@/icons/PenIcon";
import { ShapeIcon } from "@/icons/ShapeIcon";
import { ShowToolNames } from "@/icons/ShowToolNames";

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {}

export const Canvas: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => {
  const toolMenuItemMap: {
    [tool: string]: {
      icon: () => Solid.JSX.Element;
      name: string;
      onSelect: () => void;
      isHidden?: boolean;
    };
  } = {
    eraser: {
      icon: () => <EraserIcon class="h-6 w-6" />,
      name: "Eraser",
      onSelect: () => {
        setTool("eraser");
      },
    },
    hand: {
      icon: () => <HandIcon class="h-6 w-6" />,
      name: "Hand",
      onSelect: () => {
        setTool("hand");
      },
    },
    pencil: {
      icon: () => <PenIcon class="h-6 w-6" />,
      name: "Pencil",
      onSelect: () => {
        setTool("pencil");
      },
    },
    select: {
      icon: () => <LassoIcon class="h-6 w-6" />,
      isHidden: !isBetaUser(),
      name: "Select",
      onSelect: () => {
        setTool("select");
      },
    },
    shape: {
      icon: () => <ShapeIcon class="h-6 w-6" />,
      name: "Shape",
      onSelect: () => {
        setTool("shape");
      },
    },
  };

  const toolMenuItemsFromMap = (sideMenu?: boolean) =>
    Object.entries(toolMenuItemMap)
      .filter(([, { isHidden }]) => !isHidden)
      .map(([toolName, { icon, name, onSelect }]) => (
        <MenuItem isSelected={tool() === toolName} onSelect={onSelect}>
          {icon()}
          <span class={cn(sideMenu && hideToolNames() && "hidden")}>
            {name}
          </span>
        </MenuItem>
      ));

  document.title = "Scribble Desk";

  const toolsWithOptions = ["pencil", "shape"];

  function toolOptions(side?: () => Side) {
    return (
      <>
        {toolsWithOptions.includes(tool()) && (
          <>
            <For each={colorSwatch()}>
              {(color, index) => (
                <div class="flex-shrink-0">
                  <ColorPicker
                    initialColor={color}
                    isSelected={currentColorPickerIndex() === index()}
                    onSetColor={(color) => {
                      setCurrentColorPickerIndex(index());
                      const newColorSwatch = [...colorSwatch()];
                      newColorSwatch[index()] = color;
                      setColorSwatch(newColorSwatch);
                    }}
                    side={side}
                  />
                </div>
              )}
            </For>
            {tool() === "shape" && (
              <For each={shapeSwatch()}>
                {(shape, index) => (
                  <div class="flex-shrink-0">
                    <ShapePicker
                      initialShape={shape}
                      isSelected={currentShapePickerIndex() === index()}
                      onSetShape={(shape) => {
                        setCurrentShapePickerIndex(index());
                        const newShapeSwatch = [...shapeSwatch()];
                        newShapeSwatch[index()] = shape;
                        setShapeSwatch(newShapeSwatch);
                      }}
                      side={side}
                    />
                  </div>
                )}
              </For>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div class={cn("page-canvas", className, "relative")} {...attributes}>
      <div class="page-canvas__side-panel z-10 hidden tablet:block absolute top-[50%] -translate-y-[50%] pl-[env(safe-area-inset-left)] rtl:pl-0 rtl:pr-[env(safe-area-inset-right)]">
        <Menu
          onContextMenu={(event) => {
            event.preventDefault();
            setHideToolNames(!hideToolNames());
          }}
          selectionFollowsFocus={false}
        >
          {toolMenuItemsFromMap(true)}
          <MenuItem
            onSelect={() => {
              setHideToolNames(!hideToolNames());
            }}
          >
            {hideToolNames() ? (
              <>
                <ShowToolNames class="h-6 w-6 rtl:hidden" />
                <HideToolNames class="h-6 w-6 hidden rtl:block" />
              </>
            ) : (
              <>
                <HideToolNames class="h-6 w-6 rtl:hidden" />
                <ShowToolNames class="h-6 w-6 hidden rtl:block" />
              </>
            )}
            <span class={cn(hideToolNames() && "hidden")}>Hide</span>
          </MenuItem>
        </Menu>
      </div>

      {toolsWithOptions.includes(tool()) && (
        <div class="page-canvas__top-panel absolute left-[50%] hidden tablet:flex -translate-x-[50%] max-w-full overflow-auto">
          {toolOptions()}
        </div>
      )}

      <div class="page-canvas__bottom-panel z-10 absolute bottom-0 left-0 w-full tablet:hidden">
        <div
          class="flex"
          style={{
            "padding-bottom": "env(safe-area-inset-bottom)",
          }}
        >
          <div class="flex-shrink-0">
            <Dropdown side={() => "top"}>
              <DropdownButton
                class="w-28"
                hasCaret={false}
                isSelected
                selectionIndicator="line"
              >
                {tool() === "hand" ? (
                  <>
                    <HandIcon class="h-6 w-6" />
                    <span>Hand</span>
                  </>
                ) : tool() === "pencil" ? (
                  <>
                    <PenIcon class="h-6 w-6" />
                    <span>Pencil</span>
                  </>
                ) : tool() === "eraser" ? (
                  <>
                    <EraserIcon class="h-6 w-6" />
                    <span>Eraser</span>
                  </>
                ) : tool() === "select" ? (
                  <>
                    <LassoIcon class="h-6 w-6" />
                    <span>Select</span>
                  </>
                ) : tool() === "shape" ? (
                  <>
                    <ShapeIcon class="h-6 w-6" />
                    <span>Shape</span>
                  </>
                ) : null}
                <div class="flex-1" />
              </DropdownButton>
              <DropdownMenu>{toolMenuItemsFromMap()}</DropdownMenu>
            </Dropdown>
          </div>
          <div class="flex flex-1 overflow-auto">
            {toolOptions(() => "top")}
          </div>
        </div>
      </div>
      <CanvasElement />
    </div>
  );
};
