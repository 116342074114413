import { viewX, viewY } from "../../../../../helpers/engine/room";
import { allItems } from "../../../../objItem/objItem";
import { myItems } from "../../../objPlayer";
import { currentItemIndex } from "./handleItemsMenu";

export default function renderItemsMenu(ctx: CanvasRenderingContext2D) {
  const menuPaddingX = 13;
  const menuPaddingY = 13;

  const menuHeight = Object.values(myItems).length * 13 + menuPaddingY / 2;
  const menuWidth = 80;

  const menuX = viewX + ctx.canvas.width - menuWidth;
  const menuY = viewY;

  ctx.font = "16px Mon";
  ctx.fillStyle = "#FFF";

  // drawSprite({
  //   ctx,
  //   sprite: sprChatFrame,
  //   x: menuX,
  //   y: menuY,
  // });

  ctx.fillRect(menuX, menuY, menuWidth, menuHeight);

  Object.values(myItems).forEach((item, index) => {
    if (!item) {
      return;
    }

    const itemName = allItems[item.item].name;

    // 5px font
    // ctx.fillStyle = "#DDD";
    // ctx.fillText(item, menuX + 19 + 1, menuY + 24 + 1 + 12 * index);

    // ctx.fillStyle = "#222";
    // ctx.fillText(item, menuX + 19, menuY + 24 + 12 * index);

    // 8px font
    ctx.fillStyle = "#DDD";
    ctx.fillText(
      (currentItemIndex === index ? ">" : "") + itemName + " x" + item.quantity,
      menuX + menuPaddingX + 1,
      menuY + menuPaddingY + 1 + 13 * index
    );

    ctx.fillStyle = "#222";
    ctx.fillText(
      (currentItemIndex === index ? ">" : "") + itemName + " x" + item.quantity,
      menuX + menuPaddingX,
      menuY + menuPaddingY + 13 * index
    );
  });
}
