import {
  onActionPressed,
  onCancelPressed,
  onDownPressed,
  onUpPressed,
} from "../../../../../helpers/engine/handleKeyboard";
import { myItems } from "../../../objPlayer";
import { setCurrentMenu } from "../handleMenu";

export let currentItemIndex = 0;

export function handleItemsMenu() {
  if (onCancelPressed) {
    setCurrentMenu("main");
  }

  if (onDownPressed) {
    currentItemIndex += 1;

    if (currentItemIndex >= Object.keys(myItems).length) {
      currentItemIndex = 0;
    }
  }

  if (onUpPressed) {
    currentItemIndex -= 1;

    if (currentItemIndex < 0) {
      currentItemIndex = Object.keys(myItems).length - 1;
    }
  }

  if (onActionPressed) {
    const currentItem = myItems[currentItemIndex];

    if (!currentItem) {
      return;
    }

    // Open item actions
  }
}
