import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const newUserIconClasses = cva("newUserIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof newUserIconClasses> {}

export const NewUserIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
