import {
  EmailAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  linkWithCredential,
  reauthenticateWithCredential,
  signInWithEmailLink,
  updateCurrentUser,
  UserCredential,
} from "firebase/auth";

import { setBanner } from "@/features/banner/state/banner";
import { isDatabaseConnected } from "@/features/database/selectors/isDatabaseConnected";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { until } from "@/helpers/until";
import withTransition from "@/helpers/withTransition";

import { removeEmailFromLocalStorage } from "../helpers/removeEmailFromLocalStorage";
import { setIsRequestingEmailForMagicLink } from "../state/isRequestingEmailForMagicLink";
import { setUser, user } from "../state/user";

export async function signInWithMagicLink(
  userEmail: string,
  cleanUp?: () => void
) {
  async function cleanUpForNonSignInPages() {
    setIsRequestingEmailForMagicLink(false);
    removeEmailFromLocalStorage();
    history.replaceState(null, "", location.pathname);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();

      if (!isSignInWithEmailLink(auth, window.location.href)) {
        return;
      }

      if (!userEmail) {
        throw new Error("No email provided");
      }

      if (auth.currentUser) {
        const wasGuestSignUp = auth.currentUser.isAnonymous;

        const credential = EmailAuthProvider.credentialWithLink(
          userEmail,
          window.location.href
        );
        let userCredential: UserCredential;

        if (!auth.currentUser.email) {
          userCredential = await linkWithCredential(
            auth.currentUser,
            credential
          );

          setBanner({
            intent: "success",
            message: "Your email has been verified",
          });
        } else {
          userCredential = await reauthenticateWithCredential(
            auth.currentUser,
            credential
          );

          setBanner({
            intent: "success",
            message: "Signed in successfully, you may now try again",
          });
        }

        await updateCurrentUser(auth, userCredential.user);
        setUser({ ...userCredential.user });

        // Animation - Sign in with magic link (merge accounts)
        await withTransition(() => {
          cleanUpForNonSignInPages();
          cleanUp?.();
        });

        if (wasGuestSignUp) {
          // confetti({
          //   particleCount: 100,
          //   spread: 70,
          // });
        }
      } else {
        await signInWithEmailLink(auth, userEmail, window.location.href);
        await new Promise<void>((resolve) =>
          until(() => user() !== undefined, resolve)
        );

        await new Promise<void>((resolve) =>
          until(() => isDatabaseConnected(), resolve)
        );

        // Animation - Sign in with magic link
        await withTransition(() => {
          cleanUpForNonSignInPages();
          cleanUp?.();
        });

        // confetti({
        //   particleCount: 100,
        //   spread: 70,
        // });
      }
    },
    async (error) => {
      await handleError(error, async () => {
        setBanner({
          intent: "error",
          message: "This email is associated with another account",
        });
      });
      cleanUpForNonSignInPages();
      await showError(error);
      cleanUp?.();
    }
  );
}
