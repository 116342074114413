import {
  onActionPressed,
  onCancelPressed,
} from "../../../helpers/engine/handleKeyboard";
import { BoundingBox } from "../../../models/BoundingBox";
import { handleAction } from "./handleAction";

export function initiateAction(
  this: {
    direction: number;
    nextAction?: (() => void) | undefined;
    x: number;
    y: number;
  } & BoundingBox
) {
  if (this.x % 16 !== 0 || this.y % 16 !== 0) {
    return;
  }

  if (onActionPressed) {
    handleAction(this);
  }

  if (onCancelPressed && this.nextAction) {
    this.nextAction();
    return;
  }
}
