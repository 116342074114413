import { drawSprite } from "../../helpers/engine/drawSprite";
import { sprCharacter } from "../../sprites/sprCharacter";
import { ActionObject } from "../objPlayer/helpers/handleAction";
import { readMessage } from "../objPlayer/helpers/readMessage";
import { objWall } from "../objWall/objWall";

export class objNPC extends objWall {
  public direction: number;

  public height: number;
  public width: number;

  public speed: number;

  public x: number;
  public y: number;
  public depth: number;

  private message: () => string;
  private voice: string[];

  constructor({
    direction,
    message,
    voice,
    x,
    y,
  }: {
    direction: number;
    message: () => string;
    voice: string[];
    x: number;
    y: number;
  }) {
    const height = 16;
    const width = 16;

    super(x, y, height, width);

    this.message = message;
    this.voice = voice;

    this.direction = direction;

    this.speed = 0;

    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
    this.depth = this.y;
  }

  public action = async (objectActioning: ActionObject) => {
    readMessage(objectActioning, this.message, this.voice);
  };

  public render = (ctx: CanvasRenderingContext2D) => {
    const animations = 2;
    const interpolatedFrames = 1;
    const animationLength =
      sprCharacter.columns / animations + interpolatedFrames;

    const getRow = (direction: number) => {
      switch (direction) {
        case 90:
          return 0;
        case 270:
          return 1;
        case 180:
          return 2;
        case 0:
          return 3;
        default:
          return 0;
      }
    };

    const directionIndex = getRow(this.direction);

    let animationIndex =
      (Math.round((this.x + this.y) / 8) - 1) % animationLength;
    if (animationIndex === 3) {
      animationIndex = 1;
    }

    drawSprite({
      columnIndex:
        animationIndex +
        (this.speed > 0 ? (this.speed > 1 ? animationLength - 1 : 0) : 0),
      ctx,
      rowIndex: directionIndex,
      sprite: sprCharacter,
      x: this.x,
      y: this.y,
    });
  };
}
