import { createSignal, Setter } from "solid-js";

export const [localStorageSignals, setLocalStorageSignals] = createSignal<
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValue: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signalSetter: Setter<any>;
  }[]
>([]);
