import { onMenuPressed } from "../../../../helpers/engine/handleKeyboard";
import { handleItemsMenu } from "./itemsMenu/handleItemsMenu";
import {
  handleMainMenu,
  setCurrentMenuItemIndex,
} from "./mainMenu/handleMainMenu";

export type Menu =
  | "encyclopedia"
  | "items"
  | "main"
  | "mon"
  | "options"
  | undefined;

export let currentMenu: Menu;
export function setCurrentMenu(menu: Menu) {
  currentMenu = menu;
}

export default async function handleMenu() {
  switch (currentMenu) {
    case "main":
      handleMainMenu();
      break;
    case "items":
      handleItemsMenu();
      break;
    default:
      if (onMenuPressed) {
        setCurrentMenuItemIndex(0);
        currentMenu = "main";
      }
      break;
  }
}
