// TODO (4 - refactor): Refactor
import "./Ripples.scss";

import cn from "clsx";
import Solid, { createSignal, For, onCleanup } from "solid-js";

import isClickEvent from "./helpers/isClickEvent";

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {
  color?: string;
  colorDark?: string;
}

interface Ripple {
  height: number;
  isLandscape: boolean;
  isReleased: boolean;
  isRemoved: boolean;
  mouseX: string;
  mouseY: string;
  width: number;
  x: number;
  y: number;
}

export const Ripples: Solid.Component<Props> = ({
  class: className,
  children,
  color,
  colorDark,
  ref,
}) => {
  let rippleWrapper: HTMLDivElement | undefined;

  const rippleWrapperRef = (element: HTMLDivElement) => {
    rippleWrapper = element;
    if (typeof ref === "function") {
      ref(element);
    }
    ref = element;
  };

  const [rippleSignals, setRippleSignals] = createSignal<
    Solid.Signal<Ripple>[]
  >([]);

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "attributes") {
        const target = mutation.target as HTMLElement;

        if (window.getComputedStyle(target).display !== "none") {
          return;
        }

        if (rippleWrapper && target.contains(rippleWrapper)) {
          setRippleSignals([]);
        }
      }
    });
  });

  observer.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  onCleanup(() => {
    setRippleSignals([]);
    observer.disconnect();
  });

  const ripple = (
    event:
      | Solid.JSX.EventHandler<HTMLElement, KeyboardEvent>["arguments"][0]
      | Solid.JSX.EventHandler<HTMLElement, MouseEvent>["arguments"][0]
  ) => {
    if (!isClickEvent(event)) {
      return;
    }

    if (event.target.classList.contains("ripples-noClick")) {
      return;
    }

    const { height, x, y, width } = event.currentTarget.getBoundingClientRect();

    const centerX = x + width / 2;
    const centerY = y + height / 2;

    const clientX = ("clientX" in event ? event.clientX : centerX) - x;
    const clientY = ("clientY" in event ? event.clientY : centerY) - y;

    const mouseX = `${clientX}px`;
    const mouseY = `${clientY}px`;

    const newRipple: Ripple = {
      height,
      isLandscape: height < width,
      isReleased: false,
      isRemoved: false,
      mouseX,
      mouseY,
      width,
      x,
      y,
    };

    // TODO: Re-enable this linting rule
    // eslint-disable-next-line solid/reactivity
    setRippleSignals([...rippleSignals(), createSignal<Ripple>(newRipple)]);
  };

  const releaseRipple = () => {
    rippleSignals().forEach(([ripple, setRipple]) =>
      setRipple({ ...ripple(), isReleased: true })
    );
  };

  const removeRipple =
    (index: number) =>
    (
      event: Solid.JSX.EventHandler<
        HTMLDivElement,
        AnimationEvent
      >["arguments"][0]
      // TODO: Re-enable this linting rule
      // eslint-disable-next-line solid/reactivity
    ) => {
      if (event.target !== event.currentTarget) {
        return;
      }

      const [ripple, setRipple] = rippleSignals()[index];
      setRipple({ ...ripple(), isRemoved: true });

      if (rippleSignals().every(([ripple]) => ripple().isRemoved)) {
        setRippleSignals([]);
      }
    };

  return (
    <div
      class={cn("ripples", className)}
      onKeyDown={ripple}
      onKeyUp={releaseRipple}
      onPointerDown={ripple}
      onPointerLeave={releaseRipple}
      onPointerUp={releaseRipple}
      ref={rippleWrapperRef}
    >
      {children}
      <For each={rippleSignals()}>
        {([ripple], index) => (
          <div
            class={cn(
              "ripples__rippleWrapper",
              ripple().isReleased && "ripples__rippleWrapper-isReleased"
            )}
            // TODO: Re-enable this linting rule
            // eslint-disable-next-line solid/reactivity
            onAnimationEnd={removeRipple(index())}
          >
            <div
              class={cn(
                "ripples__ripple",
                ripple().isLandscape
                  ? "ripples__ripple-isLandscape"
                  : "ripples__ripple-isPortrait"
              )}
              style={{
                "--color": color,
                "--color-dark": colorDark,
                "--mouseX": ripple().mouseX,
                "--mouseY": ripple().mouseY,
              }}
            />
          </div>
        )}
      </For>
    </div>
  );
};
