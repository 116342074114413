import { Config } from "tailwindcss/types/config";

export default {
  content: ["./index.html", "./src/**/*.{js,jsx,ts,tsx}"],
  darkMode: "class",
  future: {
    hoverOnlyWhenSupported: true,
  },
  plugins: [],
  theme: {
    extend: {
      boxShadow: {
        "t-md":
          "0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -2px rgba(0, 0, 0, 0.1)",
      },
    },
    screens: {
      header: "1280px",
      hero: "512px",
      mobile: "376px",
      signInActions: "680px",
      tablet: "768px",
    },
  },
} as Config;
