import { bgGrass } from "../backgrounds/bgGrass";
import { Room } from "../helpers/engine/room";
import { myItems } from "../objects/objPlayer/objPlayer";

export const rmOverworld: Room = {
  backgroundColor: "#a7d88e",
  backgroundShader: (
    ctx: CanvasRenderingContext2D,
    viewX: number,
    viewY: number
  ) => {
    const shaderLeftEdge =
      Math.floor((viewX - ctx.canvas.width) / bgGrass.width) * bgGrass.width;
    const shaderTopEdge =
      Math.floor((viewY - ctx.canvas.height) / bgGrass.height) * bgGrass.height;

    for (
      let x = shaderLeftEdge;
      x < shaderLeftEdge + ctx.canvas.width * 2 + bgGrass.width;
      x += bgGrass.width
    ) {
      for (
        let y = shaderTopEdge;
        y < shaderTopEdge + ctx.canvas.height * 2 + bgGrass.height;
        y += bgGrass.height
      ) {
        ctx.drawImage(bgGrass, x - viewX, y - viewY);
      }
    }
  },
  map: [
    {
      properties: {
        id: "1",
        item: "potato",
        x: 0 + 16 * -10,
        y: 0,
      },
      type: "item",
    },
    {
      properties: {
        id: "2",
        item: "potato",
        x: 0 + 16 * -11,
        y: 0,
      },
      type: "item",
    },
    {
      properties: {
        direction: 270,
        message: () => {
          if (
            myItems.potato?.collectedIds.includes("1") &&
            myItems.potato?.collectedIds.includes("2")
          ) {
            return "Thank you for finding my potatoes!\n\nYou can keep them as a reward.";
          }

          if (
            myItems.potato?.collectedIds.includes("1") ||
            myItems.potato?.collectedIds.includes("2")
          ) {
            return "You found a potato, just one more\nto go.";
          }

          return "My potatoes ran away, can you help\nme find them?";
        },
        voice: ["C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5"],
        x: 0,
        y: 0 + 16 * 2,
      },
      type: "npc",
    },
    {
      properties: {
        direction: 90,
        doorNumber: 0,
        roomId: "rmInside",
        x: 0,
        y: 0 + 16 * -1,
      },
      type: "door",
    },
    {
      properties: {
        message: "No mail today.",
        x: 0 - 16 * 2,
        y: 0 - 16 * 1,
      },
      type: "letterbox",
    },
    {
      properties: {
        x: 0 + 16 * 7,
        y: 0 - 16,
      },
      type: "tree",
    },
    {
      properties: {
        message: "It's a sign.",
        x: 0 + 16 * 3,
        y: 0 + 16 * 3,
      },
      type: "sign",
    },
    {
      properties: {
        message: "It's another sign.",
        x: 0 - 16,
        y: 0 + 16 * 6,
      },
      type: "sign",
    },
    {
      properties: {
        x: 0 + 16 * 2,
        y: 0 + 16 * -1,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 3,
        y: 0 + 16 * -1,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 4,
        y: 0 + 16 * -1,
      },
      type: "grass",
    },

    {
      properties: {
        x: 0 + 16 * 2,
        y: 0 + 16 * 0,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 3,
        y: 0 + 16 * 0,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 4,
        y: 0 + 16 * 0,
      },
      type: "grass",
    },

    {
      properties: {
        x: 0 + 16 * 2,
        y: 0 + 16 * 1,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 3,
        y: 0 + 16 * 1,
      },
      type: "grass",
    },
    {
      properties: {
        x: 0 + 16 * 4,
        y: 0 + 16 * 1,
      },
      type: "grass",
    },
  ],
};
