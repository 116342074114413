import { createSignal } from "solid-js";

import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingCanvasStrokes } from "@/features/saving/state/isSaving";

import { ToolColor } from "./colorSwatch";

export type Stroke = {
  clientX: number;
  clientY: number;
  color?: ToolColor;
}[];

export const [currentStroke, setCurrentStroke] = createSignal<Stroke>([]);

export const defaultAllStrokes: Stroke[] = [];

export const {
  isConnected: isAllStrokesSynced,
  getSignal: allStrokes,
  setSignal: setAllStrokesSignal,
  setSignalConnected: setAllStrokes,
} = createConnectedSignal<(Stroke | undefined)[]>({
  databaseLocation: (uid?: string) =>
    `v1/etchasketch/${uid ?? user()?.uid}/strokes`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultAllStrokes,
  onEnd: () => setIsSavingCanvasStrokes(false),
  onStart: () => setIsSavingCanvasStrokes(true),
});
