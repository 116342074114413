import { FirebaseError } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { onValue, ref, set } from "firebase/database";
import { createSignal } from "solid-js";

import { isSignedIn } from "@/features/authentication/state/isSignedIn";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { database } from "@/helpers/initializeFirebase";

import { setConnectedSignals } from "../state/connectedSignals";

export function createConnectedSignal<T>({
  databaseLocation,
  decode = (value: string) => value as unknown as T,
  encode = (value: T) => JSON.stringify(value),
  errorHandler,
  initialValue,
  onEnd,
  onStart,
}: {
  databaseLocation: (uid?: string) => string;
  decode?: (value: string) => T;
  encode?: (value: T) => string;
  errorHandler: (error: FirebaseError) => Promise<void>;
  initialValue: T;
  onEnd: () => void;
  onStart: () => void;
}) {
  const [isConnected, setIsConnected] = createSignal(false);

  const [getSignal, setSignal] = createSignal<T>(initialValue);

  // Handle resetting the value on sign out
  setConnectedSignals((prev) => [
    ...prev,
    {
      initialValue,
      isConnected,
      signalSetter: setSignal,
    },
  ]);

  // Handle updates coming in from FireBase
  onAuthStateChanged(getAuth(), (newUser) => {
    onValue(
      ref(database, databaseLocation(newUser?.uid)),
      (snapshot) => {
        if (snapshot.exists()) {
          try {
            setSignal(() => decode(snapshot.val()) ?? initialValue);
          } catch (error) {
            console.log("database parsing error", snapshot.val());
          }
        }

        setIsConnected(true);
      },
      () => {
        setIsConnected(isSignedIn());
      }
    );
  });

  // Handle updates going out to FireBase
  async function setSignalConnected(value: T) {
    onStart();

    setSignal(() => value);

    await tryFirebase(async () => {
      await set(
        ref(database, databaseLocation()),
        encode(value) ?? initialValue
      );
    }, errorHandler);

    onEnd();
  }

  return { getSignal, isConnected, setSignal, setSignalConnected };
}
