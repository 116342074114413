import Solid, { createSignal } from "solid-js";

export interface Toast {
  actions?: {
    callback: () => Promise<void>;
    label: () => Solid.JSXElement;
  }[];
  id?: string;
  isProcessing?: boolean;
  message?: string;
}

export const [toasts, setToasts] = createSignal<Toast[]>([]);
