import { useRegisterSW } from "virtual:pwa-register/solid";

export function useUpdate() {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(serviceWorkerUrl, serviceWorkerRegistration) {
      setInterval(async () => {
        if (!(!serviceWorkerRegistration?.installing && navigator)) {
          return;
        }

        if ("connection" in navigator && !navigator.onLine) {
          return;
        }

        const response = await fetch(serviceWorkerUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache",
          },
        });

        if (response?.status === 200) {
          await serviceWorkerRegistration?.update();
        }
      }, 60 * 1000);
    },
    onRegisterError(error) {
      console.error("SW registration error", error);
    },
  });

  return { update: updateServiceWorker, updateReady: needRefresh };
}
