import { createEffect } from "solid-js";

import { calculatedTheme } from "@/features/userSettings/state/theme";

export function useDarkMode() {
  createEffect(() => {
    if (
      calculatedTheme() === "dark" ||
      (calculatedTheme() === "system" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  });
}
