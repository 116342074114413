import { drawSprite } from "../../../helpers/engine/drawSprite";
import { viewX, viewY } from "../../../helpers/engine/room";
import { sprChatFrame } from "../../../sprites/sprChatFrame";

export default function renderMessage(
  message: string,
  messageTime: Date | undefined,
  ctx: CanvasRenderingContext2D
) {
  const messageHeight = sprChatFrame.spriteHeight() - 2;

  const messageX = viewX;

  const messageY = viewY + ctx.canvas.height - messageHeight;

  ctx.font = "16px Mon";
  ctx.fillStyle = "#FFF";

  drawSprite({
    ctx,
    sprite: sprChatFrame,
    x: messageX,
    y: messageY,
  });

  message
    .slice(0, messageTime ? (Date.now() - Number(messageTime)) / 20 : 0)
    .split("\n")
    .forEach((line, index) => {
      // 5px font
      // ctx.fillStyle = "#DDD";
      // ctx.fillText(line, messageX + 19 + 1, messageY + 24 + 1 + 12 * index);

      // ctx.fillStyle = "#222";
      // ctx.fillText(line, messageX + 19, messageY + 24 + 12 * index);

      // 8px font
      ctx.fillStyle = "#DDD";
      ctx.fillText(line, messageX + 19 + 1, messageY + 25 + 1 + 13 * index);

      ctx.fillStyle = "#222";
      ctx.fillText(line, messageX + 19, messageY + 25 + 13 * index);
    });
}
