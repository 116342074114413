// Your web app's Firebase configuration

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2KEEsHNiBo41YWLdRGAWcJb6q4T6up_I",
  appId: "1:1068973204318:web:91919776debcae163ae5cf",
  authDomain: "scribble-desk.firebaseapp.com",
  databaseURL:
    "https://scribble-desk-default-rtdb.asia-southeast1.firebasedatabase.app/",
  measurementId: "G-7PNR6Y3FX4",
  messagingSenderId: "1068973204318",
  projectId: "scribble-desk",
  storageBucket: "scribble-desk.appspot.com",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const analytics = getAnalytics(app);
