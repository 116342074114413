import Solid, { createSignal } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { EmailIcon } from "@/icons/EmailIcon";

import { updateEmail } from "../../actions/updateEmail";
import { isSigningOut } from "../../state/isSigningOut";
import { user } from "../../state/user";

export type SignInMethod = "guest" | "email" | "password" | "new" | undefined;

interface Props {}

export const UpdateEmailForm: Solid.Component<Props> = () => {
  const [newEmail, setNewEmail] = createSignal("");
  const [isVerifyingEmail, setIsVerifyingEmail] = createSignal(false);

  async function submitEmail() {
    setIsVerifyingEmail(true);
    await updateEmail(newEmail());
    setIsVerifyingEmail(false);
  }

  const isVerifyEmailDisabled = () =>
    !newEmail() ||
    !emailNeedsVerification() ||
    isVerifyingEmail() ||
    isSigningOut();

  const emailNeedsVerification = () =>
    !user()?.emailVerified || (newEmail() && newEmail() !== user()?.email);

  return (
    <div class="flex gap-3">
      <div class="flex-1">
        <Input
          isDisabled={isVerifyingEmail() || isSigningOut()}
          label="Email"
          onInput={(event) => {
            setNewEmail(event.currentTarget.value);
          }}
          onKeyPress={(event) =>
            (async (event) => {
              if (event.key !== "Enter") {
                return;
              }

              if (!isVerifyEmailDisabled()) {
                await submitEmail();
              }
            })(event)
          }
          type="email"
          value={user()?.email ?? ""}
        />
      </div>
      <div>
        <Button
          class="relative"
          isDisabled={isVerifyEmailDisabled()}
          isNextToInput
          onClick={submitEmail}
        >
          {isVerifyingEmail() ? (
            <LoadingSpinner class="h-6 w-6" />
          ) : (
            <>
              <EmailIcon class="h-6 w-6" />
              {emailNeedsVerification() && (
                <div
                  class="absolute left-8 top-2 rounded-full bg-red-500 p-1"
                  data-testid="notification"
                />
              )}
            </>
          )}
          Verify
        </Button>
      </div>
    </div>
  );
};
