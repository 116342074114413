import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { setBanner } from "@/features/banner/state/banner";
import { isDatabaseConnected } from "@/features/database/selectors/isDatabaseConnected";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { until } from "@/helpers/until";
import withTransition from "@/helpers/withTransition";

import { email, password } from "../components/forms/SignInForm";
import { addEmailToLocalStorage } from "../helpers/addEmailToLocalStorage";
import { setIsSigningIn } from "../state/isSigningIn";
import { setSignInMethod } from "../state/signInMethod";
import { user } from "../state/user";

export async function signUserUp() {
  setIsSigningIn(true);
  setSignInMethod("new");

  function cleanUp() {
    setIsSigningIn(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email(), password());
      await new Promise<void>((resolve) =>
        until(() => user() !== undefined, resolve)
      );
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        setBanner({
          intent: "success",
          message:
            "Your account has been created\n\nBefore signing in, please verify your email using the link we sent you.",
        });
        // confetti({
        //   particleCount: 100,
        //   spread: 70,
        // });
        await signOut(auth);
      }

      cleanUp();
    },
    async (error) => {
      await handleError(error, async () => {
        await tryFirebase(
          async () => {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email(), password());
            await new Promise<void>((resolve) =>
              until(() => user() !== undefined, resolve)
            );
            if (auth.currentUser && !auth.currentUser.emailVerified) {
              await sendEmailVerification(auth.currentUser);
              setBanner({
                intent: "success",
                message:
                  "Your account has been created\n\nBefore signing in, please verify your email using the link we sent you.",
              });
              // confetti({
              //   particleCount: 100,
              //   spread: 70,
              // });
              await signOut(auth);
            }

            if (auth.currentUser?.emailVerified) {
              await new Promise<void>((resolve) =>
                until(() => isDatabaseConnected(), resolve)
              );

              // Animation - Sign in via sign up
              await withTransition(() => {
                cleanUp();
              });
            } else {
              cleanUp();
            }
          },
          async () => {
            await tryFirebase(
              async () => {
                const auth = getAuth();
                await signOut(auth);
                await sendSignInLinkToEmail(auth, email(), {
                  handleCodeInApp: true,
                  url: window.location.href,
                });
                addEmailToLocalStorage(email());
              },
              async () => {
                // Doing anything here would give away whether or not the email is in use
              }
            );

            cleanUp();

            setBanner({
              intent: "success",
              message:
                "Your account has been created\n\nBefore signing in, please verify your email using the link we sent you.",
            });
            // confetti({
            //   particleCount: 100,
            //   spread: 70,
            // });
          }
        );
      });
      await showError(error);
      cleanUp();
    }
  );
}
