import { BoundingBox } from "../../models/BoundingBox";

export class objWall implements BoundingBox {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number
  ) {}
}
