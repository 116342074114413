import "./Timer.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid, { createSignal } from "solid-js";

import { Button } from "@/components/Button/Button";
import { PauseIcon } from "@/icons/PauseIcon";
import { PlayIcon } from "@/icons/PlayIcon";
import { RepeatIcon } from "@/icons/RepeatIcon";
import { ResetIcon } from "@/icons/ResetIcon";

import { setTimers, timers } from "../../DinnerTimer";
import formatTime from "../../helpers/formatTime";
import sum from "../../helpers/sum";

export type TimerInterface =
  | {
      isRunning: true;
      name: string;
      isRepeat: boolean;
      startTime: number;
      time: number[];
    }
  | {
      isRunning: false;
      name: string;
      isRepeat: boolean;
      time: number[];
      timeRemaining: number;
    };

const timerClasses = cva("Timer", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof timerClasses> {
  timer: TimerInterface;
  timerIndex: number;
}

export let timeRef = Date.now();
const [time, setTimeSignal] = createSignal(timeRef);
function setTime(newTime: number) {
  timeRef = newTime;
  setTimeSignal(newTime);
}
function updateTime() {
  setTime(Date.now());

  requestAnimationFrame(() => {
    updateTime();
  });
}
updateTime();

export const Timer: Solid.Component<Props> = ({
  class: className,
  timer,
  timerIndex,
  ...attributes
}) => (
  <div
    class={cn(
      timerClasses({}),
      className,
      "grid grid-cols-[subgrid] items-center col-start-1 col-end-4 p-4"
    )}
    {...attributes}
  >
    <div
      class="Timer__name pr-11 -mr-11"
      contentEditable
      onChange={(event) => {
        const newTimers = [...timers()];

        newTimers[timerIndex] = {
          ...timer,
          name: event.currentTarget.textContent ?? "",
        };

        setTimers(newTimers);
      }}
    >
      {timer.name}
    </div>
    <div class="Timer__time">
      {formatTime(
        Math.ceil(
          (timer.isRunning
            ? timer.isRepeat
              ? sum(...timer.time) -
                ((time() - timer.startTime) % sum(...timer.time))
              : timer.startTime + sum(...timer.time) - time()
            : timer.timeRemaining) / 1000
        )
      )}
    </div>
    <div class="Timer__controls flex flex-col items-end tablet:flex-row">
      <Button
        class={cn(
          "Timer__repeat flex flex-row-reverse tablet:flex-row",
          timer.isRepeat && "Timer__repeat--active"
        )}
        onClick={() => {
          const newTimers = [...timers()];

          newTimers[timerIndex] = {
            ...timer,
            isRepeat: !timer.isRepeat,
          };

          setTimers(newTimers);
        }}
      >
        <RepeatIcon class="h-6 w-6" />
        Repeat
      </Button>
      <Button
        class="Timer__reset flex flex-row-reverse tablet:flex-row"
        onClick={() => {
          const newTimers = [...timers()];

          newTimers[timerIndex] = {
            ...timer,
            isRunning: false,
            timeRemaining: sum(...timer.time),
          };

          setTimers(newTimers);
        }}
      >
        <ResetIcon class="h-6 w-6" />
        Reset
      </Button>
      <Button
        class="Timer__play flex flex-row-reverse tablet:flex-row tablet:min-w-[110px]"
        onClick={() => {
          const newTimers = [...timers()];

          if (timer.isRunning) {
            newTimers[timerIndex] = {
              ...timer,
              isRunning: false,
              timeRemaining: sum(...timer.time) - (time() - timer.startTime),
            };
          } else {
            newTimers[timerIndex] = {
              ...timer,
              isRunning: true,
              startTime: time() - (sum(...timer.time) - timer.timeRemaining),
            };
          }

          setTimers(newTimers);
        }}
      >
        {timer.isRunning ? (
          <PauseIcon class="h-6 w-6" />
        ) : (
          <PlayIcon class="h-6 w-6" />
        )}
        {timer.isRunning ? "Pause" : "Start"}
      </Button>
    </div>
  </div>
);
