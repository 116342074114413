import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

import { banner, setBanner } from "@/features/banner/state/banner";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import withTransition from "@/helpers/withTransition";

import { addEmailToLocalStorage } from "../helpers/addEmailToLocalStorage";
import { setIsReAuthenticating } from "../state/isReAuthenticating";
import { setIsRequestingReAuth } from "../state/isRequestingReAuth";
import { setSignInMethod } from "../state/signInMethod";

export async function reAuthWithEmail() {
  setIsReAuthenticating(true);
  setSignInMethod("email");

  function cleanUp() {
    setIsReAuthenticating(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();

      if (!auth.currentUser?.email) {
        throw new Error("No email provided");
      }

      await sendSignInLinkToEmail(auth, auth.currentUser.email, {
        handleCodeInApp: true,
        url: window.location.href,
      });
      addEmailToLocalStorage(auth.currentUser.email);

      if (banner()) {
        setBanner({
          intent: "success",
          message: "Check your email for a link to reauthenticate",
        });
      }
      // Animation - Send magic link for re-authentication
      await withTransition(() => {
        setIsRequestingReAuth(false);
        cleanUp();
      });
      setBanner({
        intent: "success",
        message: "Check your email for a link to reauthenticate",
      });
    },
    async (error) => {
      await handleError(error);
      await showError(error);
      cleanUp();
    }
  );
}
