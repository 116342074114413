import Solid, { createEffect } from "solid-js";
import colors from "tailwindcss/colors";

import { canvasView } from "../state/canvasView";
import { currentShape, shapes } from "../state/shapes";
import { allStrokes, currentStroke } from "../state/strokes";

// TODO (1 - feature): Smooth inking - https://github.com/fabricjs/fabric.js/blob/master/src/util/path/index.ts#L905

export default function renderCanvas(
  canvasSize: Solid.Accessor<{ height: number; width: number }>,
  canvasRef: () => HTMLCanvasElement | undefined
) {
  createEffect(() => {
    canvasSize();

    const canvasElement = canvasRef();

    if (!canvasElement) {
      return;
    }
    const scale = 2;

    canvasElement.width =
      (canvasElement.parentElement?.clientWidth || 0) * scale;
    canvasElement.height =
      (canvasElement.parentElement?.clientHeight || 0) * scale;

    const ctx = canvasElement.getContext("2d");

    if (!ctx) {
      return;
    }

    ctx.translate(
      canvasView().x * scale * canvasView().zoom,
      canvasView().y * scale * canvasView().zoom
    );

    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.lineWidth = 3 * scale * canvasView().zoom;

    ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    [...allStrokes(), currentStroke()]
      .filter((stroke) => stroke?.[0])
      .forEach((stroke) => {
        if (!stroke) {
          return;
        }

        if (stroke?.[0].color) {
          ctx.strokeStyle =
            colors[stroke[0].color][500] || String(colors[stroke[0].color]);
        } else {
          ctx.strokeStyle = "black";
        }

        ctx.beginPath();

        ctx.moveTo(
          stroke[0].clientX * scale * canvasView().zoom,
          stroke[0].clientY * scale * canvasView().zoom
        );

        stroke.forEach((point) => {
          ctx.lineTo(
            point.clientX * scale * canvasView().zoom,
            point.clientY * scale * canvasView().zoom
          );
        });

        ctx.stroke();
      });

    [...shapes(), currentShape()].forEach((shape) => {
      if (!shape) {
        return;
      }

      ctx.strokeStyle = colors[shape.color][500] || String(colors[shape.color]);

      switch (shape.shape) {
        case "rectangle":
          ctx.beginPath();
          ctx.rect(
            shape.x1 * scale * canvasView().zoom,
            shape.y1 * scale * canvasView().zoom,
            (shape.x2 - shape.x1) * scale * canvasView().zoom,
            (shape.y2 - shape.y1) * scale * canvasView().zoom
          );
          ctx.stroke();
          break;
        case "circle": {
          ctx.beginPath();
          const width = shape.x2 - shape.x1;
          const height = shape.y2 - shape.y1;

          ctx.ellipse(
            (shape.x1 + width / 2) * scale * canvasView().zoom,
            (shape.y1 + height / 2) * scale * canvasView().zoom,
            Math.abs(width / 2) * scale * canvasView().zoom,
            Math.abs(height / 2) * scale * canvasView().zoom,
            0,
            0,
            Math.PI * 2
          );
          ctx.stroke();
          break;
        }
      }
    });
  });
}
