declare global {
  interface Document {
    startViewTransition: (callBack: () => void) => {
      finished: Promise<void>;
      ready: Promise<void>;
    };
  }
}

export default async function withTransition(callBack: () => void) {
  if (!document.startViewTransition) {
    callBack();
  } else {
    const { finished, ready } = document.startViewTransition(async () => {
      await callBack();
    });

    ready.catch((error) => {
      console.error(error);
    });

    await finished;
  }
}
