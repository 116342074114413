import { BoundingBox } from "../../models/BoundingBox";
import { RoomObject } from "../../models/RoomObject";
import { currentRoom, player } from "../../Mon";
import { objItem } from "../../objects/objItem/objItem";
import { myItems } from "../../objects/objPlayer/objPlayer";

export function collisionsWhenPlaced<T>(
  objectToPlace: BoundingBox,
  x: number,
  y: number,
  classToCollideWith?: new (...args: never[]) => T
) {
  return [player.current as RoomObject, ...currentRoom.current.objects]
    .filter((potentialItem) => {
      if (
        potentialItem instanceof objItem &&
        myItems[potentialItem.item]?.collectedIds.includes(potentialItem.id)
      ) {
        return false;
      }

      return true;
    })
    .filter((objectToCollideWith) => {
      if (!objectToCollideWith) {
        return false;
      }

      return (
        (!classToCollideWith ||
          objectToCollideWith instanceof classToCollideWith) &&
        objectToCollideWith !== objectToPlace &&
        typeof objectToCollideWith.x === "number" &&
        typeof objectToCollideWith.y === "number" &&
        typeof objectToCollideWith.width === "number" &&
        typeof objectToCollideWith.height === "number" &&
        x + objectToPlace.width > objectToCollideWith.x &&
        x < objectToCollideWith.x + objectToCollideWith.width &&
        y + objectToPlace.height > objectToCollideWith.y &&
        y < objectToCollideWith.y + objectToCollideWith.height
      );
    });
}
