import { createEffect, createSignal } from "solid-js";

export default function useElementSize(element: () => HTMLElement | undefined) {
  const [elementSize, setElementSize] = createSignal({ height: 0, width: 0 });

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const currentElement = element();

      if (currentElement && entry.target === currentElement) {
        setElementSize({
          height: currentElement.clientHeight,
          width: currentElement.clientWidth,
        });
      }
    }
  });

  createEffect(() => {
    const previousElement = element();

    if (!previousElement) {
      return;
    }

    resizeObserver.observe(previousElement);

    return () => {
      if (!previousElement) {
        return;
      }

      resizeObserver.unobserve(previousElement);
    };
  });

  return {
    elementSize,
  };
}
