import "./ShapePicker.scss";

import Solid, { createSignal, For } from "solid-js";
import colors from "tailwindcss/colors";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";

import { toolColor } from "../../../selectors/toolColor";
import { Shape } from "../../../state/shapes";

interface Props extends Solid.ComponentProps<typeof Dropdown> {
  initialShape: Shape["shape"];
  isSelected?: boolean;
  onClick?: (event: MouseEvent) => void;
  onSetShape?: (shape: Shape["shape"]) => void;
}

export const ShapePicker: Solid.Component<Props> = ({
  onSetShape,
  initialShape,
  isSelected,
  onClick,
  ...attributes
}) => {
  const [shape, setShapeSignal] = createSignal<Shape["shape"]>(initialShape);

  function setShape(shape: Shape["shape"]) {
    setShapeSignal(shape);
    onSetShape?.(shape);
  }

  const shapeMap = () => ({
    circle: (
      <svg
        class="w-6 h-6"
        fill="none"
        stroke={
          toolColor() === "black" || toolColor() === "white"
            ? "currentColor"
            : colors[toolColor()][500]
        }
        stroke-width="2"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="10" />
      </svg>
    ),
    rectangle: (
      <svg
        class="w-6 h-6"
        fill="none"
        stroke={
          toolColor() === "black" || toolColor() === "white"
            ? "currentColor"
            : colors[toolColor()][500]
        }
        stroke-width="2"
        viewBox="0 0 24 24"
      >
        <rect height="20" rx="2" ry="2" width="20" x="2" y="2" />
      </svg>
    ),
  });

  return (
    <Dropdown alignment={() => "center"} {...attributes}>
      <DropdownButton
        class="text-gray-500 dark:text-gray-300"
        hasCaret={false}
        isSelected={isSelected}
        onClick={(event) => {
          if (!isSelected) {
            event.preventDefault();
            setShape(shape());
          }
          onClick?.(event);
        }}
      >
        {shapeMap()[shape()]}
      </DropdownButton>
      <DropdownMenu class="flex flex-wrap">
        <For each={["rectangle", "circle"] as Shape["shape"][]}>
          {(s) => (
            <MenuItem
              class="text-gray-500 dark:text-gray-300"
              isSelected={s === shape()}
              onSelect={() => {
                setShape(s);
              }}
            >
              {shapeMap()[s]}
            </MenuItem>
          )}
        </For>
      </DropdownMenu>
    </Dropdown>
  );
};
