import bgMusic from "../../audio/bgmTown.mp3";
import { RoomObject } from "../../models/RoomObject";
import { currentRoom, player } from "../../Mon";
import { objDoor } from "../../objects/objDoor/objDoor";
import { objGrass } from "../../objects/objGrass/objGrass";
import { objItem } from "../../objects/objItem/objItem";
import { objLetterbox } from "../../objects/objLetterbox/objLetterbox";
import { objNPC } from "../../objects/objNPC/objNPC";
import { objOtherPlayers } from "../../objects/objOtherPlayer/objOtherPlayers";
import { objSign } from "../../objects/objSign/objSign";
import { objTree } from "../../objects/objTree/objTree";

export let viewX = 0;
export let viewY = 0;

export type Room = {
  backgroundColor: string;
  backgroundShader?: (
    ctx: CanvasRenderingContext2D,
    viewX: number,
    viewY: number
  ) => void;
  map: RoomMap;
};

export type RoomMap = (
  | {
      properties: ConstructorParameters<typeof objDoor>[0];
      type: "door";
    }
  | {
      properties: ConstructorParameters<typeof objGrass>[0];
      type: "grass";
    }
  | {
      properties: ConstructorParameters<typeof objItem>[0];
      type: "item";
    }
  | {
      properties: ConstructorParameters<typeof objLetterbox>[0];
      type: "letterbox";
    }
  | {
      properties: ConstructorParameters<typeof objNPC>[0];
      type: "npc";
    }
  | {
      properties: ConstructorParameters<typeof objSign>[0];
      type: "sign";
    }
  | {
      properties: ConstructorParameters<typeof objTree>[0];
      type: "tree";
    }
)[];

export class room {
  public audio;

  public backgroundColor: string;
  public backgroundShader?: (
    ctx: CanvasRenderingContext2D,
    viewX: number,
    viewY: number
  ) => void;

  public framerate;

  public objects: RoomObject[] = [];

  public cameraObject: RoomObject | undefined;

  constructor({
    backgroundColor,
    backgroundShader,
    backgroundMusic = bgMusic,
    framerate = 60,
    map,
  }: {
    backgroundColor: string;
    backgroundShader?: (
      ctx: CanvasRenderingContext2D,
      viewX: number,
      viewY: number
    ) => void;
    backgroundMusic?: string;
    framerate?: number;
    map: RoomMap;
  }) {
    this.audio = new Audio(backgroundMusic);
    this.audio.loop = true;

    this.backgroundColor = backgroundColor;
    this.backgroundShader = backgroundShader;

    this.framerate = framerate;

    const newObjects = map
      .map((object) => {
        let newObject;

        switch (object.type) {
          case "door":
            newObject = new objDoor(object.properties);
            break;
          case "grass":
            newObject = new objGrass(object.properties);
            break;
          case "item":
            newObject = new objItem(object.properties);
            break;
          case "letterbox":
            newObject = new objLetterbox(object.properties);
            break;
          case "npc":
            newObject = new objNPC(object.properties);
            break;
          case "sign":
            newObject = new objSign(object.properties);
            break;
          case "tree":
            newObject = new objTree(object.properties);
            break;
        }

        return newObject;
      })
      .filter((object) => object);

    this.objects.push(new objOtherPlayers(), ...newObjects);

    setInterval(this.step, 1000 / this.framerate);
  }

  private step = () => {
    if (currentRoom.current !== this) {
      return;
    }

    player.current?.step();

    for (const object of this.objects) {
      if (object.step) {
        object.step();
      }
    }
  };

  public render = (ctx: CanvasRenderingContext2D) => {
    ctx.imageSmoothingEnabled = false;

    ctx.canvas.width = 240;
    ctx.canvas.height = 160;

    if (
      this.cameraObject?.x !== undefined &&
      this.cameraObject?.y !== undefined &&
      this.cameraObject?.width &&
      this.cameraObject?.height
    ) {
      viewX =
        this.cameraObject.x +
        this.cameraObject.width / 2 -
        Math.round(ctx.canvas.width / 2);

      viewY =
        this.cameraObject.y +
        this.cameraObject.height / 2 -
        Math.round(ctx.canvas.height / 2);
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Green background
    ctx.fillStyle = this.backgroundColor;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    this.backgroundShader?.(ctx, viewX, viewY);

    ctx.translate(-viewX, -viewY);

    // ctx.drawImage(bgMap, 0, 0, bgMap.width, bgMap.height);

    // Use object.depth to sort objects
    [player.current, ...this.objects]
      .map((object) => {
        const objects = object?.objects;

        return objects || object;
      })
      .flat()
      .sort((a, b) => {
        if (a?.depth === undefined) {
          return -1;
        }
        if (b?.depth === undefined) {
          return 1;
        }

        return (a.depth || 0) - (b.depth || 0);
      })
      .forEach((object) => object?.render?.(ctx));
  };
}
