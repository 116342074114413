import { drawSprite } from "../../helpers/engine/drawSprite";
import { sprItem } from "../../sprites/sprItem";
import { ActionObject } from "../objPlayer/helpers/handleAction";
import { readMessage } from "../objPlayer/helpers/readMessage";
import { collectItem, myItems } from "../objPlayer/objPlayer";
import { objWall } from "../objWall/objWall";

export interface BagItems {
  [index: string]:
    | {
        collectedIds: string[];
        item: keyof typeof allItems;
        quantity: number;
      }
    | undefined;
}

export const allItems = {
  potato: {
    name: "Potato",
  },
};

export class objItem extends objWall {
  public height: number;
  public width: number;
  public x: number;
  public y: number;
  public depth: number;

  public id: string;
  public item: keyof typeof allItems;

  constructor({
    id,
    item,
    x,
    y,
  }: {
    id: string;
    item: keyof typeof allItems;
    x: number;
    y: number;
  }) {
    const height = 16;
    const width = 16;

    super(x, y, width, height);

    this.item = item;
    this.id = id;

    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
    this.depth = this.y;
  }

  public action = async (objectActioning: ActionObject) => {
    collectItem(this);
    readMessage(
      objectActioning,
      () => `You found a ${allItems[this.item].name}!`
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  public render = (ctx: CanvasRenderingContext2D) => {
    if (
      Object.values(myItems).find((item) =>
        item?.collectedIds.includes(this.id)
      )
    ) {
      return;
    }

    drawSprite({
      ctx,
      sprite: sprItem,
      x: this.x,
      y: this.y,
    });
  };
}
