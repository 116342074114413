import { collisionsWhenPlaced } from "../../../helpers/engine/collisionsWhenPlaced";
import {
  isDownPressed,
  isLeftPressed,
  isRightPressed,
  isShiftPressed,
  isUpPressed,
} from "../../../helpers/engine/handleKeyboard";
import { BoundingBox } from "../../../models/BoundingBox";
import { objWall } from "../../objWall/objWall";
import { currentMenu } from "./menu/handleMenu";

export function handleMovement(
  this: {
    x: number;
    y: number;
    isRunning: boolean;
    isMoving: boolean;
    startedTurning: number;
    direction: number;
    speed: number;
    depth: number;
    nextAction: (() => void) | undefined;
  } & BoundingBox
) {
  if (this.nextAction || currentMenu) {
    return;
  }

  if (this.x % 2 === 0 && this.y % 2 === 0) {
    this.isRunning = isShiftPressed;
  }

  if (this.x % 16 === 0 && this.y % 16 === 0) {
    this.isMoving = false;
  }

  if (!this.isMoving) {
    let direction = 0;
    if (isLeftPressed) {
      direction = 180;
    } else if (isRightPressed) {
      direction = 0;
    } else if (isUpPressed) {
      direction = 270;
    } else if (isDownPressed) {
      direction = 90;
    }

    if (isLeftPressed || isRightPressed || isUpPressed || isDownPressed) {
      if (!this.startedTurning) {
        if (this.direction === direction) {
          this.startedTurning = -1;
        } else {
          this.startedTurning = Date.now();
        }
      }
      this.direction = direction;
      this.isMoving = true;
    } else {
      this.startedTurning = 0;
    }
  }

  if (
    this.startedTurning &&
    (this.isRunning || Date.now() - this.startedTurning > 100)
  ) {
    this.speed = this.isRunning ? 2 : 1;
  } else {
    this.speed = 0;
  }

  const nextX =
    this.x +
    this.speed * Math.round(Math.cos((this.direction * Math.PI) / 180));
  const nextY =
    this.y +
    this.speed * Math.round(Math.sin((this.direction * Math.PI) / 180));

  if (collisionsWhenPlaced(this, nextX, nextY, objWall).length) {
    this.speed = 0;
  }

  this.x += this.speed * Math.round(Math.cos((this.direction * Math.PI) / 180));
  this.y += this.speed * Math.round(Math.sin((this.direction * Math.PI) / 180));
  this.depth = this.y;
}
