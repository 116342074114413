import { currentMenu } from "./handleMenu";
import renderItemsMenu from "./itemsMenu/renderItemsMenu";
import renderMainMenu from "./mainMenu/renderMainMenu";

export default function renderMenu(ctx: CanvasRenderingContext2D) {
  if (currentMenu === "main") {
    renderMainMenu(ctx);
  }

  if (currentMenu === "items") {
    renderItemsMenu(ctx);
  }
}
