import { onValue, ref } from "firebase/database";

import { user } from "../../../../features/authentication/state/user";
import { database } from "../../../../helpers/initializeFirebase";
import { currentRoom, player, roomIdMap } from "../../Mon";
import { objPlayer } from "../objPlayer/objPlayer";
import { renderOtherPlayer } from "./helpers/renderOtherPlayer";
import { walkOtherPlayerToLocation } from "./helpers/walkOtherPlayerToLocation";

export type PlayerVector = {
  direction: number;
  room: string;
  time: number;
  x: number;
  y: number;
};

export class objOtherPlayers {
  public allPlayers: {
    [uid: string]: {
      currentVector: PlayerVector & { isRunning: boolean };
      nextVector: PlayerVector;
    };
  } = {};

  get objects() {
    return Object.entries(this.allPlayers)
      .filter(([uid]) => uid !== user()?.uid)
      .map(([, player]) => ({
        depth: player.currentVector.y - 2,
        height: objPlayer.height,
        render: (ctx: CanvasRenderingContext2D) =>
          renderOtherPlayer(ctx, player.currentVector),
        width: objPlayer.width,
        x: player.currentVector.x,
        y: player.currentVector.y,
      }));
  }

  constructor() {
    onValue(
      ref(database, `mon/v1/players`), // TODO (1 - refactor): Make this cheaper - getting all players every time one player changes is very expensive in Firebase RTDB
      (snapshot) => {
        console.log("database read");
        if (snapshot.exists()) {
          const allPlayersData: { [uid: string]: PlayerVector } =
            snapshot.val() ?? {};

          const uid = user()?.uid;

          if (!player.current && uid) {
            const defaultPlayerData = {
              direction: 90,
              room: "rmOverworld",
              // Kanto
              // x: 1056,
              // y: 4272,
              // Weyard
              // x: 5408,
              // y: 5008,
              x: 0,
              y: 0,
            };

            const playerData = {
              ...defaultPlayerData,
              ...allPlayersData[uid],
            };

            const { room, x, y, direction } = playerData;

            player.current = new objPlayer({
              direction,
              room,
              x,
              y,
            });

            currentRoom.current = roomIdMap[room];
            currentRoom.current.cameraObject = player.current;
          }

          this.allPlayers = Object.entries(allPlayersData).reduce(
            (acc, data) => {
              const [uid, playerVector] = data;
              acc[uid] = {
                currentVector: this.allPlayers[uid]?.currentVector ?? {
                  ...playerVector,
                  isRunning: false,
                },
                nextVector: playerVector,
              };

              return acc;
            },
            {} as {
              [uid: string]: {
                currentVector: PlayerVector & { isRunning: boolean };
                nextVector: PlayerVector;
              };
            }
          );
        }
      }
    );
  }

  public step = () => {
    Object.entries(this.allPlayers).forEach(
      walkOtherPlayerToLocation(this.allPlayers)
    );
  };
}
