import { getAuth, sendEmailVerification, signOut } from "firebase/auth";

import { setIsRequestingEmailForMagicLink } from "@/features/authentication/state/isRequestingEmailForMagicLink";
import { setIsRequestingReAuth } from "@/features/authentication/state/isRequestingReAuth";

import tryFirebase from "../helpers/tryFirebase";
import { showError } from "./showError";

export async function handleError(
  error: {
    code: string;
    message: string;
  },
  emailAlreadyInUseCallback?: () => Promise<void>
) {
  switch (error.code) {
    // Sign in with expired credentials
    case "auth/code-expired":
    case "auth/expired-action-code":
    case "auth/invalid-action-code":
    case "auth/invalid-user-token":
    case "auth/invalid-auth-event":
    case "auth/user-token-expired":
    case "auth/user-signed-out":
    case "auth/too-many-requests":
      await tryFirebase(
        async () => {
          setIsRequestingEmailForMagicLink(false);
          history.replaceState(null, "", location.pathname);
          const auth = getAuth();
          await signOut(auth);
        },
        async (error) => {
          await showError(error);
        }
      );
      break;

    // Sign up with account that already exists
    case "auth/credential-already-in-use":
    case "auth/email-already-in-use":
      await emailAlreadyInUseCallback?.();
      break;

    // Perform action that requires verification
    case "auth/unverified-email":
    case "auth/email-change-needs-verification":
      await tryFirebase(
        async () => {
          const auth = getAuth();
          if (!auth.currentUser) {
            throw new Error("No user signed in");
          }
          await sendEmailVerification(auth.currentUser);
        },
        async (error) => {
          await showError(error);
        }
      );
      break;

    // Perform action that requires re-authentication
    case "auth/requires-recent-login":
      setIsRequestingReAuth(true);
      break;
  }
}
