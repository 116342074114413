import "./ColorPicker.scss";

import cn from "clsx";
import Solid, { createSignal, For } from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { ToolColor } from "@/features/canvas/state/colorSwatch";

interface Props extends Solid.ComponentProps<typeof Dropdown> {
  initialColor: ToolColor;
  isSelected?: boolean;
  onClick?: (event: MouseEvent) => void;
  onSetColor?: (color: ToolColor) => void;
}

export const ColorPicker: Solid.Component<Props> = ({
  onSetColor,
  initialColor,
  isSelected,
  onClick,
  ...attributes
}) => {
  const [color, setColorSignal] = createSignal<ToolColor>(initialColor);

  function setColor(color: ToolColor) {
    setColorSignal(color);
    onSetColor?.(color);
  }

  function meetsContrast(color: ToolColor) {
    if (
      document.documentElement.classList.contains("dark") &&
      color === "black"
    ) {
      return false;
    }

    if (
      !document.documentElement.classList.contains("dark") &&
      color === "white"
    ) {
      return false;
    }

    return true;
  }

  return (
    <Dropdown alignment={() => "center"} {...attributes}>
      <DropdownButton
        class="text-gray-500 dark:text-gray-300"
        hasCaret={false}
        isSelected={isSelected}
        onClick={(event) => {
          if (!isSelected) {
            event.preventDefault();
            setColor(color());
          }
          onClick?.(event);
        }}
        selectionIndicator="circle"
        style={{
          "--color-item": meetsContrast(color())
            ? `var(--color-${color()}-500)`
            : "currentColor",
        }}
      >
        <svg
          aria-label={color()}
          class={cn(
            "h-6 w-6 isolate",
            meetsContrast(color())
              ? "stroke-none"
              : "stroke-gray-500 dark:stroke-gray-300"
          )}
          fill={`var(--color-${color()}-500)`}
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r={meetsContrast(color()) ? "10" : "9"} />
        </svg>
      </DropdownButton>
      <DropdownMenu class="flex flex-wrap">
        <For
          each={
            [
              "black",
              "white",
              "red",
              "orange",
              "amber",
              "yellow",
              "lime",
              "green",
              "emerald",
              "teal",
              "cyan",
              "sky",
              "blue",
              "indigo",
              "violet",
              "purple",
              "fuchsia",
              "pink",
              "rose",
            ] as ToolColor[]
          }
        >
          {(c) => (
            <MenuItem
              class="text-gray-500 dark:text-gray-300"
              isSelected={c === color()}
              onSelect={() => {
                setColor(c);
              }}
              selectionIndicator="circle"
              style={{
                "--color-item": meetsContrast(c)
                  ? `var(--color-${c}-500)`
                  : "currentColor",
              }}
            >
              <svg
                aria-label={c.charAt(0).toUpperCase() + c.slice(1)}
                class={cn(
                  "h-6 w-6 isolate",
                  meetsContrast(c)
                    ? "stroke-none"
                    : "stroke-gray-500 dark:stroke-gray-300"
                )}
                fill={`var(--color-${c}-500)`}
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r={meetsContrast(color()) ? "10" : "9"}
                />
              </svg>
            </MenuItem>
          )}
        </For>
      </DropdownMenu>
    </Dropdown>
  );
};
