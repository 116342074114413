import { collisionsWhenPlaced } from "../../helpers/engine/collisionsWhenPlaced";
import { drawSprite } from "../../helpers/engine/drawSprite";
import { sprGrass, sprGrassForeground } from "../../sprites/sprGrass";
import { objPlayer } from "../objPlayer/objPlayer";

export class objGrass {
  get objects() {
    return [
      {
        depth: this.depth - 16,
        render: this.renderBackground,
      },
      {
        depth: this.depth,
        render: this.renderForeground,
      },
    ];
  }

  public height: number;
  public width: number;
  public x: number;
  public y: number;
  public depth: number;

  private squashTime?: number;
  private tilesToDraw: boolean[][] | undefined;

  constructor({ x, y }: { x: number; y: number }) {
    const height = 2;
    const width = 2;

    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
    this.depth = this.y;
  }

  public step = () => {
    if (collisionsWhenPlaced(this, this.x + 7, this.y + 7, objPlayer).length) {
      if (!this.squashTime) {
        this.squashTime = Date.now();
      }
    } else {
      if (
        this.squashTime &&
        (Date.now() - this.squashTime) / 100 > sprGrassForeground.columns - 1
      ) {
        this.squashTime = undefined;
      }
    }
  };

  public renderBackground = (ctx: CanvasRenderingContext2D) => {
    // Center piece
    // for (let ix = 0; ix < 2; ix++) {
    //   for (let iy = 0; iy < 2; iy++) {
    //     drawSprite({
    //       columnIndex: ix + 1,
    //       ctx,
    //       rowIndex: iy + 1,
    //       sprite: sprGrass,
    //       x: this.x + ix * 8,
    //       y: this.y + iy * 8,
    //     });
    //   }
    // }
    // if (!this.tilesToDraw) {
    //   this.tilesToDraw = new Array(4)
    //     .fill(0)
    //     .map((_, ix) =>
    //       new Array(4)
    //         .fill(0)
    //         .map(
    //           (_, iy) =>
    //             !collisionsWhenPlaced(
    //               this,
    //               this.x - 16 + Math.floor(ix / 2) * 2 * 16,
    //               this.y - 16 + Math.floor(iy / 2) * 2 * 16,
    //               objGrass
    //             ).length
    //         )
    //     );
    // }
    // this.tilesToDraw.forEach((row, ix) => {
    //   row.forEach((tile, iy) => {
    //     if (tile) {
    //       drawSprite({
    //         columnIndex: ix,
    //         ctx,
    //         rowIndex: iy,
    //         sprite: sprGrass,
    //         x: this.x + ix * 8 - 8,
    //         y: this.y + iy * 8 - 8,
    //       });
    //     }
    //   });
    // });

    drawSprite({
      columnIndex: this.squashTime ? 1 : 0,
      ctx,
      rowIndex: 0,
      sprite: sprGrass,
      x: this.x,
      y: this.y,
    });
  };

  public renderForeground = (ctx: CanvasRenderingContext2D) => {
    if (this.squashTime) {
      drawSprite({
        columnIndex: Math.min(
          Math.floor((Date.now() - this.squashTime) / 100),
          sprGrassForeground.columns - 1
        ),
        ctx,
        rowIndex: 0,
        sprite: sprGrassForeground,
        x: this.x,
        y: this.y,
      });
    }
  };
}
