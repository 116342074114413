import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { SignInIcon } from "@/icons/SignInIcon";

interface Props extends Solid.ComponentProps<typeof Button> {
  signInMethod?: string;
}

export const SignInButton: Solid.Component<Props> = ({
  isDisabled,
  signInMethod,
  ...spreadProps
}) => (
  <Button isDisabled={isDisabled} {...spreadProps}>
    {isDisabled && signInMethod === "password" ? (
      <LoadingSpinner class="h-6 w-6" />
    ) : (
      <SignInIcon class="h-6 w-6" />
    )}
    Sign in
  </Button>
);
