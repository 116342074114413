import { createSignal } from "solid-js";

import { toolColor } from "../selectors/toolColor";
import { toolShape } from "../selectors/toolShape";
import { canvasView } from "../state/canvasView";
import {
  currentShape,
  setCurrentShape,
  setShapes,
  shapes,
} from "../state/shapes";

export default function useShapeTool() {
  const [initialShapeToolPosition, setInitialShapeToolPosition] = createSignal<
    { x: number; y: number } | undefined
  >(undefined);

  function shapeTool(
    x: number,
    y: number,
    fromCenter?: boolean,
    aspectRatio?: number
  ) {
    if (!initialShapeToolPosition()) {
      setInitialShapeToolPosition({ x, y });
    }

    const startX = initialShapeToolPosition()?.x || x;
    const startY = initialShapeToolPosition()?.y || y;

    let width = Math.abs(x - startX);
    let height = Math.abs(y - startY);

    if (aspectRatio !== undefined) {
      if (width > height) {
        height = width / aspectRatio;
      } else {
        width = height * aspectRatio;
      }
    }

    let left = x < startX ? startX - width : startX;
    let top = y < startY ? startY - height : startY;

    if (fromCenter) {
      left = startX - width;
      top = startY - height;
      width = width * 2;
      height = height * 2;
    }

    setCurrentShape({
      color: toolColor(),
      shape: toolShape(),
      x1: left,
      x2: left + width,
      y1: top,
      y2: top + height,
    });
  }

  function shapeToolCommit() {
    const shape = currentShape();

    if (shape) {
      setShapes([...shapes(), shape]);
    }

    setInitialShapeToolPosition(undefined);
    setCurrentShape();
  }

  function shapeToolCancel() {
    setInitialShapeToolPosition(undefined);
    setCurrentShape();
  }

  function shapeToolHandler({
    altKey,
    clientX,
    clientY,
    currentTarget,
    shiftKey,
  }: PointerEvent) {
    const x =
      (clientX - (currentTarget as HTMLElement).getBoundingClientRect().left) /
      canvasView().zoom;
    const y =
      (clientY - (currentTarget as HTMLElement).getBoundingClientRect().top) /
      canvasView().zoom;

    shapeTool(
      x - canvasView().x,
      y - canvasView().y,
      altKey,
      shiftKey ? 1 : undefined
    );
  }

  return {
    onPointerCancel: shapeToolCancel,
    onPointerDown: shapeToolHandler,
    onPointerLeave: shapeToolCommit,
    onPointerMove: shapeToolHandler,
    onPointerUp: shapeToolCommit,
  };
}
