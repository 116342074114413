import { useLocation, useNavigate } from "@solidjs/router";

import { isTablet } from "@/features/responsive/state/isTablet";

import { setHasAddedHomePageToBackStack } from "../state/hasAddedHomePageToBackStack";

export async function addHomePageToBackStack() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParameters = location.search;
  const currentLocation = location.pathname;
  const hash = location.hash;
  if (currentLocation !== "/") {
    navigate("/", { replace: true });

    const paths = currentLocation.split("/");

    for (let i = 1; i < paths.length; i++) {
      await new Promise((resolve) => setTimeout(resolve));
      navigate(paths.slice(0, i + 1).join("/") + queryParameters);
    }

    await new Promise((resolve) => setTimeout(resolve));
    navigate(currentLocation + queryParameters + hash, {
      replace: isTablet() ? undefined : true,
    });
  }
  setHasAddedHomePageToBackStack(true);
}
