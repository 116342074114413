import "./DinnerTimer.scss";

import Solid, { createEffect, createSignal, For, onCleanup } from "solid-js";

import { Timer, timeRef, TimerInterface } from "./components/Timer/Timer";
import sum from "./helpers/sum";

const audioLocked = true;
const timerSound = new Audio("/timer/timer.mp3");

export const [timers, setTimers] = createSignal<TimerInterface[]>([
  {
    isRepeat: true,
    isRunning: false,
    name: "🥩 Steak",
    time: [60000],
    timeRemaining: 60000,
  },
  {
    isRepeat: false,
    isRunning: false,
    name: "🍚 Rice",
    time: [600000, 300000],
    timeRemaining: 900000,
  },
]);

export const DinnerTimer: Solid.Component = () => {
  const [timerNotificationSignal, setTimerNotificationSignal] =
    createSignal(false);

  function updateTimerNotifications() {
    setTimerNotificationSignal(!timerNotificationSignal());
  }

  createEffect(() => {
    timerNotificationSignal();

    const timeoutIds = timers().map((timer) => {
      const timeRemaining = timer.isRunning
        ? timer.isRepeat
          ? sum(...timer.time) -
            ((timeRef - timer.startTime) % sum(...timer.time))
          : timer.startTime + sum(...timer.time) - timeRef
        : undefined;

      if (timeRemaining === undefined) {
        return undefined;
      }

      const timeoutId = setTimeout(() => {
        timerSound.play();
        updateTimerNotifications();
      }, timeRemaining);

      return timeoutId;
    });

    onCleanup(() => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    });
  });

  return (
    <div
      class="DinnerTimer grid grid-cols-[auto_1fr_auto] p-8 gap-8"
      onTouchStart={() => {
        if (!audioLocked) {
          return;
        }

        timerSound.muted = true;
        timerSound.play();
        timerSound.onended = () => {
          timerSound.muted = false;
        };
      }}
    >
      <audio id="timer" src="/timer/timer.mp3" />
      <For each={timers()}>
        {(timer, timerIndex) => (
          <Timer timer={timer} timerIndex={timerIndex()} />
        )}
      </For>
    </div>
  );
};
