import { Accessor, createSignal, Setter } from "solid-js";

export const [connectedSignals, setConnectedSignals] = createSignal<
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValue: any;
    isConnected: Accessor<boolean>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signalSetter: Setter<any>;
  }[]
>([]);
