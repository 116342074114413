import "./DropdownMenu.scss";

import { cva } from "class-variance-authority";
import cn from "clsx";
import { nanoid } from "nanoid";
import Solid, { createEffect, useContext } from "solid-js";
import { Portal } from "solid-js/web";

import useMenuRef from "@/components/Menu/hooks/useMenuRef";
import { Menu } from "@/components/Menu/Menu";

import { DropdownContext } from "./Dropdown";

const dropdownMenuClasses = cva("dropdownMenu", {
  defaultVariants: {},
  variants: {
    isOpen: {
      true: "dropdownMenu-isOpen",
    },
    side: {
      bottom: "dropdownMenu-sideBottom",
      end: "dropdownMenu-sideEnd",
      inside: "",
      start: "dropdownMenu-sideStart",
      top: "dropdownMenu-sideTop",
    },
  },
});

interface Props
  extends Omit<
    Solid.ComponentProps<typeof Menu>,
    "isMultiSelect" | "selectionFollowsFocus"
  > {
  isOpen?: boolean;
  style?: Solid.JSX.CSSProperties;
}

export const DropdownMenu: Solid.Component<Props> = ({
  children,
  ...attributes
}) => {
  const menuId = nanoid();

  const { menuProps } = useContext(DropdownContext);

  const { menuRef, resetActiveItemIndex } = useMenuRef();

  let wasOpen = menuProps?.().isOpen();

  createEffect(() => {
    if (menuProps?.().isOpen() !== wasOpen) {
      resetActiveItemIndex();
      wasOpen = menuProps?.().isOpen();
    }
  });

  const onClickHandler: Props["onClick"] = (...args) => {
    if (typeof attributes.onClick === "function") {
      attributes.onClick(...args);
    }

    menuProps?.().onClick(...args);
  };

  const onKeyDownHandler: Props["onKeyDown"] = (...args) => {
    if (typeof attributes.onKeyDown === "function") {
      attributes.onKeyDown(...args);
    }

    menuProps?.().onKeyDown(...args);
  };

  return (
    <div aria-owns={attributes.id ?? menuId}>
      <Portal>
        <div
          class={dropdownMenuClasses({
            isOpen: menuProps?.().isOpen(),
            side: menuProps?.().side(),
          })}
          ref={(element) => menuProps?.().menuBorderElementRef(element)}
          style={{
            ...menuProps?.().positioningStyles,
            ...menuProps?.().borderStyles,
          }}
        >
          <Menu
            {...attributes}
            class={cn("w-full", attributes.class)}
            id={attributes.id ?? menuId}
            isActive={menuProps?.().isOpen()}
            isFocusable={false}
            isMultiSelect={menuProps?.().isMultiSelect}
            onClick={onClickHandler}
            onKeyDown={onKeyDownHandler}
            ref={({ element, resetActiveItemIndex }) => {
              if (typeof attributes.ref === "function") {
                attributes.ref({ element, resetActiveItemIndex });
              }
              menuRef({ element, resetActiveItemIndex });
              menuProps?.().ref(element);
            }}
            selectionFollowsFocus={menuProps?.().selectionFollowsFocus}
          >
            {children}
          </Menu>
        </div>
      </Portal>
    </div>
  );
};
