import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const continueAsGuestIconClasses = cva("continueAsGuestIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof continueAsGuestIconClasses> {}

export const ContinueAsGuestIcon: Solid.Component<Props> = ({
  ...attributes
}) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
