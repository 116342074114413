import { createEffect } from "solid-js";

import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { setToasts, toasts } from "@/features/toast/state/toasts";
import { UpgradeIcon } from "@/icons/UpgradeIcon";

import { isUpdating, setIsUpdating } from "../state/isUpdating";
import { useUpdate } from "./useUpdate";

export function useUpdateToast() {
  const { updateReady, update } = useUpdate();

  createEffect(() => {
    if (
      (updateReady() || isUpdating()) &&
      !toasts().find((toast) => toast.id === "update-available")
    ) {
      setToasts([
        ...toasts(),
        {
          actions: [
            {
              callback: async () => {
                setIsUpdating(true);
                update();
              },
              label: () => (
                <>
                  {isUpdating() ? (
                    <LoadingSpinner class="h-6 w-6" />
                  ) : (
                    <UpgradeIcon class="h-6 w-6 stroke-yellow-500 fill-yellow-500" />
                  )}
                  Update Scribble Desk
                </>
              ),
            },
          ],
          id: "update-available",
          message: "",
        },
      ]);
    }
  });
}
