import { useNavigate } from "@solidjs/router";
import cn from "clsx";
import Solid, { createSignal } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import withTransition from "@/helpers/withTransition";
import { BackIcon } from "@/icons/BackIcon";

import { signInWithMagicLink } from "../../actions/signInWithMagicLink";
import { setIsRequestingEmailForMagicLink } from "../../state/isRequestingEmailForMagicLink";
import { isSigningIn, setIsSigningIn } from "../../state/isSigningIn";
import { isSigningOut } from "../../state/isSigningOut";
import { SignInButton } from "../ui/SignInButton";

export type SignInMethod = "guest" | "email" | "password" | "new" | undefined;

interface Props {}

export const MagicLinkForm: Solid.Component<Props> = () => {
  const [email, setEmail] = createSignal("");

  const navigate = useNavigate();

  async function submitForm() {
    if (!email()) {
      return;
    }

    setIsSigningIn(true);

    function cleanUp() {
      setIsSigningIn(false);
    }

    await tryFirebase(
      // TODO: Re-enable this linting rule
      // eslint-disable-next-line solid/reactivity
      async () => {
        await signInWithMagicLink(email(), cleanUp);
      },
      async (error) => {
        await handleError(error);
        await showError(error);
        cleanUp();
      }
    );
  }

  return (
    <div class={cn("flex flex-col gap-5")}>
      <div style={{ "text-wrap": "pretty" }}>
        Confirm your email address to sign in.
      </div>
      <div>
        <Input
          intent="field"
          isDisabled={isSigningIn() || isSigningOut()}
          label="Email"
          onInput={(event) =>
            setEmail((event.target as HTMLInputElement).value)
          }
          onKeyPress={(event) => {
            if (event.key !== "Enter") {
              return;
            }

            submitForm();
          }}
          type="email"
        />
      </div>
      <div
        class={cn(
          "flex auto-cols-auto flex-col justify-end gap-5",
          "signInActions:auto-rows-auto signInActions:flex-row"
        )}
      >
        <div>
          <Button
            onClick={() => {
              // Animation - Close magic link form
              withTransition(() => {
                navigate(location.pathname, { replace: true });
                setIsRequestingEmailForMagicLink(false);
              });
            }}
          >
            <BackIcon class="h-6 w-6" />
            Back
          </Button>
        </div>
        <div class={cn("hidden", "signInActions:block signInActions:flex-1")} />
        <div>
          <SignInButton
            class="w-full signInActions:w-auto"
            intent="primary"
            isDisabled={!email() || isSigningIn() || isSigningOut()}
            onClick={submitForm}
            signInMethod={email() && "password"}
          />
        </div>
      </div>
    </div>
  );
};
