import "./Menu.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid, { createContext } from "solid-js";

import useMenuItems from "./hooks/useMenuItems";
import { MenuRef } from "./hooks/useMenuRef";

export const MenuContext = createContext<{
  activeItem?: () => string;
  addMenuItem?: (id: string) => void;
  menuItems?: Solid.Accessor<string[]>;
  removeMenuItem?: (id: string) => void;
  setActiveItemIndex?: (index: number) => void;
}>({});

const menuClasses = cva("menu", {
  defaultVariants: {},
  variants: {
    isActive: {
      true: "menu--active",
    },
  },
});

interface Props
  extends Omit<Solid.JSX.HTMLAttributes<HTMLDivElement>, "ref">,
    VariantProps<typeof menuClasses> {
  isFocusable?: boolean;
  isMultiSelect?: boolean;
  // useImperativeHandle
  ref?: (ref: MenuRef) => void;
  selectionFollowsFocus?: boolean;
}

export const Menu: Solid.Component<Props> = ({
  children,
  class: className,
  isActive,
  isFocusable = true,
  isMultiSelect,
  ref = () => {},
  selectionFollowsFocus,
  ...attributes
}) => {
  const {
    activeItem,
    addMenuItem,
    menuItems,
    onKeyDown,
    removeMenuItem,
    resetActiveItemIndex,
    setActiveItemIndex,
  } = useMenuItems(isMultiSelect, selectionFollowsFocus);

  function onKeyDownHandler(
    event: KeyboardEvent & { currentTarget: HTMLDivElement; target: Element }
  ) {
    onKeyDown(event);

    if (typeof attributes.onKeyDown === "function") {
      attributes.onKeyDown(event);
    }
  }

  return (
    <MenuContext.Provider
      value={{
        activeItem,
        addMenuItem,
        menuItems,
        removeMenuItem,
        setActiveItemIndex,
      }}
    >
      <div
        {...attributes}
        aria-activedescendant={activeItem()}
        class={cn(menuClasses({ isActive }), "p-1", className)}
        onKeyDown={onKeyDownHandler}
        ref={(element) => {
          ref({ element, resetActiveItemIndex });
        }}
        tabIndex={isFocusable ? 0 : -1}
      >
        {children}
      </div>
    </MenuContext.Provider>
  );
};
