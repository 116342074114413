export default function doesElementContainElement(
  containerElement?: HTMLElement,
  elementToFind?: HTMLElement
): boolean {
  if (!containerElement || !elementToFind) {
    return false;
  }

  if (containerElement.contains(elementToFind)) {
    return true;
  }

  const parentElements = containerElement.querySelectorAll(
    "[aria-owns]"
  ) as NodeListOf<HTMLElement>;

  return Array.from(parentElements).some((parentElement) => {
    const childId = parentElement.getAttribute("aria-owns");
    if (!childId) {
      return false;
    }

    const childElement = document.getElementById(childId) ?? undefined;

    return doesElementContainElement(childElement, elementToFind);
  });
}
