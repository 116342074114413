import { Room } from "../helpers/engine/room";

export const rmInside: Room = {
  backgroundColor: "#AAA",
  map: [
    {
      properties: {
        direction: 90,
        message: () => "I am in your room!",
        voice: ["C3", "D3", "E3", "F3", "G3", "A3", "B3", "C4"],
        x: 0,
        y: 0 + 16 * 2,
      },
      type: "npc",
    },
    {
      properties: {
        direction: 270,
        doorNumber: 0,
        roomId: "rmOverworld",
        x: 0 + 16,
        y: 0 + 16 * 7,
      },
      type: "door",
    },
  ],
};
