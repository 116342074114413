// TODO (3 - Login): https://firebase.google.com/docs/auth/web/redirect-best-practices

// TODO (4 - refactor): Refactor all layout, padding, margin, and visibility to be in JSX

import cn from "clsx";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Solid, { createEffect, createSignal } from "solid-js";

import { Banner } from "@/components/Banner/Banner";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { PageComponent } from "@/components/PageComponent/PageComponent";
import { Toasts } from "@/components/Toasts/Toasts";
import { signInWithMagicLinkOnPageLoad } from "@/features/authentication/actions/signUserInWithEmailOnPageLoad";
import { MagicLinkForm } from "@/features/authentication/components/forms/MagicLinkForm";
import { ReAuthForm } from "@/features/authentication/components/forms/ReAuthForm";
import {
  setEmail,
  setPassword,
} from "@/features/authentication/components/forms/SignInForm";
import {
  isAuthChecked,
  setIsAuthChecked,
} from "@/features/authentication/state/isAuthChecked";
import {
  isAuthReady,
  setIsAuthReady,
} from "@/features/authentication/state/isAuthReady";
import {
  isEmailLinkChecked,
  setIsEmailLinkChecked,
} from "@/features/authentication/state/isEmailLinkChecked";
import { isRequestingEmailForMagicLink } from "@/features/authentication/state/isRequestingEmailForMagicLink";
import { isRequestingReAuth } from "@/features/authentication/state/isRequestingReAuth";
import {
  isSignedIn,
  setIsSignedIn,
} from "@/features/authentication/state/isSignedIn";
import { isSigningIn } from "@/features/authentication/state/isSigningIn";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { setUser, user } from "@/features/authentication/state/user";
import { banner, setBanner } from "@/features/banner/state/banner";
import { useDarkMode } from "@/features/darkMode/hooks/useDarkMode";
import { resetState } from "@/features/database/actions/resetState";
import { isDatabaseConnected } from "@/features/database/selectors/isDatabaseConnected";
import { setIsBetaUser } from "@/features/featureFlags/state/betaUser";
import { Header } from "@/features/header/components/Header/Header";
import { addHomePageToBackStack } from "@/features/router/actions/addHomePageToBackStack";
import { hasAddedHomePageToBackStack } from "@/features/router/state/hasAddedHomePageToBackStack";
import { toasts } from "@/features/toast/state/toasts";
import { useUpdateToast } from "@/features/updates/hooks/useUpdateToast";
import { calculatedThemeColor } from "@/features/userSettings/state/themeColor";
import withTransition from "@/helpers/withTransition";
import { SignIn } from "@/pages/SignIn/SignIn";

export const [isInstalled, setIsInstalled] = createSignal(
  window.matchMedia("(display-mode: standalone)").matches
);

window
  .matchMedia("(display-mode: standalone)")
  .addEventListener("change", ({ matches }) => {
    if (matches) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }
  });

interface Props {
  children?: Solid.JSXElement;
}

export const App: Solid.Component<Props> = ({ children }) => {
  useUpdateToast();

  addHomePageToBackStack();

  createEffect(() => {
    if (
      !isAuthChecked() ||
      !isEmailLinkChecked() ||
      !hasAddedHomePageToBackStack() ||
      isSigningIn()
    ) {
      return;
    }

    if (!user() && isSignedIn()) {
      setEmail("");
      setPassword("");

      setIsSignedIn(false);
      return;
    }

    if (user() && !isSignedIn()) {
      setEmail("");
      setPassword("");
      setBanner(undefined);

      setIsSignedIn(true);
      return;
    }
  });

  useDarkMode();

  onAuthStateChanged(getAuth(), async (newUser) => {
    if (isAuthChecked()) {
      setUser(newUser ? { ...newUser } : undefined);
    } else {
      setUser(newUser ? { ...newUser } : undefined);
      setIsAuthChecked(true);

      if (!isEmailLinkChecked()) {
        await signInWithMagicLinkOnPageLoad();
        setIsEmailLinkChecked(true);
      }
    }
  });

  let previousUid: string | undefined;

  createEffect(() => {
    if (user()?.uid === previousUid) {
      return;
    }

    previousUid = user()?.uid;

    if (!user()?.uid) {
      resetState();
    } else {
      if (user()?.uid === "Hv9m9r6H3ghEc1an11Alf9Mhww82") {
        setIsBetaUser(true);
      }
    }
  });

  createEffect(() => {
    const isAuthReadyNow =
      isAuthChecked() &&
      isEmailLinkChecked() &&
      (!isSignedIn() || isDatabaseConnected()) &&
      !isSigningOut();

    if (isAuthReady() !== isAuthReadyNow) {
      // Animation - Page load
      withTransition(() => {
        setIsAuthReady(isAuthReadyNow);
      });
    }
  });

  const isLoading = () =>
    !isAuthChecked() ||
    !isEmailLinkChecked() ||
    (isSignedIn() && !isDatabaseConnected());

  return (
    <>
      <style>
        {`:root {
            --color-brand-400: var(--color-${calculatedThemeColor()}-400);
            --color-brand-500: var(--color-${calculatedThemeColor()}-500);
            --color-brand-600: var(--color-${calculatedThemeColor()}-600);
          }`}
      </style>
      <div
        class={cn(
          (isAuthReady() ||
            // @ts-expect-error - This property is valid but only in Chrome
            (document.startViewTransition && !isLoading())) &&
            "hidden",
          "static flex h-full items-center justify-center"
        )}
      >
        <LoadingSpinner class="h-20 w-20 text-[var(--color-brand-500)]" />
      </div>
      <div
        class={cn(!isAuthReady() && "hidden", "grid h-full grid-flow-col")}
        style={{
          "grid-template-areas": `
          "header"
          "body"
          `,
          "grid-template-rows": "auto 1fr",
        }}
      >
        {isSignedIn() ? (
          <>
            <Header style={{ "grid-area": "header" }} />
            {children}
            {isRequestingReAuth() && (
              <dialog class="h-full w-full bg-black bg-opacity-50" open>
                <div class="absolute left-0 top-0 flex h-full w-full flex-col flex-wrap content-center">
                  <div class="max-h-[20%] flex-1" />
                  <PageComponent>
                    <ReAuthForm />
                  </PageComponent>
                  <div class="max-h-[30%] flex-1" />
                </div>
              </dialog>
            )}
          </>
        ) : (
          <SignIn />
        )}
      </div>
      {isRequestingEmailForMagicLink() && (
        <dialog
          class="absolute left-0 top-0 h-full w-full bg-black bg-opacity-50"
          open
        >
          <div class="absolute left-0 top-0 flex h-full w-full flex-col flex-wrap content-center">
            <div class="max-h-[20%] flex-1" />
            <PageComponent>
              <MagicLinkForm />
            </PageComponent>
            <div class="max-h-[30%] flex-1" />
          </div>
        </dialog>
      )}
      {banner() && (
        <Banner
          dismiss={() => setBanner(undefined)}
          intent={banner()?.intent}
          message={banner()?.message ?? "Loading..."}
        />
      )}
      {isAuthReady() && <Toasts toasts={toasts()} />}
    </>
  );
};
