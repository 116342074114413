/* @refresh reload */
import "./index.scss";
import "./helpers/initializeSentry";
import "./helpers/initializeFirebase";

import { Route, Router } from "@solidjs/router";
import { render } from "solid-js/web";

import { Canvas } from "@/pages/Canvas/Canvas";
import { DeskSettings } from "@/pages/DeskSettings/DeskSettings";
import { Mon } from "@/pages/Mon/Mon";
import { DinnerTimer } from "@/pages/Timer/DinnerTimer";
import { UserSettings } from "@/pages/UserSettings/UserSettings";

import { App } from "./App";

render(
  () => (
    <Router root={App}>
      <Route component={() => <Canvas />} path="/" />
      <Route component={DeskSettings} path="/desk-settings" />
      <Route component={UserSettings} path="/user-settings/*" />
      <Route component={Mon} path="/mon" />
      <Route component={DinnerTimer} path="/timer" />
    </Router>
  ),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById("root")!
);
