import { getAuth, signInAnonymously } from "firebase/auth";

import { isDatabaseConnected } from "@/features/database/selectors/isDatabaseConnected";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { until } from "@/helpers/until";
import withTransition from "@/helpers/withTransition";

import { setIsSigningIn } from "../state/isSigningIn";
import { setSignInMethod } from "../state/signInMethod";
import { user } from "../state/user";

export async function signAnonymousUserIn() {
  setIsSigningIn(true);
  setSignInMethod("guest");

  function cleanUp() {
    setIsSigningIn(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await signInAnonymously(auth);
      await new Promise<void>((resolve) =>
        until(() => user() !== undefined, resolve)
      );

      await new Promise<void>((resolve) =>
        until(() => isDatabaseConnected(), resolve)
      );

      // Animation - Sign in anonymously
      await withTransition(() => {
        cleanUp();
      });
    },
    async (error) => {
      await handleError(error);
      await showError(error);

      cleanUp();
    }
  );
}
