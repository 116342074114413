import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingHideToolNames } from "@/features/saving/state/isSaving";

export const defaultHideToolNames = false;

export const {
  isConnected: isHideToolNamesSynced,
  getSignal: hideToolNames,
  setSignal: setHideToolNamesSignal,
  setSignalConnected: setHideToolNames,
} = createConnectedSignal<boolean>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/hideToolNames`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultHideToolNames,
  onEnd: () => setIsSavingHideToolNames(false),
  onStart: () => setIsSavingHideToolNames(true),
});
