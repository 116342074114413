export const getRow = (direction: number) => {
  switch (direction) {
    case 90:
      return 0;
    case 270:
      return 1;
    case 180:
      return 2;
    case 0:
      return 3;
    default:
      return 0;
  }
};
