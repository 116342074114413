export let isLeftPressed = false;
export let isRightPressed = false;
export let isUpPressed = false;
export let isDownPressed = false;
export let isShiftPressed = false;
export let isActionPressed = false;
export let isCancelPressed = false;
export let isMenuPressed = false;

let wasLeftPressed = false;
let wasRightPressed = false;
let wasUpPressed = false;
let wasDownPressed = false;
let wasActionPressed = false;
let wasCancelPressed = false;
let wasMenuPressed = false;

export let onLeftPressed = false;
export let onRightPressed = false;
export let onUpPressed = false;
export let onDownPressed = false;
export let onActionPressed = false;
export let onCancelPressed = false;
export let onMenuPressed = false;

document.addEventListener("keydown", (event) => {
  switch (event.key) {
    case "ArrowLeft":
      isLeftPressed = true;
      break;

    case "ArrowRight":
      isRightPressed = true;
      break;

    case "ArrowUp":
      isUpPressed = true;
      break;

    case "ArrowDown":
      isDownPressed = true;
      break;

    case " ":
      isActionPressed = true;
      break;

    case "Enter":
      isMenuPressed = true;
      break;

    case "Escape":
      isCancelPressed = true;
      break;
  }

  if (event.shiftKey) {
    isShiftPressed = true;
  } else {
    isShiftPressed = false;
  }
});

document.addEventListener("keyup", (event) => {
  switch (event.key) {
    case "ArrowLeft":
      isLeftPressed = false;
      break;

    case "ArrowRight":
      isRightPressed = false;
      break;

    case "ArrowUp":
      isUpPressed = false;
      break;

    case "ArrowDown":
      isDownPressed = false;
      break;

    case " ":
      isActionPressed = false;
      break;

    case "Enter":
      isMenuPressed = false;
      break;

    case "Escape":
      isCancelPressed = false;
      break;
  }

  if (event.shiftKey) {
    isShiftPressed = true;
  } else {
    isShiftPressed = false;
  }
});

// Gamepad support
function updateGamepad() {
  const gamepad = navigator.getGamepads()[0];

  if (gamepad) {
    const axes = gamepad.axes;
    const buttons = gamepad.buttons;

    isLeftPressed = axes[0] < -0.5 || buttons[14].pressed;
    isRightPressed = axes[0] > 0.5 || buttons[15].pressed;
    isUpPressed = axes[1] < -0.5 || buttons[12].pressed;
    isDownPressed = axes[1] > 0.5 || buttons[13].pressed;
    isShiftPressed = buttons[1].pressed;
    isCancelPressed = buttons[1].pressed;
    isActionPressed = buttons[0].pressed;
    isMenuPressed = buttons[9].pressed;
  }
}

export default function handleKeyboard() {
  updateGamepad();

  onLeftPressed = isLeftPressed && !wasLeftPressed;
  onRightPressed = isRightPressed && !wasRightPressed;
  onUpPressed = isUpPressed && !wasUpPressed;
  onDownPressed = isDownPressed && !wasDownPressed;
  onActionPressed = isActionPressed && !wasActionPressed;
  onCancelPressed = isCancelPressed && !wasCancelPressed;
  onMenuPressed = isMenuPressed && !wasMenuPressed;

  wasLeftPressed = isLeftPressed;
  wasRightPressed = isRightPressed;
  wasUpPressed = isUpPressed;
  wasDownPressed = isDownPressed;
  wasActionPressed = isActionPressed;
  wasCancelPressed = isCancelPressed;
  wasMenuPressed = isMenuPressed;
}
