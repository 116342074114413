import { createSignal } from "solid-js";

export default function useMenuItems(
  isMultiSelect?: boolean,
  selectionFollowsFocus: boolean = true
) {
  const [menuItemIds, setMenuItemIds] = createSignal<string[]>([]);
  const [activeItemIndex, setActiveItemIndexSignal] = createSignal<number>(-1);
  function setActiveItemIndex(index: number) {
    setActiveItemIndexSignal(index);
    document.getElementById(menuItemIds()[index])?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }

  function addMenuItem(id: string) {
    setMenuItemIds([...menuItemIds(), id]);
  }

  function removeMenuItem(id: string) {
    setMenuItemIds(menuItemIds().filter((item) => item !== id));
  }

  function activeItem() {
    return menuItemIds()[activeItemIndex()];
  }

  function resetActiveItemIndex() {
    if (isMultiSelect) {
      setActiveItemIndex(0);
    } else {
      const selectedItemIndex = menuItemIds().findIndex(
        (item) =>
          document.getElementById(item)?.getAttribute("data-isselected") ===
          "true"
      );
      setActiveItemIndex(selectedItemIndex || 0);
    }
  }

  function onKeyDown(
    event: KeyboardEvent & { currentTarget: HTMLDivElement; target: Element }
  ) {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (event.key === "ArrowDown") {
      const nextMenuItem =
        activeItemIndex() < menuItemIds().length - 1
          ? activeItemIndex() + 1
          : 0;

      setActiveItemIndex(nextMenuItem);
    }

    if (event.key === "ArrowUp") {
      const previousMenuItem =
        activeItemIndex() > 0
          ? activeItemIndex() - 1
          : menuItemIds().length - 1;

      setActiveItemIndex(previousMenuItem);
    }

    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      if (isMultiSelect || !selectionFollowsFocus) {
        return;
      }

      const keyboardEvent = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        ctrlKey: true,
        key: " ",
        view: window,
      });

      document.getElementById(activeItem())?.dispatchEvent(keyboardEvent);
    }

    if (event.key === "Enter" || event.key === " ") {
      const keyboardEvent = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        key: event.key,
        view: window,
      });

      document.getElementById(activeItem())?.dispatchEvent(keyboardEvent);
    }
  }

  return {
    activeItem,
    activeItemIndex,
    addMenuItem,
    menuItems: menuItemIds,
    onKeyDown,
    removeMenuItem,
    resetActiveItemIndex,
    setActiveItemIndex,
  };
}
