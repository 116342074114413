import Solid from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { isBetaUser } from "@/features/featureFlags/state/betaUser";
import { HandIcon } from "@/icons/HandIcon";
import { LassoIcon } from "@/icons/LassoIcon";
import { PenIcon } from "@/icons/PenIcon";

import { setTouchAction, touchAction } from "../../state/touchAction";

interface Props extends Solid.ComponentProps<typeof Dropdown> {}

export const TouchActionPicker: Solid.Component<Props> = ({
  isDisabled,
  ...spreadProps
}) => (
  <Dropdown isDisabled={isDisabled || isSigningOut()} {...spreadProps}>
    <DropdownButton class="w-full" intent="secondary" isSelected>
      {touchAction() === "hand" && (
        <>
          <HandIcon class="h-6 w-6" />
          Hand
        </>
      )}
      {touchAction() === "select" && (
        <>
          <LassoIcon class="h-6 w-6" />
          Select
        </>
      )}
      {touchAction() === "tool" && (
        <>
          <PenIcon class="h-6 w-6" />
          Use tool
        </>
      )}
    </DropdownButton>
    <DropdownMenu>
      <MenuItem
        isSelected={touchAction() === "hand"}
        onSelect={() =>
          (async () => {
            await setTouchAction("hand");
          })()
        }
      >
        <HandIcon class="h-6 w-6" />
        Hand
      </MenuItem>
      {isBetaUser() && (
        <MenuItem
          isSelected={touchAction() === "select"}
          onSelect={() =>
            (async () => {
              await setTouchAction("select");
            })()
          }
        >
          <LassoIcon class="h-6 w-6" />
          Select
        </MenuItem>
      )}
      <MenuItem
        isSelected={touchAction() === "tool"}
        onSelect={() =>
          (async () => {
            await setTouchAction("tool");
          })()
        }
      >
        <PenIcon class="h-6 w-6" />
        Use tool
      </MenuItem>
    </DropdownMenu>
  </Dropdown>
);
