import { createSignal } from "solid-js";

export const [isSavingCanvasView, setIsSavingCanvasView] = createSignal(false);
export const [isSavingCanvasStrokes, setIsSavingCanvasStrokes] =
  createSignal(false);
export const [isSavingCanvasShapes, setIsSavingCanvasShapes] =
  createSignal(false);
export const [isSavingColorSwatch, setIsSavingColorSwatch] =
  createSignal(false);
export const [isSavingShapeSwatch, setIsSavingShapeSwatch] =
  createSignal(false);
export const [isSavingHideToolNames, setIsSavingHideToolNames] =
  createSignal(false);

export const [isSavingTheme, setIsSavingTheme] = createSignal(false);
export const [isSavingThemeColor, setIsSavingThemeColor] = createSignal(false);
export const [isSavingTouchAction, setIsSavingTouchAction] =
  createSignal(false);
export const [isSavingDeviceTheme, setIsSavingDeviceTheme] =
  createSignal(false);
export const [isSavingDeviceThemeColor, setIsSavingDeviceThemeColor] =
  createSignal(false);
export const [isSavingDeviceTouchAction, setIsSavingDeviceTouchAction] =
  createSignal(false);

export const isSaving = () =>
  isSavingCanvasView() ||
  isSavingCanvasStrokes() ||
  isSavingCanvasShapes() ||
  isSavingColorSwatch() ||
  isSavingShapeSwatch() ||
  isSavingHideToolNames() ||
  isSavingTheme() ||
  isSavingThemeColor() ||
  isSavingTouchAction() ||
  isSavingDeviceTheme() ||
  isSavingDeviceThemeColor() ||
  isSavingDeviceTouchAction();
