import "./LoadingSpinner.scss";

import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const loadingSpinnerClasses = cva("loadingSpinner", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.SvgSVGAttributes<SVGSVGElement>,
    VariantProps<typeof loadingSpinnerClasses> {}

export const LoadingSpinner: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => (
  <svg
    class={`${loadingSpinnerClasses({})} ${className}`}
    {...attributes}
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="1.4s"
        fill="freeze"
        repeatCount="indefinite"
        type="rotate"
        values="0 36 36;270 36 36"
      />
      <circle
        cx="36"
        cy="36"
        fill="none"
        r="30"
        stroke="currentColor"
        stroke-dasharray="187"
        stroke-dashoffset="610"
        stroke-linecap="round"
        stroke-width="6"
      >
        <animateTransform
          attributeName="transform"
          begin="0s"
          dur="1.4s"
          fill="freeze"
          repeatCount="indefinite"
          type="rotate"
          values="0 36 36;135 36 36;450 36 36"
        />
        <animate
          attributeName="stroke-dashoffset"
          begin="0s"
          dur="1.4s"
          fill="freeze"
          repeatCount="indefinite"
          values="187;46.75;187"
        />
      </circle>
    </g>
  </svg>
);
