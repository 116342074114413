import { FirebaseError } from "firebase/app";

export default async function tryFirebase(
  callback: () => Promise<void>,
  errorHandler: (error: FirebaseError) => Promise<void>
) {
  try {
    await callback();
  } catch (error) {
    if (error instanceof FirebaseError) {
      await errorHandler(error);
    }
  }
}
