import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";

import { banner, setBanner } from "@/features/banner/state/banner";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import withTransition from "@/helpers/withTransition";

import { password } from "../components/forms/SignInForm";
import { setIsReAuthenticating } from "../state/isReAuthenticating";
import { setIsRequestingReAuth } from "../state/isRequestingReAuth";
import { setSignInMethod } from "../state/signInMethod";

export async function reAuthWithPassword() {
  setIsReAuthenticating(true);
  setSignInMethod("password");

  function cleanUp() {
    setIsReAuthenticating(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();

      if (!auth.currentUser?.email) {
        throw new Error("No email provided");
      }

      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password()
      );
      await reauthenticateWithCredential(auth.currentUser, credential);

      if (banner()) {
        setBanner({
          intent: "success",
          message: "Signed in successfully, you may now try again",
        });
      }
      // Animation - Sign in with password for re-authentication
      await withTransition(() => {
        setIsRequestingReAuth(false);
        cleanUp();
      });
      setBanner({
        intent: "success",
        message: "Signed in successfully, you may now try again",
      });
    },
    async (error) => {
      await handleError(error);
      await showError(error);
      cleanUp();
    }
  );
}
