import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const deviceSettingsIconClasses = cva("deviceSettingsIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof deviceSettingsIconClasses> {}

export const DeviceSettingsIcon: Solid.Component<Props> = ({
  ...attributes
}) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
