import "./ReAuthForm.scss";

import cn from "clsx";
import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import withTransition from "@/helpers/withTransition";
import { BackIcon } from "@/icons/BackIcon";

import { reAuthWithEmail } from "../../actions/reAuthWithEmail";
import { reAuthWithPassword } from "../../actions/reAuthWithPassword";
import { isReAuthenticating } from "../../state/isReAuthenticating";
import { setIsRequestingReAuth } from "../../state/isRequestingReAuth";
import { isSigningOut } from "../../state/isSigningOut";
import { signInMethod } from "../../state/signInMethod";
import { user } from "../../state/user";
import { SendMagicLinkButton } from "../ui/SendMagicLinkButton";
import { SignInButton } from "../ui/SignInButton";
import { password, setPassword } from "./SignInForm";

export type SignInMethod = "guest" | "email" | "password" | "new" | undefined;

interface Props {}

export const ReAuthForm: Solid.Component<Props> = () => {
  function submitForm() {
    if (user()?.email && !password()) {
      reAuthWithEmail();
    }

    if (user()?.email && password()) {
      reAuthWithPassword();
    }
  }

  return (
    <>
      <div
        class={cn(
          "reAuthForm",
          user()?.email && "reAuthForm-hasEmail",
          password() && "reAuthForm-hasPassword",
          "flex flex-col gap-5"
        )}
      >
        <div>
          This action requires a recent sign-in, please sign in and try again
        </div>
        <div>
          <Input
            class="reAuthForm__email"
            intent="field"
            label="Email"
            onKeyPress={(event) => {
              if (event.key !== "Enter") {
                return;
              }

              submitForm();
            }}
            readonly
            type="email"
            value={user()?.email || ""}
          />
        </div>
        <div>
          <Input
            class="reAuthForm__password"
            intent="field"
            isDisabled={isReAuthenticating() || isSigningOut()}
            label="Password (Optional)"
            onInput={(event) =>
              (async (event) => {
                const value = event.target.value;
                if (document.activeElement === event.target) {
                  // Animation - Sign in buttons changing
                  await withTransition(() => {
                    setPassword(value);
                  });
                }
                setPassword(value);
              })(event)
            }
            onKeyPress={(event) => {
              if (event.key !== "Enter") {
                return;
              }

              submitForm();
            }}
            type="password"
          />
        </div>
        <div
          class={cn(
            "flex auto-cols-auto flex-col justify-end gap-5",
            "signInActions:auto-rows-auto signInActions:flex-row"
          )}
        >
          <div class="reAuthForm__secondaryActions">
            <Button
              isDisabled={isReAuthenticating() || isSigningOut()}
              onClick={() => {
                // Animation - Close re-authentication form
                withTransition(() => {
                  setIsRequestingReAuth(false);
                });
              }}
            >
              <BackIcon class="h-6 w-6" />
              Back
            </Button>
          </div>
          <div
            class={cn("hidden", "signInActions:block signInActions:flex-1")}
          />
          <div class="reAuthForm__primaryActions">
            <SendMagicLinkButton
              class="reAuthForm__sendMagicLink w-full signInActions:w-auto"
              intent="primary"
              isDisabled={isReAuthenticating() || isSigningOut()}
              onClick={reAuthWithEmail}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "reAuthForm__primaryActions",
                }
              }
            />
            <SignInButton
              class="reAuthForm__signIn w-full signInActions:w-auto"
              intent="primary"
              isDisabled={isReAuthenticating() || isSigningOut()}
              onClick={reAuthWithPassword}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "reAuthForm__primaryActions",
                }
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
