import { createEffect, createSignal } from "solid-js";

import useOnMouseDownOutside from "./useOnMouseDownOutside";

export default function useDropdown(
  trigger: () => HTMLElement | undefined,
  contents: () => HTMLElement | undefined
) {
  const [isOpen, setIsOpen] = createSignal(false);

  createEffect(() =>
    useOnMouseDownOutside([contents(), trigger()], () => setIsOpen(false))
  );

  function toggle() {
    setIsOpen(!isOpen());
  }

  function close() {
    setIsOpen(false);
  }

  function open() {
    setIsOpen(true);
  }

  return { close, isOpen, open, toggle };
}
