import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingShapeSwatch } from "@/features/saving/state/isSaving";

import { Shape } from "./shapes";

export const defaultShapeSwatch = ["rectangle"] as Shape["shape"][];

export const {
  isConnected: isShapeSwatchSynced,
  getSignal: shapeSwatch,
  setSignal: setShapeSwatchSignal,
  setSignalConnected: setShapeSwatch,
} = createConnectedSignal<Shape["shape"][]>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/shapeSwatch`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultShapeSwatch,
  onEnd: () => setIsSavingShapeSwatch(false),
  onStart: () => setIsSavingShapeSwatch(true),
});
