import "./UserSettings.scss";

import { A, useLocation, useNavigate } from "@solidjs/router";
import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Menu } from "@/components/Menu/Menu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { PageComponent } from "@/components/PageComponent/PageComponent";
import { deleteUser } from "@/features/authentication/actions/deleteUser";
import { UpdateEmailForm } from "@/features/authentication/components/forms/UpdateEmailForm";
import { UpdatePasswordForm } from "@/features/authentication/components/forms/UpdatePasswordForm";
import { isDeletingUser } from "@/features/authentication/state/isDeletingUser";
import { isTablet } from "@/features/responsive/state/isTablet";
import { DeviceThemePicker } from "@/features/userSettings/components/ui/DeviceThemePicker";
import { DeviceTouchActionPicker } from "@/features/userSettings/components/ui/DeviceTouchActionPicker";
import { ThemeColorPicker } from "@/features/userSettings/components/ui/ThemeColorPicker";
import { ThemePicker } from "@/features/userSettings/components/ui/ThemePicker";
import { TouchActionPicker } from "@/features/userSettings/components/ui/TouchActionPicker";
import { ChevronRight } from "@/icons/ChevronRight";
import { DeleteIcon } from "@/icons/DeleteIcon";
import { DeviceSettingsIcon } from "@/icons/DeviceSettingsIcon";
import { ThemeIcon } from "@/icons/ThemeIcon";
import { TouchActionIcon } from "@/icons/TouchActionIcon";
import { UserSettingsIcon } from "@/icons/UserSettingsIcon";

export enum Tabs {
  ACCOUNT = "account",
  THEME = "theme",
  TOUCH_ACTION = "touch-action",
  DEVICE_SETTINGS = "device-settings",
}

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {}

export const UserSettings: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  document.title = "Scribble Desk - User settings";

  const currentTab = () => {
    if (location.hash === "#account") {
      return Tabs.ACCOUNT;
    }
    if (location.hash === "#theme") {
      return Tabs.THEME;
    }
    if (location.hash === "#touch-action") {
      return Tabs.TOUCH_ACTION;
    }
    if (location.hash === "#device-settings") {
      return Tabs.DEVICE_SETTINGS;
    }
  };

  const hasSelectedTab = () => Boolean(currentTab());

  return (
    <PageComponent class="overflow-hidden flex" withoutPadding>
      <Menu
        class={`${
          hasSelectedTab() && "hidden"
        } tablet:block flex-1 tablet:flex-initial p-2.5 tablet:border-r-2 border-gray-100 dark:border-gray-700`}
      >
        <A href={"#account"} replace={isTablet() ? undefined : true}>
          <MenuItem
            class="flex"
            isSelected={
              currentTab() === Tabs.ACCOUNT || (!currentTab() && !isTablet())
            }
          >
            <UserSettingsIcon class="h-6 w-6" />
            <span class="flex-1">Account</span>
            <ChevronRight class="h-6 w-6 tablet:hidden" />
          </MenuItem>
        </A>
        <A href="#theme" replace={isTablet() ? undefined : true}>
          <MenuItem class="flex" isSelected={currentTab() === Tabs.THEME}>
            <ThemeIcon class="h-6 w-6" />
            <span class="flex-1">Theme</span>
            <ChevronRight class="h-6 w-6 tablet:hidden" />
          </MenuItem>
        </A>
        <A href="#touch-action" replace={isTablet() ? undefined : true}>
          <MenuItem
            class="flex"
            isSelected={currentTab() === Tabs.TOUCH_ACTION}
          >
            <TouchActionIcon class="h-6 w-6" />
            <span class="flex-1">Touch action</span>
            <ChevronRight class="h-6 w-6 tablet:hidden" />
          </MenuItem>
        </A>
        <A href="#device-settings" replace={isTablet() ? undefined : true}>
          <MenuItem
            class="flex"
            isSelected={currentTab() === Tabs.DEVICE_SETTINGS}
          >
            <DeviceSettingsIcon class="h-6 w-6" />
            <span class="flex-1">Device settings</span>
            <ChevronRight class="h-6 w-6 tablet:hidden" />
          </MenuItem>
        </A>
      </Menu>
      <div
        class={`pageUserSettings flex-1 ${
          !hasSelectedTab() ? "hidden tablet:flex" : "flex"
        } flex-col gap-5 p-5 tablet:p-10 ${className}`}
        {...attributes}
      >
        {(currentTab() === Tabs.ACCOUNT || (!currentTab() && !isTablet())) && (
          <>
            <h2 class="flex gap-2 mb-2">
              <UserSettingsIcon class="h-6 w-6" />
              Account
            </h2>
            <UpdateEmailForm />
            <UpdatePasswordForm />
            <div class="m-auto inline-block">
              <Button
                intent="error"
                isDisabled={isDeletingUser()}
                // TODO (4 - fix): Figure out why we need to pass navigate in here. I would think that `deleteUser` is inside a Router but it claims not to be.
                onClick={() => deleteUser(navigate)}
              >
                {isDeletingUser() ? (
                  <LoadingSpinner class="h-6 w-6" />
                ) : (
                  <DeleteIcon class="h-6 w-6" />
                )}
                Delete my account
              </Button>
            </div>
          </>
        )}
        {currentTab() === Tabs.THEME && (
          <>
            <h2 class="flex gap-2 mb-2">
              <ThemeIcon class="h-6 w-6" />
              Theme
            </h2>
            <div>
              <ThemePicker />
            </div>
            <div>
              <ThemeColorPicker />
            </div>
          </>
        )}

        {currentTab() === Tabs.TOUCH_ACTION && (
          <>
            <h2 class="flex gap-2 mb-2">
              <TouchActionIcon class="h-6 w-6" />
              Touch action
            </h2>
            <div>
              <TouchActionPicker />
            </div>
          </>
        )}
        {currentTab() === Tabs.DEVICE_SETTINGS && (
          <>
            <h2 class="flex gap-2 mb-2">
              <DeviceSettingsIcon class="h-6 w-6" />
              Device settings
            </h2>
            <p>These settings will only apply to this device.</p>
            <h3 class="flex gap-2 mt-2">
              <ThemeIcon class="h-6 w-6" />
              Theme
            </h3>
            <div>
              <DeviceThemePicker />
            </div>
            {/* <div>
          <DeviceThemeColorPicker />
        </div> */}
            <h3 class="flex gap-2 mt-2">
              <TouchActionIcon class="h-6 w-6" />
              Touch action
            </h3>
            <div>
              <DeviceTouchActionPicker />
            </div>
          </>
        )}
      </div>
    </PageComponent>
  );
};
