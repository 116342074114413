import Solid from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { DarkModeIcon } from "@/icons/DarkModeIcon";
import { LightModeIcon } from "@/icons/LightModeIcon";
import { SystemModeIcon } from "@/icons/SystemModeIcon";

import { deviceTheme, setDeviceTheme, theme } from "../../state/theme";

interface Props extends Solid.ComponentProps<typeof Dropdown> {}

export const DeviceThemePicker: Solid.Component<Props> = ({
  isDisabled,
  ...spreadProps
}) => (
  <Dropdown isDisabled={isDisabled || isSigningOut()} {...spreadProps}>
    <DropdownButton class="w-full" intent="secondary" isSelected>
      {deviceTheme() === "dark" && (
        <>
          <DarkModeIcon class="h-6 w-6" />
          Dark mode
        </>
      )}
      {deviceTheme() === "light" && (
        <>
          <LightModeIcon class="h-6 w-6" />
          Light mode
        </>
      )}
      {deviceTheme() === "system" && (
        <>
          <SystemModeIcon class="h-6 w-6" />
          System
        </>
      )}
      {deviceTheme() === undefined && (
        <>
          {theme() === "dark" && (
            <>
              <DarkModeIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(Dark mode)</span>
            </>
          )}
          {theme() === "light" && (
            <>
              <LightModeIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(Light mode)</span>
            </>
          )}
          {theme() === "system" && (
            <>
              <SystemModeIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(System)</span>
            </>
          )}
        </>
      )}
    </DropdownButton>
    <DropdownMenu>
      <MenuItem
        isSelected={deviceTheme() === undefined}
        onSelect={() =>
          (async () => {
            await setDeviceTheme(undefined);
          })()
        }
      >
        {theme() === "dark" && (
          <>
            <DarkModeIcon class="h-6 w-6" />
            User setting{" "}
            <span class="text-gray-500 dark:text-gray-400">(Dark mode)</span>
          </>
        )}
        {theme() === "light" && (
          <>
            <LightModeIcon class="h-6 w-6" />
            User setting{" "}
            <span class="text-gray-500 dark:text-gray-400">(Light mode)</span>
          </>
        )}
        {theme() === "system" && (
          <>
            <SystemModeIcon class="h-6 w-6" />
            User setting{" "}
            <span class="text-gray-500 dark:text-gray-400">(System)</span>
          </>
        )}
      </MenuItem>
      <MenuItem
        isSelected={deviceTheme() === "dark"}
        onSelect={() =>
          (async () => {
            await setDeviceTheme("dark");
          })()
        }
      >
        <>
          <DarkModeIcon class="h-6 w-6" />
          Dark mode
        </>
      </MenuItem>
      <MenuItem
        isSelected={deviceTheme() === "light"}
        onSelect={() =>
          (async () => {
            await setDeviceTheme("light");
          })()
        }
      >
        <LightModeIcon class="h-6 w-6" />
        Light mode
      </MenuItem>
      <MenuItem
        isSelected={deviceTheme() === "system"}
        onSelect={() =>
          (async () => {
            await setDeviceTheme("system");
          })()
        }
      >
        <SystemModeIcon class="h-6 w-6" />
        System
      </MenuItem>
    </DropdownMenu>
  </Dropdown>
);
