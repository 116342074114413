export interface MenuRef {
  element: HTMLElement;
  resetActiveItemIndex: () => void;
}

export default function useMenuRef() {
  let resetActiveItemIndex: MenuRef["resetActiveItemIndex"] = () => {};

  const menuRef = (ref: MenuRef) => {
    resetActiveItemIndex = ref.resetActiveItemIndex;
  };

  return {
    menuRef,
    resetActiveItemIndex: () => resetActiveItemIndex(),
  };
}
