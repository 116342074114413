import "./Button.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid from "solid-js";

import { Ripples } from "@/components/Ripples/Ripples";

const buttonClasses = cva("button", {
  defaultVariants: {},
  variants: {
    intent: {
      error: "button-error",
      primary: "button-primary",
      secondary: "button-secondary",
      success: "button-success",
    },
    isActive: {
      true: "button-active",
    },
    isDisabled: {
      true: "button-disabled ripples-noClick",
    },
    isNextToInput: {
      true: "mb-2 mt-1",
    },
  },
});

interface Props
  extends Omit<
      Solid.JSX.ButtonHTMLAttributes<HTMLButtonElement>,
      "aria-disabled" | "disabled"
    >,
    VariantProps<typeof buttonClasses> {
  isDisabled?: boolean;
}

export const Button: Solid.Component<Props> = ({
  isActive,
  children,
  class: className,
  intent,
  isDisabled = false,
  isNextToInput,
  type,
  ...attributes
}) => (
  <button
    class={cn(
      buttonClasses({
        intent,
        isActive,
        isDisabled,
        isNextToInput,
      }),
      className
    )}
    disabled={isDisabled}
    type={type ?? "button"}
    {...attributes}
  >
    {children}
    <Ripples
      class={cn(isDisabled && "ripples-noClick")}
      color={
        intent === "primary" || intent === "error" || intent === "success"
          ? "white"
          : "gray"
      }
      colorDark="white"
    />
  </button>
);
