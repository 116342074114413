import { ref, set } from "firebase/database";

import { user } from "@/features/authentication/state/user";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { database } from "@/helpers/initializeFirebase";

export async function syncMovement(this: {
  x: number;
  y: number;
  speed: number;
  direction: number;
  xToSync: number;
  yToSync: number;
  isSyncing: boolean;
  syncedDirection: number;
  syncedX: number;
  syncedY: number;
  room: string;
}) {
  const nextX =
    this.x +
    (this.speed > 0 ? 16 - this.speed : 0) *
      Math.cos((this.direction * Math.PI) / 180);

  const nextY =
    this.y +
    (this.speed > 0 ? 16 - this.speed : 0) *
      Math.sin((this.direction * Math.PI) / 180);

  if (nextX % 16 === 0 && nextY % 16 === 0) {
    this.xToSync = nextX;
    this.yToSync = nextY;
  }

  if (
    !this.isSyncing &&
    (this.syncedDirection !== this.direction ||
      this.syncedX !== this.xToSync ||
      this.syncedY !== this.yToSync)
  ) {
    this.isSyncing = true;
    this.syncedDirection = this.direction;
    this.syncedX = this.xToSync;
    this.syncedY = this.yToSync;

    console.log("database write");
    await tryFirebase(
      async () => {
        if (!user()?.uid) {
          return;
        }
        await set(ref(database, `mon/v1/players/${user()?.uid}/position`), {
          direction: this.syncedDirection,
          room: this.room,
          time: Date.now(),
          x: this.syncedX,
          y: this.syncedY,
        });
      },
      async (error) => {
        await handleError(error);
        await showError(error);
      }
    );
    this.isSyncing = false;
  }
}
