import { createEffect, createSignal } from "solid-js";

import { roundClientRect } from "../helpers/roundClientRect";

export type Side = "inside" | "start" | "end" | "top" | "bottom";
export type Alignment = "start" | "center" | "end";

function clamp(value: number, min: number, max: number) {
  const actualMin = Math.min(min, max);
  const actualMax = Math.max(min, max);
  return Math.min(Math.max(value, actualMin), actualMax);
}

export default function usePositioning(
  anchorElement: () => HTMLElement | undefined,
  targetElement: () => HTMLElement | undefined,
  side: () => Side,
  alignment: () => Alignment,
  signal: () => void,
  targetWrapperElement?: () => HTMLElement | undefined
) {
  const [left, setLeft] = createSignal(0);
  const [top, setTop] = createSignal(0);

  const calculatePosition = () => {
    const safeAreaInsetBottom =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--safe-area-inset-bottom"
        )
      ) || 0;
    const safeAreaInsetLeft =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--safe-area-inset-left"
        )
      ) || 0;
    const safeAreaInsetRight =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--safe-area-inset-right"
        )
      ) || 0;
    const safeAreaInsetTop =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--safe-area-inset-top"
        )
      ) || 0;

    const windowBottom = window.innerHeight - safeAreaInsetBottom;
    const windowLeft = safeAreaInsetLeft;
    const windowRight = window.innerWidth - safeAreaInsetRight;
    const windowTop = safeAreaInsetTop;

    const anchorRect = roundClientRect(
      anchorElement()?.getBoundingClientRect()
    );
    const targetRect = roundClientRect(
      targetElement()?.getBoundingClientRect()
    );

    const elementToPositionBeforeMeasuring = () =>
      targetWrapperElement?.() ?? targetElement();

    const originalPosition =
      elementToPositionBeforeMeasuring()?.style.getPropertyValue("position");
    const originalLeft =
      elementToPositionBeforeMeasuring()?.style.getPropertyValue("left");
    const originalTop =
      elementToPositionBeforeMeasuring()?.style.getPropertyValue("top");
    elementToPositionBeforeMeasuring()?.style.setProperty(
      "position",
      "absolute"
    );
    elementToPositionBeforeMeasuring()?.style.setProperty(
      "left",
      `${windowLeft}px`
    );
    elementToPositionBeforeMeasuring()?.style.setProperty(
      "top",
      `${windowTop}px`
    );

    const targetHeight =
      roundClientRect(targetElement()?.getBoundingClientRect())?.height ??
      targetRect?.height ??
      0;
    const targetWidth =
      roundClientRect(targetElement()?.getBoundingClientRect())?.width ??
      targetRect?.width ??
      0;

    // eslint-disable-next-line no-unused-expressions
    originalPosition
      ? elementToPositionBeforeMeasuring()?.style.setProperty(
          "position",
          originalPosition
        )
      : elementToPositionBeforeMeasuring()?.style.removeProperty("position");
    // eslint-disable-next-line no-unused-expressions
    originalLeft
      ? elementToPositionBeforeMeasuring()?.style.setProperty(
          "left",
          originalLeft
        )
      : elementToPositionBeforeMeasuring()?.style.removeProperty("left");
    // eslint-disable-next-line no-unused-expressions
    originalTop
      ? elementToPositionBeforeMeasuring()?.style.setProperty(
          "top",
          originalTop
        )
      : elementToPositionBeforeMeasuring()?.style.removeProperty("top");

    const directionElement = anchorElement();
    const anchorDirection =
      (directionElement && getComputedStyle(directionElement).direction) ||
      "ltr";
    const directedAlignment = () =>
      anchorDirection === "ltr"
        ? alignment()
        : alignment() === "start"
          ? "end"
          : alignment() === "end"
            ? "start"
            : alignment();

    if (!anchorRect || !targetRect) {
      return;
    }

    const anchorVerticalLeft = anchorRect.left;
    const anchorVerticalRight =
      anchorRect.left + anchorRect.width - targetWidth;
    const anchorVerticalCenter =
      anchorVerticalLeft + anchorRect.width / 2 - targetWidth / 2;
    const anchorVerticalBottom = anchorRect.top + anchorRect.height;
    const anchorVerticalTop = anchorRect.top - targetHeight;

    const anchorHorizontalLeft = anchorRect.left - targetWidth;
    const anchorHorizontalRight = anchorRect.left + anchorRect.width;

    const anchorTop = anchorRect.top;
    const anchorBottom = anchorRect.top + anchorRect.height - targetHeight;

    if (side() === "inside") {
      setLeft(anchorVerticalLeft);
      setTop(anchorTop);
    }

    if (side() === "bottom") {
      if (directedAlignment() === "start") {
        setLeft(
          clamp(
            anchorVerticalLeft,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      if (directedAlignment() === "center") {
        setLeft(
          clamp(
            anchorVerticalCenter,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      if (directedAlignment() === "end") {
        setLeft(
          clamp(
            anchorVerticalRight,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      setTop(
        clamp(
          anchorVerticalBottom,
          windowTop,
          Math.max(0, windowBottom - targetHeight)
        )
      );
    }
    if (side() === "top") {
      if (directedAlignment() === "start") {
        setLeft(
          clamp(
            anchorVerticalLeft,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      if (directedAlignment() === "center") {
        setLeft(
          clamp(
            anchorVerticalLeft + anchorRect.width / 2 - targetWidth / 2,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      if (directedAlignment() === "end") {
        setLeft(
          clamp(
            anchorVerticalRight,
            windowLeft,
            Math.max(0, windowRight - targetWidth)
          )
        );
      }
      setTop(
        clamp(
          anchorVerticalTop,
          windowTop,
          Math.max(0, windowBottom - targetHeight)
        )
      );
    }

    // TODO (4 - improvement) - Update start and end to include fixes from top and bottom
    if (side() === "start") {
      if (directedAlignment() === "start") {
        setTop(anchorTop);
      }
      if (directedAlignment() === "center") {
        setTop(anchorTop + anchorRect.height / 2 - targetHeight / 2);
      }
      if (directedAlignment() === "end") {
        setTop(anchorBottom);
      }
      setLeft(anchorHorizontalLeft);
    }
    if (side() === "end") {
      if (directedAlignment() === "start") {
        setTop(anchorTop);
      }
      if (directedAlignment() === "center") {
        setTop(anchorTop + anchorRect.height / 2 - targetHeight / 2);
      }
      if (directedAlignment() === "end") {
        setTop(anchorBottom);
      }
      setLeft(anchorHorizontalRight);
    }
  };

  createEffect(() => {
    signal();
    calculatePosition();
    let timeout: ReturnType<typeof setTimeout>;
    const debouncedCalculatePosition = () => {
      calculatePosition();
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        calculatePosition();
      }, 100);
    };

    window.addEventListener("resize", debouncedCalculatePosition);
    // window.addEventListener("scroll", calculatePosition);

    return () => {
      window.removeEventListener("resize", debouncedCalculatePosition);
      // window.removeEventListener("scroll", calculatePosition);
    };
  });

  createEffect(() => {
    signal();
    const interval = setInterval(() => {
      calculatePosition();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  });

  return { left, top };
}
