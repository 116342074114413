import { viewX, viewY } from "../../../../../helpers/engine/room";
import { currentMenuItemIndex, mainMenuItems } from "./handleMainMenu";

export default function renderMainMenu(ctx: CanvasRenderingContext2D) {
  const menuPaddingX = 13;
  const menuPaddingY = 13;

  const menuHeight = mainMenuItems.length * 13 + menuPaddingY / 2;
  const menuWidth = 80;

  const menuX = viewX + ctx.canvas.width - menuWidth;
  const menuY = viewY;

  ctx.font = "16px Mon";
  ctx.fillStyle = "#FFF";

  // drawSprite({
  //   ctx,
  //   sprite: sprChatFrame,
  //   x: menuX,
  //   y: menuY,
  // });

  ctx.fillRect(menuX, menuY, menuWidth, menuHeight);

  mainMenuItems.forEach((line, index) => {
    // 5px font
    // ctx.fillStyle = "#DDD";
    // ctx.fillText(line, menuX + 19 + 1, menuY + 24 + 1 + 12 * index);

    // ctx.fillStyle = "#222";
    // ctx.fillText(line, menuX + 19, menuY + 24 + 12 * index);

    // 8px font
    ctx.fillStyle = "#DDD";
    ctx.fillText(
      (currentMenuItemIndex === index ? ">" : "") + line,
      menuX + menuPaddingX + 1,
      menuY + menuPaddingY + 1 + 13 * index
    );

    ctx.fillStyle = "#222";
    ctx.fillText(
      (currentMenuItemIndex === index ? ">" : "") + line,
      menuX + menuPaddingX,
      menuY + menuPaddingY + 13 * index
    );
  });
}
