import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const closeIconClasses = cva("closeIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof closeIconClasses> {}

export const CloseIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M6 18L18 6M6 6l12 12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
