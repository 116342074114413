import { onValue, ref } from "firebase/database";
import { nanoid } from "nanoid";

import { user } from "@/features/authentication/state/user";
import { database } from "@/helpers/initializeFirebase";

import { drawSprite } from "../../helpers/engine/drawSprite";
import { getRow } from "../../helpers/engine/getRow";
import handleKeyboard from "../../helpers/engine/handleKeyboard";
import { sprCharacter } from "../../sprites/sprCharacter";
import { allItems, BagItems } from "../objItem/objItem";
import { handleDoors } from "./helpers/handleDoors";
import { handleMovement } from "./helpers/handleMovement";
import { initiateAction } from "./helpers/initiateAction";
import handleMenu from "./helpers/menu/handleMenu";
import renderMenu from "./helpers/menu/renderMenu";
import renderMessage from "./helpers/renderMessage";
import { syncMovement } from "./helpers/syncMovement";

export let myItems: BagItems = {};

export let syncedMyItems = myItems;

export function setSyncedMyItems(items: BagItems) {
  syncedMyItems = items;
}

export function collectItem(item: {
  id: string | undefined;
  item: keyof typeof allItems;
}) {
  const collectedIds = !item.id
    ? myItems[item.item]?.collectedIds || []
    : [...(myItems[item.item]?.collectedIds || []), item.id];

  myItems[item.item] = {
    collectedIds,
    item: item.item,
    quantity: (myItems[item.item]?.quantity || 0) + 1,
  };
}

export class objPlayer {
  get objects() {
    return [
      {
        depth: this.depth,
        render: this.render,
      },
      {
        depth: this.depth + 10000,
        render: this.renderMenu,
      },
      {
        depth: this.depth + 10000,
        render: this.renderMessage,
      },
    ];
  }

  public direction = 90;

  public id = "";

  public static height = 16;
  public static width = 16;
  public height: number;
  public width: number;

  public speed = 0;

  public x: number;
  public y: number;
  public depth: number;

  public message?: string | undefined;

  public isSyncing = false;
  public xToSync: number;
  public yToSync: number;
  public syncedX: number;
  public syncedY: number;
  public syncedDirection = this.direction;

  public isRunning = false;
  public isMoving = false;
  public startedTurning = 0;

  public currentMessage: string | undefined;
  public currentMessageTime?: Date | undefined;
  public nextAction: (() => void) | undefined;

  public room: string;

  constructor({
    direction,
    room,
    speed,
    x,
    y,
  }: {
    direction?: number;
    room: string;
    speed?: number;
    x: number;
    y: number;
  }) {
    this.id = nanoid();
    this.room = room;
    this.height = objPlayer.height;
    this.width = objPlayer.width;
    this.direction = direction || this.direction;
    this.speed = speed || this.speed;
    this.x = x;
    this.y = y;
    this.depth = this.y;
    this.xToSync = this.x;
    this.yToSync = this.y;
    this.syncedX = this.xToSync;
    this.syncedY = this.yToSync;

    onValue(
      ref(database, `mon/v1/players/${user()?.uid}}/items`), // TODO (1 - refactor): Make this cheaper - getting all items  every time one item changes is very expensive in Firebase RTDB
      (snapshot) => {
        console.log("database read");
        if (snapshot.exists()) {
          const itemsData: BagItems = snapshot.val() ?? {};

          myItems = itemsData;
        }
      }
    );
  }

  public step = () => {
    initiateAction.call(this);

    if (!this.nextAction) {
      handleMenu();
    }

    handleMovement.call(this);
    handleDoors.call(this);
    syncMovement.call(this);

    handleKeyboard();
  };

  public render = (ctx: CanvasRenderingContext2D) => {
    const animations = 2;
    const interpolatedFrames = 1;
    const animationLength =
      sprCharacter.columns / animations + interpolatedFrames;

    const directionIndex = getRow(this.direction);

    let animationIndex =
      (animationLength +
        ((Math.round((this.x + this.y) / 8) - 1) % animationLength)) %
      animationLength;
    if (animationIndex === 3) {
      animationIndex = 1;
    }

    drawSprite({
      columnIndex:
        animationIndex +
        (this.speed > 0 ? (this.speed > 1 ? animationLength - 1 : 0) : 0),
      ctx,
      rowIndex: directionIndex,
      sprite: sprCharacter,
      x: this.x,
      y: this.y,
    });
  };

  public renderMessage = (ctx: CanvasRenderingContext2D) => {
    if (!this.currentMessage) {
      return;
    }

    renderMessage(this.currentMessage, this.currentMessageTime, ctx);
  };

  public renderMenu = (ctx: CanvasRenderingContext2D) => {
    renderMenu(ctx);
  };
}
