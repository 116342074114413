import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const eraserIconClasses = cva("eraserIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof eraserIconClasses> {}

export const EraserIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="currentColor"
    viewBox="0 -960 960 960"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path d="M678-220h203v60H618l60-60Zm-499 60-81-84q-18-17-17.5-41.5T97-327l458-498q16-17 40.567-17 24.566 0 41.433 17l205 215q17 17 18 42t-16 42L503-160H179Zm301-60 324-352-207-217-455 503 64 66h274Zm0-260Z" />
  </svg>
);
