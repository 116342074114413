import Solid, { createSignal } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { PasswordIcon } from "@/icons/PasswordIcon";

import { updatePassword } from "../../actions/updatePassword";
import { isSigningOut } from "../../state/isSigningOut";
import { user } from "../../state/user";

export type SignInMethod = "guest" | "email" | "password" | "new" | undefined;

interface Props {}

export const UpdatePasswordForm: Solid.Component<Props> = () => {
  let passwordInput: HTMLInputElement | undefined;

  const userEmail = () => user()?.email;

  const [newPassword, setNewPassword] = createSignal("");
  const [isUpdatingPassword, setIsUpdatingPassword] = createSignal(false);

  async function submitPassword() {
    setIsUpdatingPassword(true);

    await updatePassword(newPassword(), () => {
      setNewPassword("");
      if (passwordInput?.value) {
        passwordInput.value = "";
      }
    });

    setIsUpdatingPassword(false);
  }

  const isUpdatePasswordDisabled = () =>
    !newPassword() || isUpdatingPassword() || isSigningOut();

  return (
    <>
      {userEmail() && (
        <div class="flex gap-3">
          <div class="flex-1">
            <Input
              autocomplete="new-password"
              isDisabled={isUpdatingPassword() || isSigningOut()}
              label="Password"
              onInput={(event) => {
                setNewPassword(event.currentTarget.value);
              }}
              onKeyPress={(event) => {
                if (event.key !== "Enter") {
                  return;
                }

                if (!isUpdatePasswordDisabled()) {
                  submitPassword();
                }
              }}
              ref={passwordInput}
              type="password"
            />
          </div>
          <div>
            <Button
              class="relative"
              isDisabled={isUpdatePasswordDisabled()}
              isNextToInput
              onClick={submitPassword}
            >
              {isUpdatingPassword() ? (
                <LoadingSpinner class="h-6 w-6" />
              ) : (
                <>
                  <PasswordIcon class="h-6 w-6" />
                  {newPassword() && (
                    <div class="absolute left-8 top-2 rounded-full bg-red-500 p-1" />
                  )}
                </>
              )}
              Update
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
