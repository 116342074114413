import "./SignInForm.scss";

import cn from "clsx";
import Solid, { createSignal } from "solid-js";

import { isInstalled } from "@/App";
import { Input } from "@/components/Input/Input";
import withTransition from "@/helpers/withTransition";
import { EmailIcon } from "@/icons/EmailIcon";

import { signAnonymousUserIn } from "../../actions/signAnonymousUserIn";
import { signUserInWithEmail } from "../../actions/signUserInWithEmail";
import { signUserInWithPassword } from "../../actions/signUserInWithPassword";
import { signUserUp } from "../../actions/signUserUp";
import { hasSentMagicLinkEmail } from "../../state/hasSentMagicLinkEmail";
import { isSigningIn } from "../../state/isSigningIn";
import { isSigningOut } from "../../state/isSigningOut";
import { signInMethod } from "../../state/signInMethod";
import { ContinueAsGuestButton } from "../ui/ContinueAsGuestButton";
import { SendMagicLinkButton } from "../ui/SendMagicLinkButton";
import { SignInButton } from "../ui/SignInButton";
import { SignUpButton } from "../ui/SignUpButton";

interface Props {}

export const [email, setEmail] = createSignal("");
export const [password, setPassword] = createSignal("");

export const SignInForm: Solid.Component<Props> = () => {
  function submitForm() {
    if (!email()) {
      signAnonymousUserIn();
    }

    if (email() && !password()) {
      signUserInWithEmail(email());
    }

    if (email() && password()) {
      signUserInWithPassword(email(), password());
    }
  }

  return (
    <>
      <div
        class={cn(
          "absolute inset-5 flex flex-col items-center justify-center gap-5 tablet:inset-10",
          !hasSentMagicLinkEmail() && "pointer-events-none opacity-0"
        )}
      >
        <EmailIcon class="h-12 w-12 text-[var(--color-brand-500)]" />
        <span>Magic link sent!</span>
        <span>Check your email for a link to sign in.</span>
      </div>
      <div
        class={cn(
          "signInForm",
          email() && "signInForm-hasEmail",
          password() && "signInForm-hasPassword",
          "flex flex-col gap-5",
          hasSentMagicLinkEmail() && "pointer-events-none opacity-0"
        )}
      >
        <div>
          <Input
            class="signInForm__email"
            intent="field"
            isDisabled={isSigningIn() || isSigningOut()}
            label="Email"
            onInput={(event) =>
              (async (event) => {
                const value = event.target.value;
                if (document.activeElement === event.target) {
                  // Animation - Sign in buttons changing
                  await withTransition(() => {
                    setEmail(value);
                  });
                }
                setEmail(value);
              })(event)
            }
            onKeyPress={(event) => {
              if (event.key !== "Enter") {
                return;
              }

              submitForm();
            }}
            type="email"
          />
        </div>
        <div>
          <Input
            class="signInForm__password"
            intent="field"
            isDisabled={isSigningIn() || isSigningOut()}
            label="Password (Optional)"
            onInput={(event) =>
              (async (event) => {
                const value = event.target.value;
                if (document.activeElement === event.target) {
                  // Animation - Sign in buttons changing
                  await withTransition(() => {
                    setPassword(value);
                  });
                }
                setPassword(value);
              })(event)
            }
            onKeyPress={(event) => {
              if (event.key !== "Enter") {
                return;
              }

              submitForm();
            }}
            type="password"
          />
        </div>
        <div
          class={cn(
            "flex auto-cols-auto flex-col justify-end gap-5",
            "signInActions:auto-rows-auto signInActions:flex-row"
          )}
        >
          <div class="signInForm__secondaryActions">
            <SignUpButton
              class="signInForm__signUp w-full signInActions:w-auto"
              isDisabled={isSigningIn() || isSigningOut()}
              onClick={signUserUp}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "signInForm__secondaryActions",
                }
              }
            />
          </div>
          <div
            class={cn("hidden", "signInActions:block signInActions:flex-1")}
          />
          <div class="signInForm__primaryActions">
            <ContinueAsGuestButton
              class="signInForm__continueAsGuest w-full signInActions:w-auto"
              intent="primary"
              isDisabled={isSigningIn() || isSigningOut() || isInstalled()}
              onClick={signAnonymousUserIn}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "signInForm__primaryActions",
                }
              }
            />
            <SendMagicLinkButton
              class="signInForm__sendMagicLink w-full signInActions:w-auto"
              intent="primary"
              isDisabled={isSigningIn() || isSigningOut() || isInstalled()}
              onClick={() => signUserInWithEmail(email())}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "signInForm__primaryActions",
                }
              }
            />
            <SignInButton
              class="signInForm__signIn w-full signInActions:w-auto"
              intent="primary"
              isDisabled={isSigningIn() || isSigningOut()}
              onClick={() => signUserInWithPassword(email(), password())}
              signInMethod={signInMethod()}
              style={
                {
                  // TODO (5 - blocked): Uncomment these once view transitions work
                  // "view-transition-name": "signInForm__primaryActions",
                }
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
