import {
  getAuth,
  sendSignInLinkToEmail,
  verifyBeforeUpdateEmail,
} from "firebase/auth";

import { setBanner } from "@/features/banner/state/banner";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";

import { addEmailToLocalStorage } from "../helpers/addEmailToLocalStorage";
import { user } from "../state/user";

export async function updateEmail(email: string) {
  await tryFirebase(
    async () => {
      const auth = getAuth();
      if (user()?.isAnonymous) {
        await sendSignInLinkToEmail(auth, email, {
          handleCodeInApp: true,
          url: location.href,
        });
        addEmailToLocalStorage(email);
      } else {
        if (!auth.currentUser) {
          throw new Error("No user");
        }
        await verifyBeforeUpdateEmail(auth.currentUser, email);
      }
      setBanner({
        intent: "success",
        message: "Please verify your email using the link we sent you",
      });
    },
    async (error) => {
      await handleError(error, async () => {
        setBanner({
          intent: "success",
          message: "Please verify your email using the link we sent you",
        });
      });
      await showError(error);
    }
  );
}
