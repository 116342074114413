import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingCanvasView } from "@/features/saving/state/isSaving";

export const defaultCanvasView = { x: 0, y: 0, zoom: 1 };

export const {
  isConnected: isCanvasViewSynced,
  getSignal: canvasView,
  setSignal: setCanvasViewSignal,
  setSignalConnected: setCanvasView,
} = createConnectedSignal<{
  x: number;
  y: number;
  zoom: number;
}>({
  databaseLocation: (uid?: string) =>
    `v1/etchasketch/${uid ?? user()?.uid}/view`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultCanvasView,
  onEnd: () => setIsSavingCanvasView(false),
  onStart: () => setIsSavingCanvasView(true),
});
