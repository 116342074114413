import { loadImage } from "./loadImage";

export function createSprite({
  columns = 1,
  rows = 1,
  src,
  xOffset = 0,
  yOffset = 0,
}: {
  columns?: number;
  rows?: number;
  src: string;
  xOffset?: number;
  yOffset?: number;
}) {
  const image = loadImage(src);

  return {
    columns,
    image,
    rows,
    spriteHeight: () => image.height / rows,
    spriteWidth: () => image.width / columns,
    xOffset,
    yOffset,
  };
}
