import Solid, { createSignal } from "solid-js";

const queueableActions: Solid.Setter<boolean>[] = [];

const [getQueuedAction, setQueuedAction] = createSignal<
  (() => void) | undefined
>(undefined);

export { getQueuedAction };

function clearQueuedActions() {
  setQueuedAction(undefined);
  queueableActions.forEach((setIsActionQueued) => setIsActionQueued(false));
}

export function createQueuedAction(action: () => void) {
  const [isActionQueued, setIsActionQueued] = createSignal(false);

  queueableActions.push(setIsActionQueued);

  return {
    isActionQueued,
    queueAction: () => {
      setIsActionQueued(true);
      setQueuedAction(() => () => {
        action();
        clearQueuedActions();
      });
    },
  };
}
