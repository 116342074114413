import "./Toasts.scss";

import { useLocation } from "@solidjs/router";
import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid, { For } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Toast } from "@/features/toast/state/toasts";

const toastsClasses = cva("toasts", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof toastsClasses> {
  toasts: Toast[];
}

export const Toasts: Solid.Component<Props> = ({
  class: className,
  toasts,
  ...attributes
}) => {
  const location = useLocation();

  return (
    <>
      {toasts.length > 0 && (
        <div
          class={cn(
            toastsClasses({}),
            className,
            "absolute bottom-0 left-0 p-5 flex flex-col items-center w-full mb-5",
            location.pathname === "/" ? "pb-12 tablet:pb-0" : "pb-0"
          )}
          {...attributes}
        >
          <For each={toasts}>
            {(toast) => (
              <div class="toasts__toast dark inline-flex gap-3 rounded-lg p-3 mt-5">
                {toast.message && (
                  <div class="flex-grow p-2">{toast.message}</div>
                )}
                {
                  <For each={toast.actions}>
                    {(action) => (
                      <Button onClick={action.callback}>
                        {action.label()}
                      </Button>
                    )}
                  </For>
                }
              </div>
            )}
          </For>
          <div class="h-[env(safe-area-inset-bottom)]" />
        </div>
      )}
    </>
  );
};
