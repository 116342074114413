import Solid from "solid-js";

import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { HandIcon } from "@/icons/HandIcon";
import { LassoIcon } from "@/icons/LassoIcon";
import { PenIcon } from "@/icons/PenIcon";

import {
  deviceTouchAction,
  setDeviceTouchAction,
  touchAction,
} from "../../state/touchAction";

interface Props extends Solid.ComponentProps<typeof Dropdown> {}

export const DeviceTouchActionPicker: Solid.Component<Props> = ({
  isDisabled,
  ...spreadProps
}) => (
  <Dropdown isDisabled={isDisabled || isSigningOut()} {...spreadProps}>
    <DropdownButton class="w-full" intent="secondary" isSelected>
      {deviceTouchAction() === "hand" && (
        <>
          <HandIcon class="h-6 w-6" />
          Hand
        </>
      )}
      {deviceTouchAction() === "select" && (
        <>
          <LassoIcon class="h-6 w-6" />
          Select
        </>
      )}
      {deviceTouchAction() === "tool" && (
        <>
          <PenIcon class="h-6 w-6" />
          Use tool
        </>
      )}
      {deviceTouchAction() === undefined && (
        <>
          {touchAction() === "hand" && (
            <>
              <HandIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(Hand)</span>
            </>
          )}
          {touchAction() === "select" && (
            <>
              <LassoIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(Select)</span>
            </>
          )}
          {touchAction() === "tool" && (
            <>
              <PenIcon class="h-6 w-6" />
              User setting{" "}
              <span class="text-gray-500 dark:text-gray-400">(Use tool)</span>
            </>
          )}
        </>
      )}
    </DropdownButton>
    <DropdownMenu>
      <MenuItem
        isSelected={deviceTouchAction() === undefined}
        onSelect={() => {
          setDeviceTouchAction(undefined);
        }}
      >
        {
          <>
            {touchAction() === "hand" && (
              <>
                <HandIcon class="h-6 w-6" />
                User setting{" "}
                <span class="text-gray-500 dark:text-gray-400">(Hand)</span>
              </>
            )}
            {touchAction() === "select" && (
              <>
                <LassoIcon class="h-6 w-6" />
                User setting{" "}
                <span class="text-gray-500 dark:text-gray-400">(Select)</span>
              </>
            )}
            {touchAction() === "tool" && (
              <>
                <PenIcon class="h-6 w-6" />
                User setting{" "}
                <span class="text-gray-500 dark:text-gray-400">(Use tool)</span>
              </>
            )}
          </>
        }
      </MenuItem>
      <MenuItem
        isSelected={deviceTouchAction() === "hand"}
        onSelect={() =>
          (async () => {
            await setDeviceTouchAction("hand");
          })()
        }
      >
        <>
          <HandIcon class="h-6 w-6" />
          Hand
        </>
      </MenuItem>
      <MenuItem
        isSelected={deviceTouchAction() === "select"}
        onSelect={() =>
          (async () => {
            await setDeviceTouchAction("select");
          })()
        }
      >
        <LassoIcon class="h-6 w-6" />
        Select
      </MenuItem>
      <MenuItem
        isSelected={deviceTouchAction() === "tool"}
        onSelect={() =>
          (async () => {
            await setDeviceTouchAction("tool");
          })()
        }
      >
        <PenIcon class="h-6 w-6" />
        Use tool
      </MenuItem>
    </DropdownMenu>
  </Dropdown>
);
