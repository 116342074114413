import { FirebaseError } from "firebase/app";

import { setBanner } from "@/features/banner/state/banner";

import getErrorMessage from "../helpers/getErrorMessage";

export async function showError(error: FirebaseError) {
  const errorMessage = await getErrorMessage(error);
  if (errorMessage) {
    setBanner({ intent: "error", message: errorMessage });
  }
}
