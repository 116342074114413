// TODO (3 - refactor): Refactor
import "./Header.scss";

import { useLocation, useNavigate } from "@solidjs/router";
import cn from "clsx";
import Solid, { createEffect } from "solid-js";

import { Button } from "@/components/Button/Button";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { DropdownButton } from "@/components/Dropdown/DropdownButton";
import { DropdownMenu } from "@/components/Dropdown/DropdownMenu";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { MenuItem } from "@/components/MenuItem/MenuItem";
import { signUserOut } from "@/features/authentication/actions/signUserOut";
import { isSigningOut } from "@/features/authentication/state/isSigningOut";
import { user } from "@/features/authentication/state/user";
import { setCanvasView } from "@/features/canvas/state/canvasView";
import { setShapes } from "@/features/canvas/state/shapes";
import { setAllStrokes } from "@/features/canvas/state/strokes";
import { isBetaUser } from "@/features/featureFlags/state/betaUser";
import { isSaving } from "@/features/saving/state/isSaving";
import withTransition from "@/helpers/withTransition";
import { BackIcon } from "@/icons/BackIcon";
import { DeleteIcon } from "@/icons/DeleteIcon";
import { DeskSettingsIcon } from "@/icons/DeskSettingsIcon";
import { MenuIcon } from "@/icons/MenuIcon";
import { ResetViewIcon } from "@/icons/ResetViewIcon";
import { SearchIcon } from "@/icons/SearchIcon";
import { ShareIcon } from "@/icons/ShareIcon";
import { SignOutIcon } from "@/icons/SignOutIcon";
import { UserSettingsIcon } from "@/icons/UserSettingsIcon";
import { Logo } from "@/images/Logo";
import { Tabs } from "@/pages/UserSettings/UserSettings";

import {
  createQueuedAction,
  getQueuedAction,
} from "../hooks/createQueuedAction";

interface Props extends Solid.JSX.HTMLAttributes<HTMLElement> {
  isDisabled?: boolean;
}

export const Header: Solid.Component<Props> = ({ ...attributes }) => {
  const { isActionQueued: isBackQueued, queueAction: queueBack } =
    createQueuedAction(() => {
      // Animation - Back
      withTransition(() => {
        navigate(-1);
      });
    });
  const { isActionQueued: isClearCanvasQueued, queueAction: queueClearCanvas } =
    createQueuedAction(() => {
      setAllStrokes([]);
      setShapes([]);
    });
  const {
    isActionQueued: isDeskSettingsQueued,
    queueAction: queueDeskSettings,
  } = createQueuedAction(() => {
    // Animation - Desk settings
    withTransition(() =>
      navigate("/desk-settings" + location.search, {
        replace: location.pathname !== "/",
      })
    );
  });
  const { isActionQueued: isResetZoomQueued, queueAction: queueResetZoom } =
    createQueuedAction(() => {
      setCanvasView({ x: 0, y: 0, zoom: 1 });
    });
  const { isActionQueued: isSignOutQueued, queueAction: queueSignOut } =
    createQueuedAction(async () => {
      await signUserOut();
    });
  const {
    isActionQueued: isUserSettingsQueued,
    queueAction: queueUserSettings,
  } = createQueuedAction(() =>
    // Animation - User settings
    withTransition(() =>
      navigate("/user-settings" + window.location.search, {
        replace: location.pathname !== "/",
      })
    )
  );

  const navigate = useNavigate();

  createEffect(() => {
    const queuedAction = getQueuedAction();
    if (queuedAction && !isSaving()) {
      // Watch out, this is async
      queuedAction();
    }
  });

  const canvasMenuItems = () => [
    // {
    //   callback: () => {
    //     clearQueue();
    //     console.log("quick add");
    //   },
    //   icon: () => <AddIcon class="h-6 w-6" />,
    //   isDisabled: () => isSigningOut(),
    //   isSelected: () => location.pathname === "/quick-add",
    //   label: "Quick Add",
    // },
    {
      callback: queueClearCanvas,
      icon: () =>
        isClearCanvasQueued() ? (
          <LoadingSpinner class="h-6 w-6" />
        ) : (
          <DeleteIcon class="h-6 w-6" />
        ),
      isDisabled: () => isSigningOut(),
      label: "Clear desk",
    },
    {
      callback: queueResetZoom,
      icon: () =>
        isResetZoomQueued() ? (
          <LoadingSpinner class="h-6 w-6" />
        ) : (
          <ResetViewIcon class="h-6 w-6" />
        ),
      isDisabled: () => isSigningOut(),
      label: "Reset view",
    },
    {
      callback: () => {
        console.log("search");
      },
      icon: () => <SearchIcon class="h-6 w-6" />,
      isDisabled: () => isSigningOut(),
      isHidden: () => !isBetaUser(),
      isSelected: () => location.pathname === "/search",
      label: "Search",
    },
    {
      callback: () => {
        console.log("share");
      },
      icon: () => <ShareIcon class="h-6 w-6" />,
      isDisabled: () => isSigningOut(),
      isHidden: () => !isBetaUser(),
      isSelected: () => location.pathname === "/share",
      label: "Share",
    },
  ];

  const menuItems = () => [
    // {
    //   callback: () => {
    //     clearQueue();
    //     console.log("help");
    //   },
    //   icon: () => <HelpIcon class="h-6 w-6" />,
    //   isDisabled: () => isSigningOut(),
    //   isSelected: () => location.pathname === "/help",
    //   label: "Help",
    // },
    {
      callback: queueDeskSettings,
      icon: () =>
        isDeskSettingsQueued() ? (
          <LoadingSpinner class="h-6 w-6" />
        ) : (
          <DeskSettingsIcon class="h-6 w-6" />
        ),
      isDisabled: () => isSigningOut(),
      isHidden: () => !isBetaUser(),
      isSelected: () => location.pathname === "/desk-settings",
      label: "Desk settings",
    },
    {
      callback: queueUserSettings,
      hasNotification: !user()?.emailVerified,
      icon: () =>
        isUserSettingsQueued() ? (
          <LoadingSpinner class="h-6 w-6" />
        ) : (
          <UserSettingsIcon class="h-6 w-6" />
        ),
      isDisabled: () => isSigningOut(),
      isSelected: () => location.pathname === "/user-settings",
      label: "User settings",
    },
    {
      callback: queueSignOut,
      icon: () =>
        isSignOutQueued() ? (
          <LoadingSpinner class="h-6 w-6" />
        ) : (
          <SignOutIcon class="h-6 w-6" />
        ),
      isDisabled: () => isSigningOut(),
      isSelected: () => false,
      label: "Sign out",
    },
  ];

  function getHeadingForPath(path: string) {
    if (path.startsWith("/user-settings")) {
      return (
        <div class="flex gap-2">
          <UserSettingsIcon class="h-6 w-6" />
          User settings
        </div>
      );
    }
  }

  // TODO: Refactor Header to live in each page
  // TODO: Use children to specify the page heading
  // TODO: Add a prop for adding in menu items to the hamburger
  // TODO: Add a prop for hasBackButton
  // TODO: Fix paths with a trailing slash by refactoring [...locations].includes(location.pathname) -> [...locations.find(locationItem => location.pathname.startsWith(locationItem))]

  const backLocations = [
    "/user-settings",
    ...Object.values(Tabs).map((tab) => `/user-settings/${tab}`),
    "/desk-settings",
  ];
  const canvasLocations = ["/"];
  const menuLocations = [
    "/",
    "/user-settings",
    ...Object.values(Tabs).map((tab) => `/user-settings/${tab}`),
    "/desk-settings",
  ];

  const location = useLocation();

  return (
    <header
      class={cn(
        "header",
        "flex",
        ![...backLocations, ...canvasLocations, ...menuLocations].includes(
          location.pathname
        ) && "hidden"
      )}
      {...attributes}
    >
      <div>
        {backLocations.includes(location.pathname) ? (
          <Button isDisabled={isSigningOut()} onClick={queueBack}>
            {isBackQueued() ? (
              <LoadingSpinner class="h-6 w-6" />
            ) : (
              <BackIcon class="h-6 w-6" />
            )}
            <span class="hidden mobile:inline">Back</span>
          </Button>
        ) : (
          <div class="px-2 py-2.5">
            <Logo class="h-5" />
          </div>
        )}
      </div>
      <div class={cn("flex-1 self-center pl-4 flex gap-4")}>
        <h1>{getHeadingForPath(location.pathname)}</h1>
        <div
          class={cn(
            "transition-opacity delay-[1000ms]",
            isSaving() ? "opacity-100" : "opacity-0"
          )}
        >
          <span>Saving...</span>
        </div>
      </div>
      <div class="hidden gap-2 header:flex">
        {canvasLocations.includes(location.pathname) &&
          canvasMenuItems().map(
            ({ callback, icon, isDisabled, isHidden, label }) =>
              isHidden?.() ? null : (
                <Button isDisabled={isDisabled()} onClick={callback}>
                  {icon()}
                  <span>{label}</span>
                </Button>
              )
          )}
        {menuLocations.includes(location.pathname) &&
          menuItems().map(
            ({
              callback,
              hasNotification,
              icon,
              isDisabled,
              isHidden,
              label,
            }) =>
              isHidden?.() ? null : (
                <Button isDisabled={isDisabled()} onClick={callback}>
                  {icon()}
                  <span>{label}</span>
                  {hasNotification && (
                    <div class="absolute left-7 top-2 rounded-full bg-red-500 p-1" />
                  )}
                </Button>
              )
          )}
      </div>
      <div class="inline header:hidden">
        {[...canvasLocations, ...menuLocations].includes(location.pathname) && (
          <Dropdown alignment={() => "end"} selectionFollowsFocus={false}>
            <DropdownButton hasCaret={false}>
              <span class="hidden mobile:inline">Menu</span>
              {isDeskSettingsQueued() ||
              isUserSettingsQueued() ||
              isSignOutQueued() ? (
                <LoadingSpinner class="h-6 w-6" />
              ) : (
                <MenuIcon class="h-6 w-6" />
              )}
              {menuItems().find((menuItem) => menuItem.hasNotification) && (
                <div class="absolute right-2 top-2 rounded-full bg-red-500 p-1" />
              )}
            </DropdownButton>
            <DropdownMenu>
              {canvasLocations.includes(location.pathname) &&
                canvasMenuItems().map(
                  ({ callback, icon, isDisabled, isHidden, label }) =>
                    isHidden?.() ? null : (
                      <MenuItem isDisabled={isDisabled()} onSelect={callback}>
                        {icon()}
                        {label}
                      </MenuItem>
                    )
                )}
              {menuLocations.includes(location.pathname) &&
                menuItems().map(
                  ({
                    callback,
                    hasNotification,
                    icon,
                    isDisabled,
                    isHidden,
                    isSelected,
                    label,
                  }) =>
                    isHidden?.() ? null : (
                      <MenuItem
                        isDisabled={isDisabled()}
                        isSelected={isSelected()}
                        onSelect={callback}
                      >
                        {icon()}
                        {label}
                        {hasNotification && (
                          <div class="absolute left-6 top-2 rounded-full bg-red-500 p-1" />
                        )}
                      </MenuItem>
                    )
                )}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </header>
  );
};
