import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://cf05225f71014e94853b4193a3c574d0@o4505417626419200.ingest.sentry.io/4505417631989760",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/scribbledesk\.app/,
        /^https:\/\/scribb\.app/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Session Replay
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
});
