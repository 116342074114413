import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createSignal } from "solid-js";

import { user } from "@/features/authentication/state/user";

import { setLocalStorageSignals } from "../state/localStorageSignals";

export function createLocalStorageSignal({
  initialValue,
  localStorageLocation,
  onEnd,
  onStart,
}: {
  initialValue?: string;
  localStorageLocation: (uid?: string) => string;
  onEnd: () => void;
  onStart: () => void;
}) {
  const [getSignal, setSignal] = createSignal(initialValue);

  // Handle resetting the value on sign out
  setLocalStorageSignals((prev) => [
    ...prev,
    {
      initialValue,
      signalSetter: setSignal,
    },
  ]);

  // Handle loading values from localStorage
  onAuthStateChanged(getAuth(), (newUser) => {
    setSignal(
      window.localStorage.getItem(localStorageLocation(newUser?.uid)) ??
        undefined
    );
  });

  // Handle setting values to localStorage
  async function setSignalConnected(value?: string) {
    onStart();

    setSignal(value);

    if (value) {
      window.localStorage.setItem(localStorageLocation(user()?.uid), value);
    } else {
      window.localStorage.removeItem(localStorageLocation(user()?.uid));
    }

    onEnd();
  }

  return { getSignal, setSignal, setSignalConnected };
}
