import { createSprite } from "./createSprite";

export function drawSprite({
  ctx,
  sprite,
  columnIndex = 0,
  rowIndex = 0,
  x,
  y,
}: {
  ctx: CanvasRenderingContext2D;
  sprite: ReturnType<typeof createSprite>;
  columnIndex?: number;
  rowIndex?: number;
  x: number;
  y: number;
}) {
  ctx.drawImage(
    sprite.image,

    columnIndex * sprite.spriteWidth(),
    rowIndex * sprite.spriteHeight(),
    sprite.spriteWidth(),
    sprite.spriteHeight(),

    x - sprite.xOffset,
    y - sprite.yOffset,
    sprite.spriteWidth(),
    sprite.spriteHeight()
  );
}
