import Solid, { createContext, createEffect, createSignal } from "solid-js";

import { Menu } from "@/components/Menu/Menu";

import { useDeleteBorderRadius } from "./hooks/useDeleteBorderRadius";
import useDropdown from "./hooks/useDropdown";
import usePositioning, { Alignment, Side } from "./hooks/usePositioning";

export const DropdownContext = createContext<{
  menuProps?: () => {
    borderStyles: Solid.JSX.CSSProperties;
    isDisabled?: boolean;
    isMultiSelect?: Solid.ComponentProps<typeof Menu>["isMultiSelect"];
    isOpen: () => boolean;
    menuBorderElementRef: (element: HTMLElement) => void;
    onClick: (event: MouseEvent) => void;
    onKeyDown: (event: KeyboardEvent) => void;
    positioningStyles: Solid.JSX.CSSProperties;
    ref: (element: HTMLElement) => void;
    selectionFollowsFocus?: Solid.ComponentProps<typeof Menu>["isMultiSelect"];
    side: () => Side;
  };
  triggerProps?: () => {
    borderStyles: Solid.JSX.CSSProperties;
    close: () => void;
    isDisabled?: boolean;
    isOpen: () => boolean;
    menu: () => HTMLElement | undefined;
    toggle: () => void;
    ref: (element: HTMLElement) => void;
  };
}>({});

interface Props {
  alignment?: () => Alignment;
  children?: Solid.JSX.Element;
  isDisabled?: boolean;
  isMultiSelect?: Solid.ComponentProps<typeof Menu>["isMultiSelect"];
  ref?: ({
    close,
    open,
    toggle,
  }: {
    close: () => void;
    open: () => void;
    toggle: () => void;
  }) => void;
  selectionFollowsFocus?: Solid.ComponentProps<typeof Menu>["isMultiSelect"];
  side?: () => Side;
}

export const Dropdown: Solid.Component<Props> = ({
  alignment = () => "start",
  children,
  isDisabled,
  isMultiSelect,
  ref,
  selectionFollowsFocus,
  side = () => "bottom",
}) => {
  const [menu, setMenu] = createSignal<HTMLElement>();
  const [menuBorderElement, setMenuBorderElement] = createSignal<HTMLElement>();
  const [trigger, setTrigger] = createSignal<HTMLElement>();

  const { isOpen, close, open, toggle } = useDropdown(
    trigger,
    menuBorderElement
  );

  const { left, top } = usePositioning(
    trigger,
    menu,
    side,
    alignment,
    isOpen,
    menuBorderElement
  );
  const { menuStyles, triggerStyles } = useDeleteBorderRadius(
    trigger,
    menuBorderElement,
    side,
    alignment,
    isOpen
  );

  createEffect(() => {
    ref?.({ close, open, toggle });
  });

  const triggerWidth = () => trigger()?.getBoundingClientRect().width;
  const menuProps = () => ({
    borderStyles: menuStyles(),
    isDisabled,
    isMultiSelect,
    isOpen: () => isOpen() && !isDisabled,
    menuBorderElementRef: setMenuBorderElement,
    onClick: () => {
      close();
    },
    onKeyDown: (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        close();
      }

      if (event.key === " " && !event.ctrlKey && !isMultiSelect) {
        close();
      }

      if (event.key === "Escape") {
        close();
      }
    },
    positioningStyles: {
      left: `${left()}px`,
      "min-width": triggerWidth() && `${triggerWidth()}px`,
      position: "absolute" as Solid.JSX.CSSProperties["position"],
      top: `${top()}px`,
    },
    ref: setMenu,
    selectionFollowsFocus,
    side,
    trigger: trigger(),
  });

  const triggerProps = () => ({
    borderStyles: triggerStyles(),
    close,
    isDisabled,
    isOpen: () => isOpen() && !isDisabled,
    menu,
    ref: setTrigger,
    toggle,
  });

  return (
    <DropdownContext.Provider value={{ menuProps, triggerProps }}>
      {children}
    </DropdownContext.Provider>
  );
};
