import { createEffect, createSignal } from "solid-js";

import { roundClientRect } from "../helpers/roundClientRect";
import { Alignment, Side } from "./usePositioning";

export const useDeleteBorderRadius = (
  anchorElement: () => HTMLElement | undefined,
  targetElement: () => HTMLElement | undefined,
  side: () => Side,
  alignment: () => Alignment,
  signal: () => void
) => {
  const [triggerRadius, setTriggerRadius] = createSignal<{
    topLeft?: boolean;
    topRight?: boolean;
    bottomLeft?: boolean;
    bottomRight?: boolean;
  }>({});
  const [menuRadius, setMenuRadius] = createSignal<{
    topLeft?: boolean;
    topRight?: boolean;
    bottomLeft?: boolean;
    bottomRight?: boolean;
  }>({});

  const calculateBorders = () => {
    const triggerRect = roundClientRect(
      anchorElement()?.getBoundingClientRect()
    );
    const menuRect = roundClientRect(targetElement()?.getBoundingClientRect());

    if (!triggerRect || !menuRect) {
      return {
        menuRadius: menuRadius,
        triggerRadius: triggerRadius,
      };
    }

    const directionElement = anchorElement();
    const anchorDirection =
      (directionElement && getComputedStyle(directionElement).direction) ||
      "ltr";
    const directedAlignment = () =>
      anchorDirection === "ltr"
        ? alignment()
        : alignment() === "start"
          ? "end"
          : alignment() === "end"
            ? "start"
            : alignment();
    const isMenuWider = menuRect.width >= triggerRect.width;
    const isTriggerWider = triggerRect.width >= menuRect.width;
    const isMenuTaller = menuRect.height >= triggerRect.height;
    const isTriggerTaller = triggerRect.height >= menuRect.height;

    if (side() === "inside") {
      setTriggerRadius({});
      setMenuRadius({});
    }

    if (side() === "bottom") {
      if (directedAlignment() === "start") {
        setTriggerRadius({
          bottomLeft: true,
          bottomRight: isMenuWider,
        });
        setMenuRadius({
          bottomLeft: menuRect.top + menuRect.height === window.innerHeight,
          bottomRight: menuRect.top + menuRect.height === window.innerHeight,
          topLeft:
            menuRect.top > triggerRect.top + triggerRect.height / 2 ||
            menuRect.top === 0,
          topRight:
            (isTriggerWider &&
              menuRect.top > triggerRect.top + triggerRect.height / 2) ||
            menuRect.top === 0,
        });
      }

      if (directedAlignment() === "center") {
        setTriggerRadius({
          bottomLeft: isMenuWider,
          bottomRight: isMenuWider,
        });
        setMenuRadius({
          bottomLeft: menuRect.top + menuRect.height === window.innerHeight,
          bottomRight: menuRect.top + menuRect.height === window.innerHeight,
          topLeft:
            (isTriggerWider &&
              menuRect.top > triggerRect.top + triggerRect.height / 2) ||
            menuRect.top === 0,
          topRight:
            (isTriggerWider &&
              menuRect.top > triggerRect.top + triggerRect.height / 2) ||
            menuRect.top === 0,
        });
      }

      if (directedAlignment() === "end") {
        setTriggerRadius({
          bottomLeft: isMenuWider,
          bottomRight: true,
        });
        setMenuRadius({
          bottomLeft: menuRect.top + menuRect.height === window.innerHeight,
          bottomRight: menuRect.top + menuRect.height === window.innerHeight,
          topLeft:
            (isTriggerWider &&
              menuRect.top > triggerRect.top + triggerRect.height / 2) ||
            menuRect.top === 0,
          topRight:
            menuRect.top > triggerRect.top + triggerRect.height / 2 ||
            menuRect.top === 0,
        });
      }
    }

    if (side() === "top") {
      if (directedAlignment() === "start") {
        setTriggerRadius({
          topLeft: true,
          topRight: isMenuWider,
        });
        setMenuRadius({
          bottomLeft:
            menuRect.top > 0 || menuRect.height === window.innerHeight,
          bottomRight:
            (isTriggerWider && menuRect.top > 0) ||
            menuRect.height === window.innerHeight,
          topLeft: menuRect.top === 0,
          topRight: menuRect.top === 0,
        });
      }

      if (directedAlignment() === "center") {
        setTriggerRadius({
          topLeft: isMenuWider,
          topRight: isMenuWider,
        });
        setMenuRadius({
          bottomLeft:
            (isTriggerWider && menuRect.top > 0) ||
            menuRect.height === window.innerHeight,
          bottomRight:
            (isTriggerWider && menuRect.top > 0) ||
            menuRect.height === window.innerHeight,
          topLeft: menuRect.top === 0,
          topRight: menuRect.top === 0,
        });
      }

      if (directedAlignment() === "end") {
        setTriggerRadius({
          topLeft: isMenuWider,
          topRight: true,
        });
        setMenuRadius({
          bottomLeft:
            (isTriggerWider && menuRect.top > 0) ||
            menuRect.height === window.innerHeight,
          bottomRight:
            menuRect.top > 0 || menuRect.height === window.innerHeight,
          topLeft: menuRect.top === 0,
          topRight: menuRect.top === 0,
        });
      }
    }

    if (side() === "start" || (side() === "end" && anchorDirection === "rtl")) {
      if (directedAlignment() === "start") {
        setTriggerRadius({
          bottomLeft: isMenuTaller,
          topLeft: true,
        });
        setMenuRadius({
          bottomRight: isTriggerTaller,
          topRight: true,
        });
      }

      if (directedAlignment() === "center") {
        setTriggerRadius({
          bottomLeft: isMenuTaller,
          topLeft: isMenuTaller,
        });
        setMenuRadius({
          bottomRight: isTriggerTaller,
          topRight: isTriggerTaller,
        });
      }

      if (directedAlignment() === "end") {
        setTriggerRadius({
          bottomLeft: true,
          topLeft: isMenuTaller,
        });
        setMenuRadius({
          bottomRight: true,
          topLeft: isTriggerTaller,
        });
      }
    }

    if (side() === "end" || (side() === "start" && anchorDirection === "rtl")) {
      if (directedAlignment() === "start") {
        setTriggerRadius({
          bottomRight: isMenuTaller,
          topRight: true,
        });
        setMenuRadius({
          bottomLeft: isTriggerTaller,
          topLeft: true,
        });
      }

      if (directedAlignment() === "center") {
        setTriggerRadius({
          bottomRight: isMenuTaller,
          topRight: isMenuTaller,
        });
        setMenuRadius({
          bottomLeft: isTriggerTaller,
          topLeft: isTriggerTaller,
        });
      }

      if (directedAlignment() === "end") {
        setTriggerRadius({
          bottomRight: true,
          topRight: isMenuTaller,
        });
        setMenuRadius({
          bottomLeft: true,
          topLeft: isTriggerTaller,
        });
      }
    }
  };

  createEffect(() => {
    signal();
    calculateBorders();
    let timeout: ReturnType<typeof setTimeout>;
    const debouncedCalculateBorders = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        calculateBorders();
      }, 100);
    };

    window.addEventListener("resize", debouncedCalculateBorders);

    return () => {
      window.removeEventListener("resize", debouncedCalculateBorders);
    };
  });

  createEffect(() => {
    signal();
    const interval = setInterval(() => {
      calculateBorders();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  });

  return {
    menuStyles: () => ({
      "border-bottom-left-radius": menuRadius().bottomLeft ? "0" : undefined,
      "border-bottom-right-radius": menuRadius().bottomRight ? "0" : undefined,
      "border-top-left-radius": menuRadius().topLeft ? "0" : undefined,
      "border-top-right-radius": menuRadius().topRight ? "0" : undefined,
    }),
    triggerStyles: () => ({
      "border-bottom-left-radius": triggerRadius().bottomLeft ? "0" : undefined,
      "border-bottom-right-radius": triggerRadius().bottomRight
        ? "0"
        : undefined,
      "border-top-left-radius": triggerRadius().topLeft ? "0" : undefined,
      "border-top-right-radius": triggerRadius().topRight ? "0" : undefined,
    }),
  };
};
