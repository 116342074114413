import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const chevronRightClasses = cva("chevronRight", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof chevronRightClasses> {}

export const ChevronRight: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
