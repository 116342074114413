import {
  defaultCurrentColorPickerIndex,
  setCurrentColorPickerIndex,
} from "@/features/canvas/state/currentColorPickerIndex";
import { defaultTool, setTool } from "@/features/canvas/state/tool";
import {
  defaultIsBetaUser,
  setIsBetaUser,
} from "@/features/featureFlags/state/betaUser";
import { localStorageSignals } from "@/features/localStorage/state/localStorageSignals";

import { connectedSignals } from "../state/connectedSignals";

export function resetState() {
  connectedSignals().forEach(({ initialValue, signalSetter }) => {
    signalSetter(initialValue);
  });

  localStorageSignals().forEach(({ initialValue, signalSetter }) => {
    signalSetter(initialValue);
  });

  setIsBetaUser(defaultIsBetaUser);
  setCurrentColorPickerIndex(defaultCurrentColorPickerIndex);
  setTool(defaultTool);
}
