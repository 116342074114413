import { drawSprite } from "../../helpers/engine/drawSprite";
import { getRow } from "../../helpers/engine/getRow";
import { player } from "../../Mon";
import { sprDoor } from "../../sprites/sprDoor";
import { ActionObject } from "../objPlayer/helpers/handleAction";
import { readMessage } from "../objPlayer/helpers/readMessage";

export class objDoor {
  public doorNumber: number;
  public roomId: string;

  public direction: number;
  public height: number;
  public width: number;
  public x: number;
  public y: number;

  private message?: () => string;

  constructor({
    direction,
    doorNumber,
    message,
    roomId,
    x,
    y,
  }: {
    direction: number;
    doorNumber: number;
    message?: () => string;
    roomId: string;
    x: number;
    y: number;
  }) {
    const height = 16;
    const width = 16;

    this.message = message;

    this.doorNumber = doorNumber;
    this.roomId = roomId;

    this.direction = direction;
    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
  }

  public action = async (objectActioning: ActionObject) => {
    if (this.message) {
      readMessage(objectActioning, this.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  public render = (ctx: CanvasRenderingContext2D) => {
    const directionIndex = getRow(this.direction);
    let spriteIndex = 0;

    if (
      player.current &&
      player.current.x !== undefined &&
      player.current.y !== undefined
    ) {
      const x1 = this.x;
      const x2 = player.current.x;
      const y1 = this.y;
      const y2 = player.current.y;

      const distanceFromDoor = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      spriteIndex = Math.min(
        Math.max(Math.round(4 - distanceFromDoor / 4), 0),
        3
      );
    }

    drawSprite({
      columnIndex: spriteIndex,
      ctx,
      rowIndex: directionIndex,
      sprite: sprDoor,
      x: this.x,
      y: this.y,
    });
  };
}
