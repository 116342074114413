import { createSignal } from "solid-js";

export const [isLoadingImages, setIsLoadingImages] = createSignal<string[]>([]);

export function loadImage(src: string) {
  setIsLoadingImages([...isLoadingImages(), src]);

  const image = new Image();

  image.src = src;

  image.onload = () => {
    setIsLoadingImages(isLoadingImages().filter((s) => s !== src));
  };

  return image;
}
