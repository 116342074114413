import { user } from "@/features/authentication/state/user";
import { ToolColor } from "@/features/canvas/state/colorSwatch";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { createLocalStorageSignal } from "@/features/localStorage/hooks/createLocalStorageSignal";
import {
  setIsSavingDeviceThemeColor,
  setIsSavingThemeColor,
} from "@/features/saving/state/isSaving";

export const defaultThemeColor = "pink";

export const {
  isConnected: isThemeColorSynced,
  getSignal: themeColor,
  setSignal: setThemeColorSignal,
  setSignalConnected: setThemeColor,
} = createConnectedSignal<ToolColor>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/themeColor`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultThemeColor,
  onEnd: () => setIsSavingThemeColor(false),
  onStart: () => setIsSavingThemeColor(true),
});

export const defaultDeviceThemeColor = undefined;

export const {
  getSignal: deviceThemeColor,
  setSignal: setDeviceThemeColorSignal,
  setSignalConnected: setDeviceThemeColor,
} = createLocalStorageSignal({
  initialValue: defaultDeviceThemeColor,
  localStorageLocation: (uid?: string) => `${uid}/themeColor`,
  onEnd: () => setIsSavingDeviceThemeColor(false),
  onStart: () => setIsSavingDeviceThemeColor(true),
});

export const calculatedThemeColor = () => deviceThemeColor() || themeColor();
