import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { ContinueAsGuestIcon } from "@/icons/ContinueAsGuestIcon";

interface Props extends Solid.ComponentProps<typeof Button> {
  signInMethod?: string;
}

export const ContinueAsGuestButton: Solid.Component<Props> = ({
  isDisabled,
  signInMethod,
  ...spreadProps
}) => (
  <Button isDisabled={isDisabled} {...spreadProps}>
    {isDisabled && signInMethod === "guest" ? (
      <LoadingSpinner class="h-6 w-6" />
    ) : (
      <ContinueAsGuestIcon class="h-6 w-6" />
    )}
    Continue as guest
  </Button>
);
