import { FirebaseError } from "firebase/app";

export default async function getErrorMessage(
  error: FirebaseError
): Promise<string | undefined> {
  switch (error.code) {
    // Sign in with invalid credentials
    case "auth/wrong-password":
    case "auth/custom-token-mismatch":
    case "auth/rejected-credential":
      return "The email and password provided were not recognised";

    // Sign in with account that doesn't exist
    case "auth/user-not-found":
      return "The email and password provided were not recognised";

    // Sign in with expired credentials
    case "auth/code-expired":
    case "auth/invalid-action-code":
    case "auth/expired-action-code":
    case "auth/invalid-user-token":
    case "auth/invalid-auth-event":
    case "auth/user-token-expired":
    case "auth/user-signed-out":
      return "Your credentials have expired, please sign in again";

    case "auth/requires-recent-login":
      return "This action requires a recent sign-in, please sign in and try again";

    // Sign up with invalid email
    case "auth/invalid-email":
    case "auth/invalid-new-email":
    case "auth/invalid-recipient-email":
      if (error.message.includes("does not match")) {
        return "The email provided does not match the magic link";
      }
      return "The email address provided is not a valid email address";

    // Sign up with account that already exists
    case "auth/credential-already-in-use":
    case "auth/email-already-in-use":
      // Handled by authentication flow
      break;

    // Sign up with weak password
    case "auth/weak-password":
      return "Passwords must be at least 6 characters";

    case "auth/invalid-credential":
      return "The email and password provided were not recognised";

    // Perform action that requires verification
    case "auth/unverified-email":
    case "auth/email-change-needs-verification":
      return "Please verify your email using the link we sent you";

    // Auth popups
    case "auth/popup-blocked":
    case "auth/popup-closed-by-user":
    case "auth/cancelled-popup-request":
      return "Popup closed, please try again";

    // Too many requests
    case "auth/too-many-requests":
      return "You have failed to sign in too many times, please try again later";

    // Reauthenticate with the wrong user
    case "auth/user-mismatch":
      return "Please sign in with the account you are currently using";

    // Account deleted
    case "auth/user-cancelled":
    case "auth/user-disabled":
      return "Your account has been disabled, please create a new one";

    // Linking accounts
    case "auth/provider-already-linked":
      // Do nothing
      break;
    case "auth/account-exists-with-different-credential":
      return "The email and password provided were not recognised";

    case "auth/web-storage-unsupported":
      return "Your browser does not support local storage, please try again with a different browser";

    case "auth/argument-error":
      return "An internal error occurred, please refresh the page and try again later";

    // Unused
    // case "auth/invalid-phone-number":
    // case "auth/missing-phone-number":

    // MFA
    // case "auth/invalid-multi-factor-session":
    // case "auth/multi-factor-info-not-found":
    // case "auth/multi-factor-auth-required":
    // case "auth/missing-multi-factor-info":
    // case "auth/missing-multi-factor-session":
    // case "auth/second-factor-already-in-use":
    // case "auth/maximum-second-factor-count-exceeded":

    // Situations that shouldn't arise
    // case "auth/already-initialized":
    // case "auth/tenant-id-mismatch":
    // case "auth/redirect-cancelled-by-user":
    // case "auth/redirect-operation-pending":
    // case "auth/no-auth-event":
    // case "auth/null-user":
    // case "auth/invalid-tenant-id":
    // case "auth/invalid-verification-id":
    // case "auth/missing-verification-code":
    // case "auth/missing-verification-id":
    default:
      console.log(`Error ${error.code}, ${error.message}`);
      return "An internal error occurred, please try again later";
  }
}
