import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const menuIconClasses = cva("menuIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof menuIconClasses> {}

export const MenuIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
