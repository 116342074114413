import { collisionsWhenPlaced } from "../../../helpers/engine/collisionsWhenPlaced";
import { BoundingBox } from "../../../models/BoundingBox";
import { currentMenu } from "./menu/handleMenu";

export interface ActionObject extends BoundingBox {
  x: number;
  y: number;
  direction: number;
  nextAction?: (objectActioning: ActionObject) => void;
}

export async function handleAction(objectActioning: ActionObject) {
  if (currentMenu) {
    return;
  }

  if (objectActioning.nextAction) {
    objectActioning.nextAction(objectActioning);
    return;
  }

  const nextX =
    objectActioning.x +
    1 * Math.round(Math.cos((objectActioning.direction * Math.PI) / 180));
  const nextY =
    objectActioning.y +
    1 * Math.round(Math.sin((objectActioning.direction * Math.PI) / 180));

  const objectsInFrontOfPlayer = collisionsWhenPlaced(
    objectActioning,
    nextX,
    nextY
  ).filter((objectInFrontOfPlayer) => objectInFrontOfPlayer?.action);

  if (objectsInFrontOfPlayer.length) {
    if ("direction" in objectsInFrontOfPlayer[0]) {
      objectsInFrontOfPlayer[0].direction =
        (objectActioning.direction + 180) % 360;
    }

    const action = objectsInFrontOfPlayer[0].action;

    if (action) {
      await action(objectActioning);
    }
  }
}
