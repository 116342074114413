import { drawSprite } from "../../../helpers/engine/drawSprite";
import { player } from "../../../Mon";
import { sprCharacter } from "../../../sprites/sprCharacter";
import { PlayerVector } from "../objOtherPlayers";

export function renderOtherPlayer(
  ctx: CanvasRenderingContext2D,
  playerToRender: PlayerVector & { isRunning: boolean }
) {
  if (
    playerToRender.room !== player.current?.room ||
    !playerToRender.time ||
    playerToRender.time < Date.now() - 5 * 60 * 1000
  ) {
    return;
  }

  const animations = 2;
  const interpolatedFrames = 1;
  const animationLength =
    sprCharacter.columns / animations + interpolatedFrames;

  const getRow = (direction: number) => {
    switch (direction) {
      case 90:
        return 0;
      case 270:
        return 1;
      case 180:
        return 2;
      case 0:
        return 3;
      default:
        return 0;
    }
  };

  const directionIndex = getRow(playerToRender.direction);

  let animationIndex =
    (Math.round((playerToRender.x + playerToRender.y) / 8) - 1) %
    animationLength;

  while (animationIndex < 0) {
    animationIndex += animationLength;
  }

  if (animationIndex === 3) {
    animationIndex = 1;
  }

  drawSprite({
    columnIndex:
      animationIndex + (playerToRender.isRunning ? animationLength - 1 : 0),
    ctx,
    rowIndex: directionIndex,
    sprite: sprCharacter,
    x: playerToRender.x,
    y: playerToRender.y,
  });
}
