import { cva, VariantProps } from "class-variance-authority";
import Solid from "solid-js";

const lightModeIconClasses = cva("lightModeIcon", {
  defaultVariants: {},
  variants: {},
});

interface Props
  extends Solid.JSX.HTMLAttributes<SVGSVGElement>,
    VariantProps<typeof lightModeIconClasses> {}

export const LightModeIcon: Solid.Component<Props> = ({ ...attributes }) => (
  <svg
    fill="none"
    stroke="currentColor"
    stroke-width={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
