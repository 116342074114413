import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { EmailIcon } from "@/icons/EmailIcon";

interface Props extends Solid.ComponentProps<typeof Button> {
  signInMethod?: string;
}

export const SendMagicLinkButton: Solid.Component<Props> = ({
  isDisabled,
  signInMethod,
  ...spreadProps
}) => (
  <Button isDisabled={isDisabled} {...spreadProps}>
    {isDisabled && signInMethod === "email" ? (
      <LoadingSpinner class="h-6 w-6" />
    ) : (
      <EmailIcon class="h-6 w-6" />
    )}
    Send magic link
  </Button>
);
