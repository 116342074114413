import Solid from "solid-js";

export default function isClickEvent(
  event:
    | Solid.JSX.EventHandlerUnion<HTMLDivElement, KeyboardEvent>
    | Solid.JSX.EventHandlerUnion<HTMLDivElement, TouchEvent>
    | Solid.JSX.EventHandlerUnion<HTMLDivElement, MouseEvent>
) {
  if (event instanceof MouseEvent && event.button === 0) {
    return true;
  }

  if (event instanceof TouchEvent && event.touches.length === 1) {
    return true;
  }

  if (
    event instanceof KeyboardEvent &&
    (event.key === "Enter" || event.key === " ")
  ) {
    return true;
  }

  return false;
}
