import { user } from "@/features/authentication/state/user";

import { player } from "../../../Mon";
import { PlayerVector } from "../objOtherPlayers";

export const walkOtherPlayerToLocation =
  (allPlayers: {
    [uid: string]: {
      currentVector: PlayerVector & {
        isRunning: boolean;
      };
      nextVector: PlayerVector;
    };
  }) =>
  ([uid, { currentVector, nextVector }]: [
    string,
    {
      currentVector: PlayerVector & { isRunning: boolean };
      nextVector: PlayerVector;
    },
  ]) => {
    if (uid !== user()?.uid) {
      const vectorUpdate = () => {
        if (
          currentVector.room !== player.current?.room ||
          nextVector.room !== player.current?.room
        ) {
          return { ...nextVector, isRunning: false };
        }

        if (currentVector.x !== nextVector.x && currentVector.y % 16 === 0) {
          const isRunning = Math.abs(nextVector.x - currentVector.x) > 16;

          return {
            ...currentVector,
            direction: nextVector.x > currentVector.x ? 0 : 180,
            isRunning,
            x:
              currentVector.x +
              (isRunning ? 2 : 1) * (nextVector.x > currentVector.x ? 1 : -1),
          };
        }

        if (currentVector.y !== nextVector.y && currentVector.x % 16 === 0) {
          const isRunning = Math.abs(nextVector.y - currentVector.y) > 20;

          return {
            ...currentVector,
            direction: nextVector.y > currentVector.y ? 90 : 270,
            isRunning,
            y:
              currentVector.y +
              (isRunning ? 2 : 1) * (nextVector.y > currentVector.y ? 1 : -1),
          };
        }

        return { ...nextVector, isRunning: false };
      };

      allPlayers[uid].currentVector = vectorUpdate();
    }
  };
