import Solid from "solid-js";

import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { NewUserIcon } from "@/icons/NewUserIcon";

interface Props extends Solid.ComponentProps<typeof Button> {
  signInMethod?: string;
}

export const SignUpButton: Solid.Component<Props> = ({
  isDisabled,
  signInMethod,
  ...spreadProps
}) => (
  <Button isDisabled={isDisabled} {...spreadProps}>
    {isDisabled && signInMethod === "new" ? (
      <LoadingSpinner class="h-6 w-6" />
    ) : (
      <NewUserIcon class="h-6 w-6" />
    )}
    Sign up
  </Button>
);
