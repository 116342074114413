import { currentRoom, player, roomIdMap } from "../../../Mon";
import { objDoor } from "../../objDoor/objDoor";

export function handleDoors(this: {
  direction: number;
  room: string;
  speed: number;
  x: number;
  y: number;
}) {
  currentRoom.current.objects.forEach(async (door) => {
    if (door instanceof objDoor) {
      if (this.x === door.x && this.y === door.y) {
        currentRoom.current = roomIdMap[door.roomId];
        this.room = door.roomId;

        currentRoom.current.cameraObject = player.current;

        const entranceDoor = currentRoom.current.objects.filter(
          (object) => object instanceof objDoor
        )[0];

        if (
          !entranceDoor ||
          entranceDoor.x === undefined ||
          entranceDoor.y === undefined ||
          !(entranceDoor instanceof objDoor)
        ) {
          return;
        }

        this.x = entranceDoor.x;
        this.y = entranceDoor.y;

        this.direction = entranceDoor.direction;

        this.x +=
          Math.round(Math.cos((this.direction * Math.PI) / 180)) * this.speed;
        this.y +=
          Math.round(Math.sin((this.direction * Math.PI) / 180)) * this.speed;
      }
    }
  });
}
