import "./PageComponent.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import Solid from "solid-js";

const pageComponentClasses = cva("pageComponent", {
  defaultVariants: {
    withoutPadding: false,
  },
  variants: {
    withoutPadding: {
      false: "p-5 tablet:p-10",
    },
  },
});

interface Props
  extends Solid.JSX.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pageComponentClasses> {
  isDisabled?: boolean;
}

export const PageComponent: Solid.Component<Props> = ({
  children,
  class: className,
  withoutPadding,
  ...attributes
}) => (
  <div
    class="overflow-auto"
    style={{
      "grid-area": "body",
    }}
  >
    <div
      class={cn(
        pageComponentClasses({ withoutPadding }),
        // tablet (768px) - margin (20px)
        "tablet:mx-auto m-5 tablet:m-10 tablet:w-[728px]",
        "isolate",
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  </div>
);
