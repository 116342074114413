import { createSignal } from "solid-js";

import { canvasView, setCanvasView } from "../state/canvasView";

export const [previousHandToolPosition, setPreviousHandToolPosition] =
  createSignal<{ x: number; y: number } | undefined>(undefined);

export default function useHandTool() {
  function handTool(x: number, y: number) {
    const handX1 = previousHandToolPosition()?.x || x;
    const handY1 = previousHandToolPosition()?.y || y;
    const handX2 = x;
    const handY2 = y;

    setPreviousHandToolPosition({ x: handX2, y: handY2 });
    setCanvasView({
      x: canvasView().x - handX1 + handX2,
      y: canvasView().y - handY1 + handY2,
      zoom: canvasView().zoom,
    });
  }

  function handToolCommit() {
    setPreviousHandToolPosition(undefined);
  }

  function handToolCancel() {
    setPreviousHandToolPosition(undefined);
  }

  function handToolHandler({ clientX, clientY, currentTarget }: PointerEvent) {
    handTool(
      (clientX - (currentTarget as HTMLElement).getBoundingClientRect().left) /
        canvasView().zoom,
      (clientY - (currentTarget as HTMLElement).getBoundingClientRect().top) /
        canvasView().zoom
    );
  }

  return {
    onPointerCancel: handToolCancel,
    onPointerDown: handToolHandler,
    onPointerLeave: handToolCommit,
    onPointerMove: handToolHandler,
    onPointerUp: handToolCommit,
  };
}
