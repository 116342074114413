import { Navigator } from "@solidjs/router";
import { getAuth } from "firebase/auth";

import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";

import { setIsDeletingUser } from "../state/isDeletingUser";
import { setIsSigningOut } from "../state/isSigningOut";

export async function deleteUser(navigate: Navigator) {
  setIsSigningOut(true);
  setIsDeletingUser(true);

  // Wait for view transition
  await new Promise((resolve) => setTimeout(resolve, 300));

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await auth.currentUser?.delete();
      localStorage.removeItem("backStack");
      navigate("/");
    },
    async (error) => {
      await handleError(error);
      await showError(error);
    }
  );
  setIsDeletingUser(false);
  setIsSigningOut(false);
}
