import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import withTransition from "@/helpers/withTransition";

import { addEmailToLocalStorage } from "../helpers/addEmailToLocalStorage";
import { setHasSentMagicLinkEmail } from "../state/hasSentMagicLinkEmail";
import { setIsSigningIn } from "../state/isSigningIn";
import { setSignInMethod } from "../state/signInMethod";

export async function signUserInWithEmail(email: string) {
  setIsSigningIn(true);
  setSignInMethod("email");

  function cleanUp() {
    setIsSigningIn(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await sendSignInLinkToEmail(auth, email, {
        handleCodeInApp: true,
        url: window.location.href,
      });
      addEmailToLocalStorage(email);
      // Animation - Send magic link
      await withTransition(() => {
        setHasSentMagicLinkEmail(true);
        cleanUp();
      });
    },
    async (error) => {
      await handleError(error);
      await showError(error);
      setHasSentMagicLinkEmail(false);

      cleanUp();
    }
  );
}
