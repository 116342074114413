import { createSignal } from "solid-js";

import { canvasView } from "../state/canvasView";
import { setShapes, shapes } from "../state/shapes";
import { allStrokes, setAllStrokes } from "../state/strokes";

export default function useEraserTool() {
  const [previousEraserToolPosition, setPreviousEraserToolPosition] =
    createSignal<{ x: number; y: number } | undefined>(undefined);

  function eraserTool(x: number, y: number) {
    const eraserSize = 10;
    const eraserX1 = previousEraserToolPosition()?.x || x;
    const eraserY1 = previousEraserToolPosition()?.y || y;
    const eraserX2 = x;
    const eraserY2 = y;

    const eraserDistance = Math.sqrt(
      Math.pow(eraserX2 - eraserX1, 2) + Math.pow(eraserY2 - eraserY1, 2)
    );

    setPreviousEraserToolPosition({ x: eraserX2, y: eraserY2 });

    const newStrokes = allStrokes().filter((stroke) => {
      if (!stroke) {
        return false;
      }

      const strokeShouldBeErased = stroke.some((point, i, pointArray) => {
        const nextI = Math.min(i + 1, pointArray.length - 1);

        if (i === nextI) {
          const distToPoint = Math.sqrt(
            Math.pow(point.clientX - eraserX2, 2) +
              Math.pow(point.clientY - eraserY2, 2)
          );

          return distToPoint <= eraserSize / 2;
        }

        const strokeX1 = point.clientX;
        const strokeY1 = point.clientY;
        const strokeX2 = pointArray[nextI].clientX;
        const strokeY2 = pointArray[nextI].clientY;

        if (eraserDistance < eraserSize / 2) {
          // Eraser is a single point, check if it's within a certain distance of the stroke point
          const numerator = Math.abs(
            (strokeY2 - strokeY1) * eraserX2 -
              (strokeX2 - strokeX1) * eraserY2 +
              strokeX2 * strokeY1 -
              strokeY2 * strokeX1
          );
          const denominator = Math.sqrt(
            Math.pow(strokeY2 - strokeY1, 2) + Math.pow(strokeX2 - strokeX1, 2)
          );
          const distToLine = numerator / denominator;

          // If the distance to the line is less than or equal to the eraser's radius, the stroke should be erased
          if (distToLine <= eraserSize / 2) {
            // Check if the intersection point is within the line segment
            const dx = strokeX2 - strokeX1;
            const dy = strokeY2 - strokeY1;
            const t =
              ((eraserX2 - strokeX1) * dx + (eraserY2 - strokeY1) * dy) /
              (dx * dx + dy * dy);
            return t >= 0 && t <= 1;
          }

          return false;
        } else {
          // Eraser is a line, check if it intersects with the stroke line
          const denominator =
            (eraserY2 - eraserY1) * (strokeX2 - strokeX1) -
            (eraserX2 - eraserX1) * (strokeY2 - strokeY1);

          if (denominator === 0) {
            return false;
          }

          const intersectionOnEraserLine =
            ((eraserX2 - eraserX1) * (strokeY1 - eraserY1) -
              (eraserY2 - eraserY1) * (strokeX1 - eraserX1)) /
            denominator;
          const intersectionOnStrokeLine =
            ((strokeX2 - strokeX1) * (strokeY1 - eraserY1) -
              (strokeY2 - strokeY1) * (strokeX1 - eraserX1)) /
            denominator;

          return (
            intersectionOnEraserLine > 0 &&
            intersectionOnEraserLine < 1 &&
            intersectionOnStrokeLine > 0 &&
            intersectionOnStrokeLine < 1
          );
        }
      });

      return !strokeShouldBeErased;
    });

    const newShapes = shapes().filter((shape) => {
      if (!shape) {
        return false;
      }

      if (shape.shape === "circle") {
        // Calculate the center of the oval
        const centerX = (shape.x1 + shape.x2) / 2;
        const centerY = (shape.y1 + shape.y2) / 2;

        // Calculate the radii of the oval
        const radiusX = Math.abs(shape.x2 - shape.x1) / 2;
        const radiusY = Math.abs(shape.y2 - shape.y1) / 2;

        // Calculate the distance from the eraser point to the center of the oval, normalized by the radii
        const dx = eraserX2 - centerX;
        const dy = eraserY2 - centerY;
        const normalizedDistance =
          (dx * dx) / (radiusX * radiusX) + (dy * dy) / (radiusY * radiusY);

        // Check if the eraser is inside the oval (normalizedDistance <= 1)
        const isEraserInsideShape = normalizedDistance <= 1;

        return !isEraserInsideShape;
      } else {
        const shapeX1 = Math.min(shape.x1, shape.x2);
        const shapeY1 = Math.min(shape.y1, shape.y2);
        const shapeX2 = Math.max(shape.x1, shape.x2);
        const shapeY2 = Math.max(shape.y1, shape.y2);

        const isEraserInsideShape =
          eraserX2 >= shapeX1 &&
          eraserX2 <= shapeX2 &&
          eraserY2 >= shapeY1 &&
          eraserY2 <= shapeY2;

        return !isEraserInsideShape;
      }
    });

    setAllStrokes(newStrokes);
    setShapes(newShapes);
  }

  function eraserToolCommit() {
    setPreviousEraserToolPosition(undefined);
  }

  function eraserToolCancel() {
    setPreviousEraserToolPosition(undefined);
  }

  function eraserToolHandler({
    clientX,
    clientY,
    currentTarget,
  }: PointerEvent) {
    const x =
      (clientX - (currentTarget as HTMLElement).getBoundingClientRect().left) /
      canvasView().zoom;
    const y =
      (clientY - (currentTarget as HTMLElement).getBoundingClientRect().top) /
      canvasView().zoom;

    eraserTool(x - canvasView().x, y - canvasView().y);
  }

  return {
    onPointerCancel: eraserToolCancel,
    onPointerDown: eraserToolHandler,
    onPointerLeave: eraserToolCommit,
    onPointerMove: eraserToolHandler,
    onPointerUp: eraserToolCommit,
  };
}
