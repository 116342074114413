import { createSignal } from "solid-js";

import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { setIsSavingCanvasShapes } from "@/features/saving/state/isSaving";

import { ToolColor } from "./colorSwatch";

export type Shape = {
  color: ToolColor;
  shape: "rectangle" | "circle";
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const [currentShape, setCurrentShape] = createSignal<Shape>();

const defaultShapes: Shape[] = [];

export const {
  isConnected: isShapesSynced,
  getSignal: shapes,
  setSignal: setShapesSignal,
  setSignalConnected: setShapes,
} = createConnectedSignal<Shape[]>({
  databaseLocation: (uid?: string) =>
    `v1/etchasketch/${uid ?? user()?.uid}/shapes`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultShapes,
  onEnd: () => setIsSavingCanvasShapes(false),
  onStart: () => setIsSavingCanvasShapes(true),
});
