import {
  onActionPressed,
  onCancelPressed,
  onDownPressed,
  onMenuPressed,
  onUpPressed,
} from "../../../../../helpers/engine/handleKeyboard";
import { setCurrentMenu } from "../handleMenu";

export enum MainMenu {
  encyclopedia = "encyclopedia",
  mon = "mon",
  items = "items",
  options = "options",
  exit = "exit",
}

export const mainMenuItems = [MainMenu.items, MainMenu.exit];

export let currentMenuItemIndex = 0;

export function setCurrentMenuItemIndex(index: number) {
  currentMenuItemIndex = index;
}

export function handleMainMenu() {
  if (onMenuPressed || onCancelPressed) {
    setCurrentMenu(undefined);
  }

  if (onDownPressed) {
    currentMenuItemIndex += 1;

    if (currentMenuItemIndex >= mainMenuItems.length) {
      currentMenuItemIndex = 0;
    }
  }

  if (onUpPressed) {
    currentMenuItemIndex -= 1;

    if (currentMenuItemIndex < 0) {
      currentMenuItemIndex = mainMenuItems.length - 1;
    }
  }

  if (onActionPressed) {
    const currentMenuItem = mainMenuItems[currentMenuItemIndex];

    if (!currentMenuItem) {
      return;
    }

    if (currentMenuItem === MainMenu.exit) {
      setCurrentMenu(undefined);
      return;
    }

    setCurrentMenu(currentMenuItem);
  }
}
