import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { createLocalStorageSignal } from "@/features/localStorage/hooks/createLocalStorageSignal";
import {
  setIsSavingDeviceTouchAction,
  setIsSavingTouchAction,
} from "@/features/saving/state/isSaving";

export const defaultTouchAction = "tool";

export type TouchAction = "hand" | "select" | "tool";

export const {
  isConnected: isTouchActionSynced,
  getSignal: touchAction,
  setSignal: setTouchActionSignal,
  setSignalConnected: setTouchAction,
} = createConnectedSignal<TouchAction>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/touchAction`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultTouchAction,
  onEnd: () => setIsSavingTouchAction(false),
  onStart: () => setIsSavingTouchAction(true),
});

export const defaultDeviceTouchAction = undefined;

export const {
  getSignal: deviceTouchAction,
  setSignal: setDeviceTouchActionSignal,
  setSignalConnected: setDeviceTouchAction,
} = createLocalStorageSignal({
  initialValue: defaultDeviceTouchAction,
  localStorageLocation: (uid?: string) => `${uid}/touchAction`,
  onEnd: () => setIsSavingDeviceTouchAction(false),
  onStart: () => setIsSavingDeviceTouchAction(true),
});

export const calculatedTouchAction = () => deviceTouchAction() || touchAction();
