import confetti from "canvas-confetti";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { setBanner } from "@/features/banner/state/banner";
import { isDatabaseConnected } from "@/features/database/selectors/isDatabaseConnected";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";
import { until } from "@/helpers/until";
import withTransition from "@/helpers/withTransition";

import { setIsSigningIn } from "../state/isSigningIn";
import { setSignInMethod } from "../state/signInMethod";
import { user } from "../state/user";

export async function signUserInWithPassword(email: string, password: string) {
  setIsSigningIn(true);
  setSignInMethod("password");

  function cleanUp() {
    setIsSigningIn(false);
    setSignInMethod(undefined);
  }

  await tryFirebase(
    async () => {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      await new Promise<void>((resolve) =>
        until(() => user() !== undefined, resolve)
      );
      if (auth.currentUser && !auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
        setBanner({
          intent: "success",
          message:
            "Before signing in, please verify your email using the link we sent you.",
        });
        await signOut(auth);
      }

      if (auth.currentUser?.emailVerified) {
        await new Promise<void>((resolve) =>
          until(() => isDatabaseConnected(), resolve)
        );
        // Animation - Sign in with password
        await withTransition(() => {
          cleanUp();
        });

        confetti({
          origin: { y: 0.7 },
          particleCount: 100,
          spread: 70,
        });
      } else {
        cleanUp();
      }
    },
    async (error) => {
      await handleError(error);
      await showError(error);

      cleanUp();
    }
  );
}
