import "./SignIn.scss";

import cn from "clsx";
import Solid, { For } from "solid-js";

import { PageComponent } from "@/components/PageComponent/PageComponent";
import { SignInForm } from "@/features/authentication/components/forms/SignInForm";
import { HeroBackground } from "@/features/authentication/components/ui/HeroBackground";

export type SignInMethod = "guest" | "email" | "password" | "new" | undefined;

interface Props extends Solid.JSX.HTMLAttributes<HTMLDivElement> {}

export const SignIn: Solid.Component<Props> = ({
  class: className,
  ...attributes
}) => {
  interface Circle {
    color: string;
    opacity: number;
    size: number;
    x: number;
    y: number;
  }

  const topOfTheCircles = 66;
  const percentageOfCirclesThatAreTransparent = 100;
  const circles = Array.from({ length: 500 }, () => {
    const x = Math.random() * 100;
    const y = topOfTheCircles + Math.random() * (100 - topOfTheCircles);
    const size = 10 + Math.random() * 20;
    const scaler = Math.max(
      0,
      (y - topOfTheCircles) /
        (100 -
          topOfTheCircles -
          ((100 - percentageOfCirclesThatAreTransparent) / 100) *
            (100 - topOfTheCircles))
    );

    const colors = [
      "fill-red-500",
      "fill-orange-500",
      "fill-amber-500",
      "fill-yellow-500",
      "fill-lime-500",
      "fill-green-500",
      "fill-emerald-500",
      "fill-teal-500",
      "fill-cyan-500",
      "fill-sky-500",
      "fill-blue-500",
      "fill-indigo-500",
      "fill-violet-500",
      "fill-purple-500",
      "fill-fuchsia-500",
      "fill-pink-500",
      "fill-rose-500",
    ].reverse();

    return {
      // color based on x and y
      color: colors[Math.floor((x / 100) * (y / 100) * (colors.length - 1))],
      opacity: scaler,
      size: size * scaler > 5 ? size * scaler : 0,
      x,
      y,
    };
  }).filter((circle) => circle.size > 0);

  const circlesThatDontOverlap = circles.reduce((acc: Circle[], circle) => {
    const doesOverlap = acc.some((otherCircle) => {
      const distance = Math.sqrt(
        Math.pow(circle.x - otherCircle.x, 2) +
          Math.pow(circle.y - otherCircle.y, 2)
      );
      return distance * 5 < circle.size + otherCircle.size;
    });
    if (doesOverlap) {
      return acc;
    }
    return [...acc, circle];
  }, []);

  document.title = "Scribble Desk - Sign in";

  return (
    <>
      <div
        class={cn(
          "absolute bottom-0 left-0 h-full w-full overflow-hidden",
          "hero:hidden"
        )}
      >
        {/* <HeroBackground
          class="absolute bottom-0 w-full translate-y-10 -rotate-90 -scale-y-100"
          preserveAspectRatio="xMaxYMid slice"
        /> */}
        <For each={circlesThatDontOverlap}>
          {(circle) => (
            <div
              style={{
                left: `${circle.x}%`,
                // left: `round(${circle.x}%, 1px)`,
                opacity: circle.opacity,
                position: "absolute",
                top: `${circle.y}%`,
                // top: `round(${circle.y}%, 1px)`,
              }}
            >
              <svg
                class={circle.color}
                style={{
                  width: `${circle.size}px`,
                }}
                viewBox={`0 0 ${circle.size} ${circle.size}`}
              >
                <circle cx="50%" cy="50%" r="50%" />
              </svg>
            </div>
          )}
        </For>
      </div>
      <div
        class={cn(
          "z-0 overflow-auto",
          "tablet:flex tablet:h-full tablet:flex-col tablet:justify-start"
        )}
        style={{
          "grid-area": "body",
        }}
      >
        <div class="max-h-[20%] flex-1" />
        <PageComponent withoutPadding>
          <div
            class={cn("pageSignIn", "overflow-hidden shrink-0", className)}
            {...attributes}
          >
            <div class="flex">
              <div
                class={cn(
                  "relative hidden w-1/2 overflow-hidden rounded-lg",
                  "hero:block"
                )}
              >
                <HeroBackground
                  class="absolute h-full w-full"
                  preserveAspectRatio="xMaxYMid slice"
                />
              </div>
              <div class={cn("relative flex-1", "p-5 tablet:p-10")}>
                <SignInForm />
              </div>
            </div>
          </div>
        </PageComponent>
        <div class="max-h-[30%] flex-1" />
      </div>
    </>
  );
};
