import { user } from "@/features/authentication/state/user";
import { createConnectedSignal } from "@/features/database/hooks/createConnectedSignal";
import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import { createLocalStorageSignal } from "@/features/localStorage/hooks/createLocalStorageSignal";
import {
  setIsSavingDeviceTheme,
  setIsSavingTheme,
} from "@/features/saving/state/isSaving";

export type Theme = "dark" | "light" | "system";

export const defaultTheme = "system";

export const {
  isConnected: isThemeSynced,
  getSignal: theme,
  setSignal: setThemeSignal,
  setSignalConnected: setTheme,
} = createConnectedSignal<Theme>({
  databaseLocation: (uid?: string) =>
    `v1/userSettings/${uid ?? user()?.uid}/theme`,
  decode: (value: string) => JSON.parse(value),
  errorHandler: async (error) => {
    await handleError(error);
    await showError(error);
  },
  initialValue: defaultTheme,
  onEnd: () => setIsSavingTheme(false),
  onStart: () => setIsSavingTheme(true),
});

export const defaultDeviceTheme = undefined;

export const {
  getSignal: deviceTheme,
  setSignal: setDeviceThemeSignal,
  setSignalConnected: setDeviceTheme,
} = createLocalStorageSignal({
  initialValue: defaultDeviceTheme,
  localStorageLocation: (uid?: string) => `${uid}/theme`,
  onEnd: () => setIsSavingDeviceTheme(false),
  onStart: () => setIsSavingDeviceTheme(true),
});

export const calculatedTheme = () => deviceTheme() || theme();
