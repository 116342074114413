import "./DropdownButton.scss";

import { cva, VariantProps } from "class-variance-authority";
import cn from "clsx";
import { nanoid } from "nanoid";
import Solid, { useContext } from "solid-js";

import { Button } from "@/components/Button/Button";

import { DropdownContext } from "./Dropdown";

const dropdownButtonClasses = cva("dropdownButton", {
  defaultVariants: {},
  variants: {
    isActive: {
      true: "dropdownButton-active",
    },
    isSelected: {
      true: "dropdownButton-selected",
    },
  },
});

interface Props
  extends Solid.ComponentProps<typeof Button>,
    VariantProps<typeof dropdownButtonClasses> {
  hasCaret?: boolean;
  isSelected?: boolean;
  selectionIndicator?: string;
  style?: Solid.JSX.CSSProperties;
}

export const DropdownButton: Solid.Component<Props> = ({
  children,
  class: className,
  hasCaret = true,
  isSelected,
  onClick,
  onKeyDown,
  ref,
  selectionIndicator = "line",
  style,
  ...attributes
}) => {
  const { triggerProps } = useContext(DropdownContext);

  const isActive = () => triggerProps?.().isOpen();

  const onClickHandler: Props["onClick"] = (event, ...args) => {
    if (typeof onClick === "function") {
      onClick(event, ...args);
    }

    if (!event.defaultPrevented) {
      triggerProps?.().toggle();
    }
  };

  const onKeyDownHandler: Props["onKeyDown"] = (event, ...args) => {
    if (typeof onKeyDown === "function") {
      onKeyDown(event, ...args);
    }

    if (event.key === "Tab") {
      triggerProps?.().close();
    }

    if (
      triggerProps?.().isOpen() &&
      (event.key === " " || event.key === "Enter")
    ) {
      event.preventDefault();
    }

    if (
      !triggerProps?.().isOpen() &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp"
    ) {
      return;
    }

    event.preventDefault();

    const newEvent = new KeyboardEvent("keydown", {
      altKey: event.altKey,
      bubbles: event.bubbles,
      cancelable: event.cancelable,
      code: event.code,
      ctrlKey: event.ctrlKey,
      isComposing: event.isComposing,
      key: event.key,
      location: event.location,
      metaKey: event.metaKey,
      repeat: event.repeat,
      shiftKey: event.shiftKey,
    });

    triggerProps?.().menu()?.dispatchEvent(newEvent);
  };

  const appliedRef: Props["ref"] = (...args) => {
    if (typeof ref === "function") {
      ref(...args);
    }
    triggerProps?.().ref(...args);
  };

  const borderId = nanoid();

  return (
    <Button
      {...attributes}
      class={cn(
        dropdownButtonClasses({
          isActive: isActive(),
          isSelected,
        }),
        className,
        borderId,
        "flex flex-1 gap-2"
      )}
      isActive={isActive()}
      isDisabled={triggerProps?.().isDisabled}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      ref={appliedRef}
      style={{
        ...(isActive() && triggerProps?.().borderStyles),
        ...style,
      }}
    >
      <style>
        {`.${borderId}::after {
            ${
              isActive() && triggerProps?.().borderStyles
                ? Object.entries(triggerProps().borderStyles)
                    .map(
                      ([key, value]) => `${key}: ${value}${value ? "px" : ""};`
                    )
                    .join("\n")
                : ""
            }}`}
      </style>
      <div
        class={cn(
          "dropdownButton__selectionIndicator",
          selectionIndicator === "circle" &&
            "dropdownButton__selectionIndicator--circle",
          selectionIndicator === "line" &&
            "dropdownButton__selectionIndicator--line",
          !isSelected && "hidden"
        )}
      />
      {children}
      {hasCaret && (
        <>
          <div class="flex-1" />
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </>
      )}
    </Button>
  );
};
