import { getAuth, isSignInWithEmailLink } from "firebase/auth";

import { handleError } from "@/features/errorHandling/actions/handleError";
import { showError } from "@/features/errorHandling/actions/showError";
import tryFirebase from "@/features/errorHandling/helpers/tryFirebase";

import { getEmailFromLocalStorage } from "../helpers/getEmailFromLocalStorage";
import { removeEmailFromLocalStorage } from "../helpers/removeEmailFromLocalStorage";
import { setIsRequestingEmailForMagicLink } from "../state/isRequestingEmailForMagicLink";
import { signInWithMagicLink } from "./signInWithMagicLink";

export async function signInWithMagicLinkOnPageLoad() {
  await tryFirebase(
    async () => {
      const auth = getAuth();
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        return;
      }

      const userEmail = getEmailFromLocalStorage();

      if (!userEmail) {
        setIsRequestingEmailForMagicLink(true);
        return;
      }

      await signInWithMagicLink(userEmail);

      removeEmailFromLocalStorage();
    },
    async (error) => {
      await handleError(error);
      await showError(error);
    }
  );
}
